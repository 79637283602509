import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Pathologies {
    constructor(private translate: TranslateService) {}

    pathologiesOnaps = [
        {
            nom: this.translate.instant('pathologies.etat.surpoidsobesite'),
            cat: [
                this.translate.instant('pathologies.surpoidsobesite.obesite'),
                this.translate.instant('pathologies.surpoidsobesite.surpoids'),
                this.translate.instant('pathologies.surpoidsobesite.prepchirbar'),
                this.translate.instant('pathologies.surpoidsobesite.postchirbar'),
                this.translate.instant('pathologies.surpoidsobesite.symdrometa'),
                this.translate.instant('pathologies.surpoidsobesite.steahepnonalc'),
                this.translate.instant('pathologies.surpoidsobesite.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.cardiovasculaires'),
            cat: [
                this.translate.instant('pathologies.cardiovasculaires.hypart'),
                this.translate.instant('pathologies.cardiovasculaires.infarctus'),
                this.translate.instant('pathologies.cardiovasculaires.insuffisance'),
                this.translate.instant('pathologies.cardiovasculaires.trouble'),
                this.translate.instant('pathologies.cardiovasculaires.cardiopathie'),
                this.translate.instant('pathologies.cardiovasculaires.valves'),
                this.translate.instant('pathologies.cardiovasculaires.embolie'),
                this.translate.instant('pathologies.cardiovasculaires.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.diabetes'),
            cat: [
                this.translate.instant('pathologies.diabetes.type2NT'),
                this.translate.instant('pathologies.diabetes.type2TSC'),
                this.translate.instant('pathologies.diabetes.type2TAC'),
                this.translate.instant('pathologies.diabetes.type1NC'),
                this.translate.instant('pathologies.diabetes.type1C'),
                this.translate.instant('pathologies.diabetes.gestationnel'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.cancers'),
            cat: [
                this.translate.instant('pathologies.cancers.sein'),
                this.translate.instant('pathologies.cancers.colon'),
                this.translate.instant('pathologies.cancers.uterus'),
                this.translate.instant('pathologies.cancers.poumon'),
                this.translate.instant('pathologies.cancers.prostate'),
                this.translate.instant('pathologies.cancers.rein'),
                this.translate.instant('pathologies.cancers.pancreas'),
                this.translate.instant('pathologies.cancers.tumeur'),
                this.translate.instant('pathologies.cancers.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.hematologiques'),
            cat: [
                this.translate.instant('pathologies.hematologiques.leucemies'),
                this.translate.instant('pathologies.hematologiques.hodgkin'),
                this.translate.instant('pathologies.hematologiques.lymphomes'),
                this.translate.instant('pathologies.hematologiques.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.neurologiques'),
            cat: [
                this.translate.instant('pathologies.neurologiques.avc'),
                this.translate.instant('pathologies.neurologiques.alzheimer'),
                this.translate.instant('pathologies.neurologiques.sep'),
                this.translate.instant('pathologies.neurologiques.parkinson'),
                this.translate.instant('pathologies.neurologiques.myopathies'),
                this.translate.instant('pathologies.neurologiques.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.psychiatrie'),
            cat: [
                this.translate.instant('pathologies.psychiatrie.depression'),
                this.translate.instant('pathologies.psychiatrie.anxiete'),
                this.translate.instant('pathologies.psychiatrie.burnout'),
                this.translate.instant('pathologies.psychiatrie.schizo'),
                this.translate.instant('pathologies.psychiatrie.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.osteoarticulaires'),
            cat: [
                this.translate.instant('pathologies.osteoarticulaires.lombalgies'),
                this.translate.instant('pathologies.osteoarticulaires.arthrose'),
                this.translate.instant('pathologies.osteoarticulaires.polyarthrite'),
                this.translate.instant('pathologies.osteoarticulaires.spondy'),
                this.translate.instant('pathologies.osteoarticulaires.rhumastismes'),
                this.translate.instant('pathologies.osteoarticulaires.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.respiratoires'),
            cat: [
                this.translate.instant('pathologies.respiratoires.bpco'),
                this.translate.instant('pathologies.respiratoires.asthme'),
                this.translate.instant('pathologies.respiratoires.insuff'),
                this.translate.instant('pathologies.respiratoires.apnee'),
                this.translate.instant('pathologies.respiratoires.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.infectieuses'),
            cat: [
                this.translate.instant('pathologies.infectieuses.vih'),
                this.translate.instant('pathologies.infectieuses.covid'),
                this.translate.instant('pathologies.infectieuses.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.renales'),
            cat: [
                this.translate.instant('pathologies.renales.nodyal'),
                this.translate.instant('pathologies.renales.dyal'),
                this.translate.instant('pathologies.renales.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.intchroinfl'),
            cat: [
                this.translate.instant('pathologies.intchroinfl.crohn'),
                this.translate.instant('pathologies.intchroinfl.rectocolite'),
                this.translate.instant('pathologies.intchroinfl.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.symptfonc'),
            cat: [
                this.translate.instant('pathologies.symptfonc.fibromyalgie'),
                this.translate.instant('pathologies.symptfonc.douleurs'),
                this.translate.instant('pathologies.symptfonc.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.transplantes'),
            cat: [
                this.translate.instant('pathologies.transplantes.rein'),
                this.translate.instant('pathologies.transplantes.coeur'),
                this.translate.instant('pathologies.transplantes.foie'),
                this.translate.instant('pathologies.transplantes.autres'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.grossesse'),
            cat: [
                this.translate.instant('pathologies.grossesse.per'),
                this.translate.instant('pathologies.grossesse.post'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.veillissement'),
            cat: [
                this.translate.instant('pathologies.veillissement.chutes'),
                this.translate.instant('pathologies.veillissement.sarcopénie'),
            ],
        },
        {
            nom: this.translate.instant('pathologies.etat.autres'),
            cat: [],
        },
    ];
}
