import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { AuthenticationService } from '@/_services';
import { User } from '@/_models';
import { ISfullSeance, ISinscrit } from '@/_interfaces';
import { Lieux } from '@/_helpers';

@Component({
    selector: 'app-paiement-seance',
    templateUrl: './paiement-seance.component.html',
    styleUrls: ['./paiement-seance.component.scss'],
})
export class PaiementSeanceComponent implements OnInit {
    paiementForm: FormGroup;
    loading = false;
    submitted = false;
    seance: ISfullSeance = this.paiementData;
    structure = this.paiementData.structure;
    prixSeance = this.paiementData.prixSeance;
    reglements = 0;
    lieu = '';
    isAdmin = false;
    currentUser: User;

    constructor(
        public dialogRef: MatDialogRef<PaiementSeanceComponent>,
        @Inject(MAT_DIALOG_DATA) public paiementData: any,

        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private locations: Lieux
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);

        this.paiementForm = this.formBuilder.group({
            versement: formBuilder.control(0, Validators.required),
        });
    }

    get p() {
        return this.paiementForm.controls;
    }

    ngOnInit(): void {
        this.reglements = 0;
        for (let reglement of this.paiementData.historique) {
            this.reglements += reglement.montant;
        }

        this.lieu = this.locations.lieux.find((x) => x.value == this.seance.seance.lieuPratique)?.name;
    }

    onSubmit(result: string) {
        this.submitted = true;

        if (result == 'Cancel' || this.paiementForm.get('versement')!.value <= 0) {
            this.dialogRef.close('Cancel');
            return;
        }

        // stop here if form is invalid
        if (this.paiementForm.invalid) {
            this.toastr.info(this.translate.instant('error.form.invalid'));
            return;
        }

        this.dialogRef.close(this.paiementForm.value);
    }
}
