import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Lieux {
    constructor(private translate: TranslateService) {}

    // Types de lieux pour les séances
    lieux = [
        {
            value: 0,
            name: this.translate.instant('seance.label.interne'),
        },
        {
            value: 1,
            name: this.translate.instant('seance.label.externe'),
        },
        {
            value: 2,
            name: this.translate.instant('seance.label.visio'),
        },
    ];
}
