import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PratiquantsAcceptes {
    constructor(private translate: TranslateService) {}
    selectedPratiquantsAcceptesValues: string[] = [];
    pratiquantsAcceptes = [
        { value: 'AUDIENCE_ADULTE', name: this.translate.instant('AUDIENCE_ADULTE') },
        { value: 'AUDIENCE_ENFANTS', name: this.translate.instant('AUDIENCE_ENFANTS') },
        { value: 'AUDIENCE_VALIDES', name: this.translate.instant('AUDIENCE_VALIDES') },
        { value: 'AUDIENCE_MALADES', name: this.translate.instant('AUDIENCE_MALADES') },
        { value: 'AUDIENCE_HANDICAPES', name: this.translate.instant('AUDIENCE_HANDICAPES') },
    ];

    listPratiquantsAcceptesError: boolean = true;
}
