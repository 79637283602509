import { Component, Input, Output, forwardRef, OnDestroy, EventEmitter } from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
    NG_VALIDATORS,
} from '@angular/forms';

import { Subscription } from 'rxjs';

import { AuthenticationService, ToolsService } from '@/_services';

import { User } from '@/_models';

// describes what the return value of the form control will look like
export interface epsFormValues {
    titre: string;
    description: string;
    selector: string;
    type: 0;
    fields: {
        garnier_q1: string;
        garnier_q2: string;
        garnier_q3: string;
        garnier_q4: string;
        garnier_q5: string;
        garnier_q6: string;
        garnier_q7: string;
        garnier_q8: string;
    };
}

@Component({
    selector: 'app-eps',
    templateUrl: './eps.component.html',
    styleUrls: ['./eps.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EpsComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EpsComponent),
            multi: true,
        },
    ],
})
export class EpsComponent implements ControlValueAccessor, OnDestroy {
    @Input() inData: any;
    @Input() inProduction: any;
    @Output() parentFormSubmittedChange = new EventEmitter<boolean>();
    @Input() set parentFormSubmitted(isSubmitted: boolean) {
        if (isSubmitted) {
            this.submitted = true;
            setTimeout(() => {
                this.parentFormSubmittedChange.next(false);
            });
        }
    }

    currentUser: User;
    loading = false;
    submitted = false;
    isAdmin: boolean;
    formID = 0;
    returnUrl: string = '/dashboard/forms';
    fieldsForm: FormGroup;
    subscriptions: Subscription[] = [];
    listeModes: Array<any> = [];
    min = 1;
    max = 10;

    constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private toolsService: ToolsService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);
        // Le nom des champs correspond aux noms ONAPS
        this.fieldsForm = this.formBuilder.group({
            selector: this.formBuilder.control('app-eps'),
            fields: this.formBuilder.group({
                garnier_q1: this.formBuilder.control('', [
                    Validators.required,
                    this.toolsService.plage(this.min, this.max),
                ]),
                garnier_q2: this.formBuilder.control('', [
                    Validators.required,
                    this.toolsService.plage(this.min, this.max),
                ]),
                garnier_q3: this.formBuilder.control('', [
                    Validators.required,
                    this.toolsService.plage(this.min, this.max),
                ]),
                garnier_q4: this.formBuilder.control('', [
                    Validators.required,
                    this.toolsService.plage(this.min, this.max),
                ]),
                garnier_q5: this.formBuilder.control('', [
                    Validators.required,
                    this.toolsService.plage(this.min, this.max),
                ]),
                garnier_q6: this.formBuilder.control('', [
                    Validators.required,
                    this.toolsService.plage(this.min, this.max),
                ]),
                garnier_q7: this.formBuilder.control('', [
                    Validators.required,
                    this.toolsService.plage(this.min, this.max),
                ]),
                garnier_q8: this.formBuilder.control('', [
                    Validators.required,
                    this.toolsService.plage(this.min, this.max),
                ]),
            }),
        });

        this.subscriptions.push(
            // any time the inner form changes update the parent of any change
            this.fieldsForm.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.fieldsForm.controls;
    }

    get value(): epsFormValues {
        return this.fieldsForm.value;
    }

    set value(value: epsFormValues) {
        this.fieldsForm.patchValue(value);
        this.onChange(value);
        this.onTouched();
    }

    // communicate the inner form validation to the parent form
    validate(_: FormControl) {
        return this.fieldsForm.valid ? null : { genericFields: { valid: false } };
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
            this.fieldsForm.patchValue(this.value);
        }

        if (value === null) {
            this.fieldsForm.reset();
        }
    }
    // On initialise le formulaire
    ngAfterContentInit(): void {
        // console.log(this.inData);
        this.writeValue(this.inData.formBind.genericFields);
    }
}
