import {
    ISActiviteSeance,
    ISprofil,
    IScoordonnees,
    IScoordonneesStructure,
    ISfullAdresse,
    IStarifOrganisation,
    IStarifParticuliers,
    ISparams,
} from '@/_interfaces';
export class Seance {
    id: number = 0;
    activite: ISActiviteSeance = { nom: '', duree: '', descriptif: '', color: '' };
    maxParticipants: string = '';
    enseignant: ISprofil = { id: 0, firstname: '', lastname: '', email: '' };
    materielDispo: string = '';
    typeClient: boolean = false;
    contactOrganisation: IScoordonneesStructure = {
        nom: '',
        prenom: '',
        phone: '',
        email: '',
        username: '',
        structure: 0,
        members: 0,
        fonctions: [
            {
                nom: '',
                descriptif: '',
            },
        ],
        notification: true,
    };
    lieuPratique: number = 0;
    lieuExterne: ISfullAdresse = { organisation: '', adresse: '', complementAdresse: '', zip: '', city: '' };
    lieuVisio: { link: string; code: string } = { link: '', code: '' };
    codeAcces: string = '';
    contactAcces: IScoordonnees = { nom: '', prenom: '', phone: '', email: '' };
    dateDebut: number = 0;
    dateFin: number = 0;
    periodicite: string = '';
    tarifsParticuliers: IStarifParticuliers = {
        tarifsFixes: [
            { nom: 'anneeRoulante', show: false, prix: '', qte: '', descriptif: '' },
            { nom: 'trimestreRoulant', show: false, prix: '', qte: '', descriptif: '' },
            { nom: 'moisRoulant', show: false, prix: '', qte: '', descriptif: '' },
            { nom: 'anneeCivile', show: false, prix: '', qte: '', descriptif: '' },
            { nom: 'anneeSportive', show: false, prix: '', qte: '', descriptif: '' },
            { nom: 'trimestreFixe', show: false, prix: '', qte: '', descriptif: '' },
            { nom: 'free', show: false, prix: '', qte: '', descriptif: '' },
        ],
        tarifsMultiples: [{ nom: '', show: false, prix: '', qte: '', descriptif: '' }],
    };

    tarifsOrganisation: IStarifOrganisation = {
        choix: false,
        prixForfaitSeance: '',
        prixForfaitParticipant: '',
    };
}
