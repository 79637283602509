import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Mesures {
    constructor(private translate: TranslateService) {}
    mesures = [
        { value: this.translate.instant('constant.MESURE_TEMPS'), name: this.translate.instant('MESURE_TEMPS') },
        { value: this.translate.instant('constant.MESURE_DISTANCE'), name: this.translate.instant('MESURE_DISTANCE') },
        { value: this.translate.instant('constant.MESURE_FORCE'), name: this.translate.instant('MESURE_FORCE') },
        { value: this.translate.instant('constant.MESURE_LEVER'), name: this.translate.instant('MESURE_LEVER') },
        {
            value: this.translate.instant('constant.MESURE_FREQ_CARD'),
            name: this.translate.instant('MESURE_FREQ_CARD'),
        },
        {
            value: this.translate.instant('constant.MESURE_SATURATION'),
            name: this.translate.instant('MESURE_SATURATION'),
        },
        { value: this.translate.instant('constant.MESURE_ECART'), name: this.translate.instant('MESURE_ECART') },
        { value: this.translate.instant('constant.MESURE_EFFORT'), name: this.translate.instant('MESURE_EFFORT') },
    ];

    onaps = [
        { field_csv: 'tm6' },
        { field_csv: 'tm6_spo2_pre' },
        { field_csv: 'tm6_spo2_post0min' },
        { field_csv: 'tm6_spo2_post1min' },
        { field_csv: 'tm6_spo2_post2min' },
        { field_csv: 'tm6_fc_pre' },
        { field_csv: 'tm6_fc_post0min' },
        { field_csv: 'tm6_fc_post1min' },
        { field_csv: 'tm6_fc_post2min' },
        { field_csv: 'tm6_rpe' },
        { field_csv: 'tupandgo' },
        { field_csv: 'assisdebout' },
        { field_csv: 'handgrip_md' },
        { field_csv: 'handgrip_mnd' },
        { field_csv: 'equilibre_pd' },
        { field_csv: 'equilibre_pnd' },
        { field_csv: 'flexion_tronc' },
    ];

    mode = [
        { value: '1', name: this.translate.instant('forms.mode.apas') },
        { value: '2', name: this.translate.instant('forms.mode.peea') },
        { value: '3', name: this.translate.instant('forms.mode.pl') },
        { value: '4', name: this.translate.instant('forms.mode.pepupds') },
    ];
}
