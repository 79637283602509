<div class="row mb-3" *ngIf="!inProduction">
    <div class="col-12">
        <h4>{{ 'forms.titre.adload' | translate }}</h4>
    </div>
</div>
<div class="form-group" [formGroup]="fieldsForm">
    <ng-container formGroupName="fields">
        <div class="row mb-3">
            <div class="col-sm-6">
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.q11' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <label>{{ 'forms.label.q11' | translate }}</label>
                <br />
                <mat-radio-group formControlName="q11" (change)="onSetYN($event, 'q11')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q11')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-3" *ngIf="ouiq11 == '1'">
                <mat-form-field>
                    <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.q12' | translate }}"
                        matPrefix
                        >help</mat-icon
                    >
                    <mat-label>{{ 'forms.label.q12' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.q12' | translate }}"
                        matInput
                        formControlName="q12"
                        [mask]="numericMask"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <span class="text-warning">{{ 'forms.consigne.q12' | translate }} </span>
                        <span class="text-danger mt-2" *ngIf="submitted && f['fields'].get('q12')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="col-sm-3" *ngIf="ouiq11 == '1'">
                <mat-form-field>
                    <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.q13' | translate }}"
                        matPrefix
                        >help</mat-icon
                    >
                    <mat-label>{{ 'forms.label.q13' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.q13' | translate }}"
                        matInput
                        formControlName="q13"
                        [mask]="heureMask"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <span class="text-warning">{{ 'forms.consigne.q13' | translate }} </span>
                        <span class="text-danger mt-2" *ngIf="submitted && f['fields'].get('q13')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
        <h4>{{ 'forms.titre.q14' | translate }}</h4>
        <div class="row mb-3">
            <div class="col-sm-6">
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.q14_fort' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <label>{{ 'forms.label.q14_fort' | translate }}</label>
                <br />
                <mat-radio-group formControlName="q14_fort" (change)="onSetYN($event, 'q14_fort')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q14_fort')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-6">
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.q14_modere' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <label>{{ 'forms.label.q14_modere' | translate }}</label>
                <br />
                <mat-radio-group formControlName="q14_modere" (change)="onSetYN($event, 'q14_modere')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q14_modere')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <ng-container *ngIf="ouiq14_fort == '1' || ouiq14_modere == '1'">
            <h4>{{ 'forms.titre.q15' | translate }}</h4>
            <div class="row mb-3">
                <div class="col-sm-6" ng-container *ngIf="ouiq14_fort == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q15_fort' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q15_fort' | translate }}"
                            matInput
                            formControlName="q15_fort"
                            [mask]="numericMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q15' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q15_fort')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" ng-container *ngIf="ouiq14_modere == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q15_modere' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q15_modere' | translate }}"
                            matInput
                            formControlName="q15_modere"
                            [mask]="numericMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q15' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q15_modere')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <h4>{{ 'forms.titre.q16' | translate }}</h4>
            <div class="row mb-3">
                <div class="col-sm-6" *ngIf="ouiq14_fort == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q16_fort' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q16_fort' | translate }}"
                            matInput
                            formControlName="q16_fort"
                            [mask]="heureMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q16' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q16_fort')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="ouiq14_modere == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q16_modere' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q16_modere' | translate }}"
                            matInput
                            formControlName="q16_modere"
                            [mask]="heureMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q16' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q16_modere')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <h4>{{ 'forms.titre.q17' | translate }}</h4>
        <div class="row mb-3">
            <div class="col-sm-6">
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.q17_ecran' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <label>{{ 'forms.label.q17_ecran' | translate }}</label>
                <br />
                <mat-radio-group formControlName="q17_ecran" (change)="onSetYN($event, 'q17_ecran')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q17_ecran')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-6">
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.q17_autre' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <label>{{ 'forms.label.q17_autre' | translate }}</label>
                <br />
                <mat-radio-group formControlName="q17_autre" (change)="onSetYN($event, 'q17_autre')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q17_autre')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <ng-container *ngIf="ouiq17_ecran == '1' || ouiq17_autre == '1'">
            <h4>{{ 'forms.titre.q18' | translate }}</h4>
            <div class="row mb-3">
                <div class="col-sm-6" ng-container *ngIf="ouiq17_ecran == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q18_ecran' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q18_ecran' | translate }}"
                            matInput
                            formControlName="q18_ecran"
                            [mask]="numericMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q18' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q18_ecran')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" ng-container *ngIf="ouiq17_autre == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q18_autre' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q18_autre' | translate }}"
                            matInput
                            formControlName="q18_autre"
                            [mask]="numericMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q18' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q18_autre')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <h4>{{ 'forms.titre.q19' | translate }}</h4>
            <div class="row mb-3">
                <div class="col-sm-6" *ngIf="ouiq17_ecran == '1'">
                    <mat-form-field>
                        <mat-icon
                            matTooltipClass="custom-tooltip"
                            matToolTipPosition="above"
                            matTooltip="{{ 'forms.help.q19_ecran' | translate }}"
                            matPrefix
                            >help</mat-icon
                        >
                        <mat-label>{{ 'forms.label.q19_ecran' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q19_ecran' | translate }}"
                            matInput
                            formControlName="q19_ecran"
                            [mask]="heureMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q19' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q19_ecran')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="ouiq17_autre == '1'">
                    <mat-form-field>
                        <mat-icon
                            matTooltipClass="custom-tooltip"
                            matToolTipPosition="above"
                            matTooltip="{{ 'forms.help.q19_autre' | translate }}"
                            matPrefix
                            >help</mat-icon
                        >
                        <mat-label>{{ 'forms.label.q19_autre' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q19_autre' | translate }}"
                            matInput
                            formControlName="q19_autre"
                            [mask]="heureMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q19' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q19_autre')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
