import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '@/_shared';

import { PreviewComponent } from '@/_components';

@NgModule({
    declarations: [PreviewComponent],
    imports: [CommonModule, MatDialogModule, MaterialModule, TranslateModule],
})
export class PreviewModule {}
