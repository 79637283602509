import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Structures {
    constructor(private translate: TranslateService) {}

    // Types de structures pro
    structures = [
        {
            value: this.translate.instant('constant.PRATIQUE_INSTITUTIONNELLE'),
            name: this.translate.instant('PRATIQUE_INSTITUTIONNELLE'),
        },
        // {
        //     value: this.translate.instant('constant.PRATIQUE_LIBERALE'),
        //     name: this.translate.instant('PRATIQUE_LIBERALE'),
        // },
    ];
}
