import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { Router } from '@angular/router';

import { User } from '@/_models';
import { Lieux } from '@/_helpers';
import { ToolsService, UserService, AuthenticationService, PratiquantService, InscriptionService } from '@/_services';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

/* Modal Dialog */
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PromptComponent } from '@/_components/modal/prompt.component';
import { ModalComponent } from '@/_components/modal/modal.component';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-board-participants',
    templateUrl: './board-participants.component.html',
    styleUrls: ['./board-participants.component.scss'],
})
export class BoardParticipantsComponent implements OnInit {
    @Input() inData: any;

    currentUser: User;
    params: [] = [];
    user = new User();
    selectedRows = <any>[];
    inscriptionsBind = <any>[];
    rawBind = <any>[];
    loading = false;
    returnUrl: string = '/dashboard/users/';
    membres: Array<any> = [];
    isPro = false;

    // Trie des colonnes de tableau
    displayedColumns: string[] = ['lastname', 'firstname', 'cycles'];
    dataSource = new MatTableDataSource(this.membres);

    constructor(
        private authenticationService: AuthenticationService,
        private inscriptionService: InscriptionService,
        private toolsService: ToolsService,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        private pratiquantService: PratiquantService,
        public matDialog: MatDialog,
        private locations: Lieux
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isPro = this.authenticationService.isPro(this.currentUser);
    }

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    ngOnInit(): void {
        this.loadAllUsers();
    }

    private loadAllUsers() {
        this.loading = true;
        this.pratiquantService.getPratiquants().subscribe({
            next: (data) => {
                // Si pro on ne charge que ses participants
                if (this.isPro) {
                    this.membres = Object(data).filter((x: User) => x.sid == this.currentUser.sid);
                } else this.membres = Object(data);

                this.inscriptionService.getAllCompany().subscribe({
                    next: (inscriptions) => {
                        // On fait de la réorganisation des données indexées sur userID
                        for (let membre of this.membres) {
                            let rows = inscriptions.filter((x: any) => x.uid == membre.id); // Ensemble des inscriptions d'un participant
                            this.rawBind = [];
                            for (let row of rows) {
                                // Mise en forme des données à afficher pour des pratiquants qui ont des inscriptions
                                if (Object.keys(row).length > 0) {
                                    // détermination du lieu de l'activité
                                    let lieu = '';
                                    switch (row.seance.lieuPratique) {
                                        case this.translate.instant('constant.SEANCE_LIEU_INTERNE') || false: // retro compatibilité
                                            lieu = this.translate.instant('seance.label.interne');
                                            break;
                                        case this.translate.instant('constant.SEANCE_LIEU_EXTERNE') || true: // retro compatibilité
                                            lieu = row.seance.lieuExterne.organisation;
                                            break;
                                        case this.translate.instant('constant.SEANCE_LIEU_VISIO'):
                                            lieu = this.translate.instant('seance.label.visio');
                                            break;
                                    }
                                    // On calcul le nombre de présences aux cyles de cette séance
                                    let used = 0;
                                    let participant = row.participants.filter((x: any) => x.uid == row.uid);
                                    if (participant.length > 0) {
                                        // On extrait les présences confirmées
                                        used = participant.filter(
                                            (x: any) => x.presence == this.translate.instant('constant.PRESENT')
                                        ).length;
                                    }

                                    this.rawBind.push({
                                        nom: row.seance.activite.nom,
                                        lieu: lieu,
                                        sessions: row.inscription.details.nbSeances,
                                        used: used,
                                    });
                                }
                            }
                            if (Object.keys(this.rawBind).length > 0) this.inscriptionsBind[rows[0].uid] = this.rawBind;
                        }
                        this.loading = false;
                        this.dataSource = new MatTableDataSource(this.membres);
                        setTimeout(() => {
                            this.toolsService.tabUpdate(
                                'BoardParticipants',
                                this.sort,
                                this.dataSource,
                                this.paginator,
                                {
                                    active: 'lastname',
                                    direction: 'asc',
                                }
                            );
                        });
                    },
                    error: () => {
                        this.loading = false;
                    },
                });
            },
            error: (error) => {
                this.loading = false;
            },
        });
    }

    isPros(id: number) {
        let row = this.membres.find((x) => x.id === id);
        return row!.roles.indexOf('ROLE_PRO') > -1;
    }

    applyFilter(filterValue: any) {
        this.dataSource.filter = filterValue.target!.value.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    sortData($event: any, tabName: string) {
        // On mémorise les filtres
        sessionStorage.setItem(tabName, JSON.stringify($event));
    }

    onPaginateChange($event: any, tabName: string) {
        sessionStorage.setItem(tabName, JSON.stringify($event));
    }
}
