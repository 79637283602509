import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Seance } from '@/_models';
import { ISprogrammation, ISpresence } from '@/_interfaces';

@Injectable({
    providedIn: 'root',
})
export class SeanceService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    currentUser: User = new User();

    constructor(private http: HttpClient) {}

    // Procédure gestion profils (user + enseignant)
    // ==========================================

    // On télécharge la séance
    getSeance(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seances/seance',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    // On télécharge la séance + programmation + participants
    getFullSeance(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seances/fullSeance',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    edit(id: number, seanceData: Seance, action: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seance/edit',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                user_id: this.currentUser.id,
                seanceData: seanceData,
                action: action,
            })
        );
    }

    getAll() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'seances',
            JSON.stringify({
                appKey: this.appKey,
                user_id: this.currentUser.id,
                company: this.currentUser.company,
            })
        );
    }

    getAllByEnseignant(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'seances/enseignant',
            JSON.stringify({
                appKey: this.appKey,
                user_id: id,
                company: this.currentUser.company,
            })
        );
    }

    delete(ids: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seance/delete',
            JSON.stringify({
                appKey: this.appKey,
                ids: ids,
                company: this.currentUser.company,
            })
        );
    }

    copy(ids: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seance/copy',
            JSON.stringify({
                appKey: this.appKey,
                ids: ids,
                company: this.currentUser.company,
            })
        );
    }

    // Gestion de la programmation
    // ===========================

    // On télécharge la programmation
    getProgrammation(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seance/programmation',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    // On met à jour la programmation
    editProgrammation(id: number, programmationData: Array<ISprogrammation>) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seance/programmation/edit',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                programmationData: programmationData,
            })
        );
    }

    // Gestion de la présence
    // ========================

    // On télécharge la présence
    getPresence(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seance/presence',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    // On met à jour la présence
    editPresence(id: number, presenceData: Array<ISpresence>) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seance/presence/edit',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                presenceData: presenceData,
            })
        );
    }

    // Gestion de la structure rattachée à la séance
    // =============================================

    setStructure(seance_id: number, user_id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'seance/setstructure',
            JSON.stringify({
                appKey: this.appKey,
                user: user_id,
                seance: seance_id,
                company: this.currentUser.company,
            })
        );
    }
}
