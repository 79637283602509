export class ISprogrammation {
    id: string = '';
    date: number = 0;
    dateFin: number = 0;
    objectifCycle: string = '';
    objectifSeance: string = '';
    alertes: [] = [];
    commentaire: string = '';
    info: string = '';
    annulation: boolean = false;
}
