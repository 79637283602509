<div class="row" *ngIf="!inProduction">
    <div class="col-12">
        <h4>{{ 'forms.titre.csed' | translate }}</h4>
    </div>
</div>
<div class="form-group" [formGroup]="fieldsForm">
    <ng-container formGroupName="fields">
        <div class="row">
            <div class="col-sm-4 mb-3">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.age' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.age' | translate }}"
                        matInput
                        formControlName="age"
                        [mask]="numericMask"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <span class="text-warning">{{ 'forms.consigne.age' | translate }} </span>
                        <span class="text-danger mt-2" *ngIf="submitted && f['fields'].get('age')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="col-sm-4 mb-3">
                <label>{{ 'forms.label.sexe' | translate }}</label>
                <mat-radio-group formControlName="sexe" class="vertical-radio">
                    <mat-radio-button value="1" class="mr-3">{{ 'forms.choix.genre.h' | translate }}</mat-radio-button>
                    <mat-radio-button value="2" class="mr-3">{{ 'forms.choix.genre.f' | translate }}</mat-radio-button>
                    <mat-radio-button value="3" class="mr-3">{{
                        'forms.choix.genre.autre' | translate
                    }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('sexe')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-4 mb-3">
                <label>{{ 'forms.label.prescription' | translate }}</label>
                <mat-radio-group formControlName="prescription">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('prescription')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.raison' | translate }}</mat-label>
                    <mat-select formControlName="raison" (selectionChange)="onRaisonChange($event, 'raison')">
                        <mat-option *ngFor="let pathologie of listePathologies; let i = index" [value]="pathologie.nom">
                            {{ pathologie.nom }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('raison')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="choixRaison != -1">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.raison_detail' | translate }}</mat-label>
                    <mat-select formControlName="raison_detail">
                        <mat-option
                            *ngFor="let raison of listePathologies[choixRaison].cat; let i = index"
                            [value]="raison"
                        >
                            {{ raison }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('raison_detail')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.ald1' | translate }}</mat-label>
                    <mat-select formControlName="ald1" (selectionChange)="onRaisonChange($event, 'ald1')">
                        <mat-option *ngFor="let pathologie of listePathologies; let i = index" [value]="pathologie.nom">
                            {{ pathologie.nom }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('ald1')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="choixAld1 != -1">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.ald1_detail' | translate }}</mat-label>
                    <mat-select formControlName="ald1_detail">
                        <mat-option
                            *ngFor="let raison of listePathologies[choixAld1].cat; let i = index"
                            [value]="raison"
                        >
                            {{ raison }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('ald1_detail')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.ald2' | translate }}</mat-label>
                    <mat-select formControlName="ald2" (selectionChange)="onRaisonChange($event, 'ald2')">
                        <mat-option *ngFor="let pathologie of listePathologies; let i = index" [value]="pathologie.nom">
                            {{ pathologie.nom }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('ald2')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="choixAld2 != -1">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.ald2_detail' | translate }}</mat-label>
                    <mat-select formControlName="ald2_detail">
                        <mat-option
                            *ngFor="let raison of listePathologies[choixAld2].cat; let i = index"
                            [value]="raison"
                        >
                            {{ raison }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('ald2_detail')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.ald3' | translate }}</mat-label>
                    <mat-select formControlName="ald3" (selectionChange)="onRaisonChange($event, 'ald3')">
                        <mat-option *ngFor="let pathologie of listePathologies; let i = index" [value]="pathologie.nom">
                            {{ pathologie.nom }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('ald3')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="choixAld3 != -1">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.ald3_detail' | translate }}</mat-label>
                    <mat-select formControlName="ald3_detail">
                        <mat-option
                            *ngFor="let raison of listePathologies[choixAld3].cat; let i = index"
                            [value]="raison"
                        >
                            {{ raison }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('ald3_detail')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.ald4' | translate }}</mat-label>
                    <mat-select formControlName="ald4" (selectionChange)="onRaisonChange($event, 'ald4')">
                        <mat-option *ngFor="let pathologie of listePathologies; let i = index" [value]="pathologie.nom">
                            {{ pathologie.nom }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('ald4')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="choixAld4 != -1">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.ald4_detail' | translate }}</mat-label>
                    <mat-select formControlName="ald4_detail">
                        <mat-option
                            *ngFor="let raison of listePathologies[choixAld4].cat; let i = index"
                            [value]="raison"
                        >
                            {{ raison }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('ald4_detail')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-sm-6 mb-3">
                <label>{{ 'forms.label.pec' | translate }}</label>
                <mat-radio-group formControlName="pec" (change)="onSetYN($event, 'pec')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('pec')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-6 mb-3" *ngIf="ouiPec == '1'">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.pec_pc' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.pec_pc' | translate }}"
                        matInput
                        formControlName="pec_pc"
                        [mask]="numericMask"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <span class="text-warning">{{ 'forms.consigne.pourcentage' | translate }} </span>
                        <span
                            class="text-danger mt-2"
                            *ngIf="submitted && f['fields'].get('pec_pc')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div> -->
    </ng-container>
</div>
