import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Company, Enseignant } from '@/_models';
@Injectable({
    providedIn: 'root',
})
export class EnseignantService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    profil: User = new User();
    currentUser: User = new User();

    constructor(private http: HttpClient) {}

    // Procédure gestion profils (user + enseignant)
    // ==========================================

    // On télécharge user + enseignant
    getProfil(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'enseignant/profil',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    getEnseignants() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'enseignants',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    setJson(id: number, enseignantData: Enseignant, field: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'enseignant/edit/json',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                enseignantData: JSON.stringify(enseignantData),
                field: field,
            })
        );
    }
}
