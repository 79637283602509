import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nl2br',
    pure: true,
})
export class nl2brPipe implements PipeTransform {
    transform(value: string): string {
        if (value) return value.replace(/\n/g, '<br/>');
        else return value;
    }
}
