import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Company } from '@/_models';
import { IScompany } from '@/_interfaces';

@Injectable({ providedIn: 'root' })
export class CompanyService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    profil: User = new User();
    currentUser: User = new User();

    constructor(private http: HttpClient) {}

    // Création de la fiche entreprise contient aussi le profil superadmin
    create(values: IScompany) {
        this.profil = values.profil;
        this.company = values.company;
        return this.http.post(
            this.apiUrl + 'company/create',
            JSON.stringify({
                appKey: this.appKey,
                licence: values.licence,
                companyData: this.company,
                profilData: this.profil,
            })
        );
    }

    // Procédure gestion profils (user + company)
    // ==========================================

    getProfil(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'company/profil',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    setCompany(id: number, values: IScompany) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        this.company = values.company;
        return this.http.post(
            this.apiUrl + 'company/edit',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                companyData: this.company,
            })
        );
    }

    setJson(id: number, companyData: Company, field: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'company/edit/json',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                companyData: JSON.stringify(companyData),
                field: field,
            })
        );
    }

    setActivites(id: number, companyData: Company, activites: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'company/edit/activites',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                companyData: JSON.stringify(companyData),
                activites: activites,
            })
        );
    }
}
