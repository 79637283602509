import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ISperiodic } from '@/_interfaces';
import { ToolsService } from '@/_services';

import { CalendarParams } from '@/_helpers';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-periodic',
    templateUrl: './periodic.component.html',
    styleUrls: ['./periodic.component.scss'],
})
export class PeriodicComponent implements OnInit {
    periodicForm: FormGroup;
    periodicBind = new ISperiodic();
    loading = false;
    submitted = false;
    periods = [
        { value: this.translate.instant('constant.DAILY'), name: this.translate.instant('periodic.label.daily') },
        { value: this.translate.instant('constant.WEEKLY'), name: this.translate.instant('periodic.label.weekly') },
        { value: this.translate.instant('constant.MONTHLY'), name: this.translate.instant('periodic.label.monthly') },
        { value: this.translate.instant('constant.YEARLY'), name: this.translate.instant('periodic.label.yearly') },
    ];
    plages: string[] = [
        this.translate.instant('constant.PLAGE_FIN_LE'),
        this.translate.instant('constant.PLAGE_OCCURENCE'),
    ];
    setDaily = false;
    setWeekly = false;
    setMonthly = false;
    setYearly = false;

    daysOfWeekError = true;

    constructor(
        public dialogRef: MatDialogRef<PeriodicComponent>,
        @Inject(MAT_DIALOG_DATA) public periodicData: any,

        private translateService: TranslateService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private translate: TranslateService,
        public calendarParams: CalendarParams,
        private toolsService: ToolsService
    ) {
        this.periodicForm = this.formBuilder.group({
            debut: formBuilder.control('', Validators.required),
            fin: formBuilder.control('', Validators.required),
            periodicite: formBuilder.control('', Validators.required),
            daily: this.formBuilder.group({
                days: formBuilder.control(''),
                alldays: formBuilder.control(false),
            }),
            weekly: this.formBuilder.group({
                weeks: formBuilder.control(''),
                daysOfWeek: this.addDaysOfWeekControls(),
            }),
            plage: this.formBuilder.group({
                debut: formBuilder.control('', Validators.required),
                fin: formBuilder.control(''),
                occurences: formBuilder.control(''),
                choixPlage: formBuilder.control('', Validators.required),
            }),
        });
    }

    get p() {
        return this.periodicForm.controls;
    }

    ngOnInit(): void {
        this.periodicForm.reset();

        // On initialise la périodicité reçue
        this.periodicBind = this.periodicData.periodicite;

        if (this.periodicBind == null || Object.entries(this.periodicBind).length <= 0) {
            // Pas de périodicité déjà définie - On initialise les valeurs par défaut

            // On récupère les dates saisies dans le formulaire principal et on extrait les heures
            this.periodicForm.get('debut')!.setValue(this.periodicData.debut.split('T')[1]);
            this.periodicForm.get('fin')?.setValue(this.periodicData.fin.split('T')[1]);

            // On initialise les différents champs
            this.periodicForm.get('periodicite')?.setValue(this.translate.instant('constant.WEEKLY'));
            this.periodicForm.get('daily.days')?.setValue(1);
            this.periodicForm.get('daily.alldays')?.setValue(false);
            this.periodicForm.get('weekly.weeks')?.setValue(1);
            this.periodicForm.get('weekly.daysOfWeek')?.setValue([null, null, null, null, null, null, null]);

            this.periodicForm.get('plage.debut')?.setValue(this.periodicData.debut);
            this.periodicForm.get('plage.fin')?.setValue(this.periodicData.fin);
            this.periodicForm.get('plage.occurences')?.setValue(1);
            this.periodicForm.get('plage.choixPlage')?.setValue('');

            this.setWeekly = true;
        } else {
            // On récupère les champs précédemment remplis
            this.periodicForm.get('debut')!.setValue(this.periodicBind.debut);
            this.periodicForm.get('fin')?.setValue(this.periodicBind.fin);

            this.periodicForm.get('periodicite')?.setValue(this.periodicBind.periodicite);
            this.periodicForm.get('daily.days')?.setValue(this.periodicBind.daily.days);
            this.periodicForm.get('daily.alldays')?.setValue(this.periodicBind.daily.alldays);
            this.periodicForm.get('weekly.weeks')?.setValue(this.periodicBind.weekly.weeks);
            this.periodicForm.get('weekly.daysOfWeek')?.setValue(this.periodicBind.weekly.daysOfWeek);

            this.periodicForm.get('plage.debut')?.setValue(this.periodicBind.plage.debut);
            this.periodicForm.get('plage.fin')?.setValue(this.periodicBind.plage.fin);
            this.periodicForm.get('plage.occurences')?.setValue(this.periodicBind.plage.occurences);
            this.periodicForm.get('plage.choixPlage')?.setValue(this.periodicBind.plage.choixPlage);

            switch (this.periodicBind.periodicite) {
                case this.translate.instant('constant.DAILY'):
                    this.setDaily = true;
                    break;

                case this.translate.instant('constant.WEEKLY'):
                    this.setWeekly = true;
                    break;

                case this.translate.instant('constant.MONTHLY'):
                    this.setMonthly = true;
                    break;

                case this.translate.instant('constant.YEARLY'):
                    this.setYearly = true;
                    break;
            }
        }
    }

    // Initialisation des jours de la semaine
    // ======================================
    addDaysOfWeekControls() {
        const arr = this.calendarParams.daysOfWeek.map((element, i) => {
            return this.formBuilder.control(false);
        });
        return this.formBuilder.array(arr);
    }

    get daysOfWeekArray(): any {
        return <FormArray>this.periodicForm.get('weekly.daysOfWeek');
    }

    // A chaque fois que l'on change l'état des checkbox
    getSelectedDaysValue(e: any) {
        const box = document.getElementById(e + '-input') as HTMLInputElement;
        if (box.checked) this.daysOfWeekArray.value[e] = true;
        else this.daysOfWeekArray.value[e] = false;

        this.daysOfWeekError = !this.daysOfWeekArray.value.find((element: boolean) => element == true);
    }

    onSubmit(result: string) {
        this.submitted = true;

        if (result == 'Cancel') {
            this.dialogRef.close(result);
            return;
        }

        // stop here if form is invalid
        if (this.periodicForm.invalid) {
            this.toastr.info(this.translate.instant('error.form.invalid'));
            return;
        }

        //Mise en forme Date locale
        let date = formatDate(this.periodicForm.get('plage.debut')!.value, 'yyyy-MM-dd', 'fr-FR');
        this.periodicForm.get('plage.debut')?.setValue(date);

        date = formatDate(this.periodicForm.get('plage.fin')!.value, 'yyyy-MM-dd', 'fr-FR');
        this.periodicForm.get('plage.fin')?.setValue(date);

        this.dialogRef.close(this.periodicForm.value);
    }

    onPeriode($event: any) {
        this.setDaily = false;
        this.setWeekly = false;
        this.setMonthly = false;
        this.setYearly = false;

        switch ($event.value) {
            case this.translate.instant('constant.DAILY'):
                this.setDaily = true;

                this.periodicForm.get('weekly.daysOfWeek')?.setValidators(null); // La liste n'est plus obligatoire
                break;

            case this.translate.instant('constant.WEEKLY'):
                this.setWeekly = true;

                this.periodicForm.get('weekly.daysOfWeek')?.setValidators(Validators.required);
                break;

            case this.translate.instant('constant.MONTHLY'):
                this.setMonthly = true;
                break;

            case this.translate.instant('constant.YEARLY'):
                this.setYearly = true;
                break;
        }
    }
}
