import { ISimage, IScontactUrgence, ISinfoPratiquant, ISsante } from '@/_interfaces';
export class Pratiquant {
    id: number = 0;
    complete: boolean = false;

    contactUrgence: IScontactUrgence = { lastname: '', firstname: '', phone: '', email: '', parente: '' };

    infoPratiquant: ISinfoPratiquant = {
        adressePostale: { adresse: '', complementAdresse: '', zip: '', city: '' },
        phone: '',
        mobile: '',
        preferedPhone: '',
        genre: '',
        birthday: 0,
        nomMutuelle: '',
        autreMutuelle: '',
        nomContratMutuelle: '',
        carteMutuelleValidite: 0,
        nomRetraite: '',
        autreRetraite: '',
        nomSante: '',
        autreSante: '',
        rgpdAccepte: false,
        reglementAccepte: false,
        statutsAccepte: false,
        newsletter: false,
        tutelle: '',
        tuteur: { nom: '', prenom: '', phone: '', email: '' },
        droitImage: '',
        nomReferer: '',
        autreReferer: '',
        referer: { nom: '', prenom: '', phone: '', email: '' },
        cmValidite: 0,
        paValidite: 0,
        fiValidite: 0,
        revenuFiscal: 0,
    };

    sante: ISsante = {
        pathologie: '',
        nomPathologie: '',
        detailPathologie: '',
        ald: false,
        aldValidite: 0,
        pch: false,
        pchValidite: 0,
        apa: false,
        apaValidite: 0,
        ssNum: '',
        acteurs: {
            medecin: { coordonnees: { nom: '', prenom: '', phone: '', email: '' }, profession: '', comm: '' },
            pros: [],
        },
    };

    reglementImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Règlement intérieur
    rgpdImage: ISimage = { image: '', extension: '', name: '', id: '' }; // RGPD
    statutsImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Statuts de l'association
    aldImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Justificatif ALD
    pchImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Justificatif PCH
    apaImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Jusitificatif APA
    cmImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Certificat Médical
    paImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Prescription APA
    apImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Autorisation parentale
    lfImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Livret de famille
    aiImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Avis d'imposition
    fiImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Fiche d'inscription
}
