<div class="row mb-3" *ngIf="!inProduction">
    <div class="col-12">
        <h4>{{ 'forms.titre.dbu' | translate }}</h4>
    </div>
</div>
<div class="form-group" [formGroup]="fieldsForm">
    <ng-container formGroupName="fields">
        <h4 class="mt-2">{{ 'forms.titre.q5' | translate }}</h4>
        <div class="row mb-3">
            <div class="col-sm-4">
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.q5_pied' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <label>{{ 'forms.label.q5_pied' | translate }}</label>
                <br />
                <mat-radio-group formControlName="q5_pied" (change)="onSetYN($event, 'q5_pied')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q5_pied')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-4">
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.q5_velo' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <label>{{ 'forms.label.q5_velo' | translate }}</label>
                <br />
                <mat-radio-group formControlName="q5_velo" (change)="onSetYN($event, 'q5_velo')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q5_velo')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-4">
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.q5_autre' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <label>{{ 'forms.label.q5_autre' | translate }}</label>
                <br />
                <mat-radio-group formControlName="q5_autre" (change)="onSetYN($event, 'q5_autre')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q5_autre')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <ng-container *ngIf="ouiq5_pied == '1' || ouiq5_velo == '1' || ouiq5_autre == '1'">
            <h4>{{ 'forms.titre.q6' | translate }}</h4>
            <div class="row mb-5">
                <div class="col-sm-4" *ngIf="ouiq5_pied == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q6_pied' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q6_pied' | translate }}"
                            matInput
                            formControlName="q6_pied"
                            [mask]="numericMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q6' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q6_pied')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-sm-4" *ngIf="ouiq5_velo == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q6_velo' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q6_velo' | translate }}"
                            matInput
                            formControlName="q6_velo"
                            [mask]="numericMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q6' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q6_velo')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-sm-4" *ngIf="ouiq5_autre == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q6_autre' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q6_autre' | translate }}"
                            matInput
                            formControlName="q6_autre"
                            [mask]="numericMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q6' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q6_autre')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <h4>{{ 'forms.titre.q7' | translate }}</h4>
            <div class="row mb-5">
                <div class="col-sm-4" *ngIf="ouiq5_pied == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q7_pied' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q7_pied' | translate }}"
                            matInput
                            formControlName="q7_pied"
                            [mask]="heureMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q7' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q7_pied')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-sm-4" *ngIf="ouiq5_velo == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q7_velo' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q7_velo' | translate }}"
                            matInput
                            formControlName="q7_velo"
                            [mask]="heureMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q7' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q7_velo')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-sm-4" *ngIf="ouiq5_autre == '1'">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q7_autre' | translate }}</mat-label>
                        <input
                            placeholder="{{ 'forms.label.q7_autre' | translate }}"
                            matInput
                            formControlName="q7_autre"
                            [mask]="heureMask"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q7' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q7_autre')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <h4 class="mt-2">{{ 'forms.titre.q8' | translate }}</h4>
        <div class="row mb-3">
            <div class="col-sm-6">
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.q8' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <label>{{ 'forms.label.q8' | translate }}</label>
                <br />
                <mat-radio-group formControlName="q8" (change)="onSetYN($event, 'q8')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q8')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <div class="row mb-3" *ngIf="ouiq8 == '1'">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.q9' | translate }}"
                        matPrefix
                        >help</mat-icon
                    >
                    <mat-label>{{ 'forms.label.q9' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.q9' | translate }}"
                        matInput
                        formControlName="q9"
                        [mask]="numericMask"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <span class="text-warning">{{ 'forms.consigne.q9' | translate }} </span>
                        <span class="text-danger mt-2" *ngIf="submitted && f['fields'].get('q9')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field>
                    <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.q10' | translate }}"
                        matPrefix
                        >help</mat-icon
                    >
                    <mat-label>{{ 'forms.label.q10' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.q10' | translate }}"
                        matInput
                        formControlName="q10"
                        [mask]="heureMask"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <span class="text-warning">{{ 'forms.consigne.q10' | translate }} </span>
                        <span class="text-danger mt-2" *ngIf="submitted && f['fields'].get('q10')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
</div>
