import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@/_models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    currentUser = new User();
    constructor(private http: HttpClient) {}

    // Procédures gestion user
    // =======================

    getAll() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'users',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    getUser(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'user',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    checkByUsername(username: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'user/checkbyusername',
            JSON.stringify({
                appKey: this.appKey,
                username: username,
                company: this.currentUser.company,
            })
        );
    }

    checkByUserId(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'user/checkbyid',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    create(user: any, roles: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'user/create',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                roles: roles,
                user: user,
            })
        );
    }

    createMass(profils: any) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'user/create/mass',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                profils: profils,
                id: this.currentUser.id,
            })
        );
    }

    edit(id: number, user: User, roles: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'user/edit',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                roles: roles,
                user: user,
            })
        );
    }

    delete(ids: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'user/delete',
            JSON.stringify({
                appKey: this.appKey,
                ids: ids,
                company: this.currentUser.company,
            })
        );
    }

    toggleUserEnable(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'user/toggleEnable',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
            })
        );
    }
}
