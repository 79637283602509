import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Company, Reglement } from '@/_models';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class ReglementService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    profil: User = new User();
    currentUser: User = new User();

    constructor(private http: HttpClient, private translate: TranslateService) {}

    // Load tout les règlements d'une séance tous utilisateurs confondus
    getAllReglements(sid: number, typeClient: boolean = false) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'reglements/all',
            JSON.stringify({
                appKey: this.appKey,
                sid: sid,
                company: this.currentUser.company,
                structure: typeClient,
            })
        );
    }

    // Load tout les règlements d'une séance et d'un utilisateur
    getReglements(sid: number, uid: number, typeClient: boolean = false) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'reglements',
            JSON.stringify({
                appKey: this.appKey,
                sid: sid,
                uid: uid,
                company: this.currentUser.company,
                structure: typeClient,
            })
        );
    }

    // Sauvegarde d'un reglement de séance
    setReglement(reglement: Reglement) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'reglement/edit',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                id: reglement.id,
                historique: reglement.historique,
            })
        );
    }

    // On check l'état des règlements
    checkReglements(reglements: Reglement, cout: number) {
        let encours = 0;
        if (reglements && reglements.hasOwnProperty('historique')) {
            for (let reglement of reglements.historique) {
                encours += reglement.montant;
            }
            if (encours >= cout) {
                return this.translate.instant('constant.PAIEMENT_COMPLET');
            } else {
                return this.translate.instant('constant.PAIEMENT_PARTIEL');
            }
        } else return this.translate.instant('constant.PAIEMENT_ATTENTE');
    }
}
