import { Component, Input, Output, forwardRef, OnDestroy, EventEmitter } from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
    NG_VALIDATORS,
} from '@angular/forms';

import { Subscription } from 'rxjs';

import { AuthenticationService, ToolsService } from '@/_services';

import { User } from '@/_models';

// describes what the return value of the form control will look like
export interface scoresFormValues {
    titre: string;
    description: string;
    selector: string;
    type: 0;
    fields: {
        nap_min_score: string;
        nap_mets_score: string;
        sed_score: string;
        ps_score: string;
    };
}

@Component({
    selector: 'app-scores',
    templateUrl: './scores.component.html',
    styleUrls: ['./scores.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ScoresComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ScoresComponent),
            multi: true,
        },
    ],
})
export class ScoresComponent implements ControlValueAccessor, OnDestroy {
    @Input() inData: any;
    @Input() inProduction: any;
    @Input() dataLoaded: any;
    @Output() parentFormSubmittedChange = new EventEmitter<boolean>();
    @Input() set parentFormSubmitted(isSubmitted: boolean) {
        if (isSubmitted) {
            this.submitted = true;
            setTimeout(() => {
                this.parentFormSubmittedChange.next(false);
            });
        }
    }

    currentUser: User;
    loading = false;
    submitted = false;
    isAdmin: boolean;
    formID = 0;
    returnUrl: string = '/dashboard/forms';
    fieldsForm: FormGroup;
    subscriptions: Subscription[] = [];
    listeModes: Array<any> = [];

    constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private toolsService: ToolsService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);
        // Le nom des champs correspond aux noms ONAPS
        this.fieldsForm = this.formBuilder.group({
            selector: this.formBuilder.control('app-scores'),
            fields: this.formBuilder.group({
                nap_min_score: this.formBuilder.control(''),
                nap_mets_score: this.formBuilder.control(''),
                sed_score: this.formBuilder.control(''),
                ps_score: this.formBuilder.control(''),
            }),
        });

        this.subscriptions.push(
            // any time the inner form changes update the parent of any change
            this.fieldsForm.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.fieldsForm.controls;
    }

    get value(): scoresFormValues {
        return this.fieldsForm.value;
    }

    set value(value: scoresFormValues) {
        this.fieldsForm.patchValue(value);
        this.onChange(value);
        this.onTouched();
    }

    // communicate the inner form validation to the parent form
    validate(_: FormControl) {
        return this.fieldsForm.valid ? null : { genericFields: { valid: false } };
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
            this.fieldsForm.patchValue(this.value);
        }

        if (value === null) {
            this.fieldsForm.reset();
        }
    }
    // On initialise le formulaire avec les calculs précédents s'ils existent
    ngAfterContentInit(): void {
        // Est-ce que les données sont exploitables ?
        if (this.dataLoaded) {
            // aat
            let q2_fort = 0;
            let q2_modere = 0;
            let q3_fort = 0;
            let q3_modere = 0;
            let q4 = 0;

            //dpu
            let q6_pied = 0;
            let q6_velo = 0;
            let q7_pied = 0;
            let q7_velo = 0;
            let q9 = 0;
            let q10 = 0;

            // adload
            let q15_fort = 0;
            let q15_modere = 0;
            let q16_fort = 0;
            let q16_modere = 0;
            let q18_ecran = 0;
            let q18_autre = 0;
            let q19_ecran = 0;
            let q19_autre = 0;

            //eps
            let garnier_q1 = 0;
            let garnier_q2 = 0;
            let garnier_q3 = 0;
            let garnier_q4 = 0;
            let garnier_q5 = 0;
            let garnier_q6 = 0;
            let garnier_q7 = 0;
            let garnier_q8 = 0;

            // On assigne les valeurs dont on a besoin pour le calcul des scores.
            this.inData.forEach((o: any, i: number) => {
                if (o.formBind != undefined) {
                    switch (o.formBind.genericFields.selector) {
                        case 'app-aat':
                            q2_fort = o.formBind.genericFields.fields.q2_fort;
                            q2_modere = o.formBind.genericFields.fields.q2_modere;
                            q3_fort = o.formBind.genericFields.fields.q3_fort;
                            q3_modere = o.formBind.genericFields.fields.q3_modere;
                            q4 = o.formBind.genericFields.fields.q4;
                            break;

                        case 'app-dpu':
                            q6_pied = o.formBind.genericFields.fields.q6_pied;
                            q6_velo = o.formBind.genericFields.fields.q6_velo;
                            q7_pied = o.formBind.genericFields.fields.q7_pied;
                            q7_velo = o.formBind.genericFields.fields.q7_velo;
                            q9 = o.formBind.genericFields.fields.q9;
                            q10 = o.formBind.genericFields.fields.q10;
                            break;

                        case 'app-adload':
                            q15_fort = o.formBind.genericFields.fields.q15_fort;
                            q15_modere = o.formBind.genericFields.fields.q15_modere;
                            q16_fort = o.formBind.genericFields.fields.q16_fort;
                            q16_modere = o.formBind.genericFields.fields.q16_modere;
                            q18_ecran = o.formBind.genericFields.fields.q18_ecran;
                            q18_autre = o.formBind.genericFields.fields.q18_autre;
                            q19_ecran = o.formBind.genericFields.fields.q19_ecran;
                            q19_autre = o.formBind.genericFields.fields.q19_autre;
                            break;

                        case 'app-eps':
                            garnier_q1 = o.formBind.genericFields.fields.garnier_q1;
                            garnier_q2 = o.formBind.genericFields.fields.garnier_q2;
                            garnier_q3 = o.formBind.genericFields.fields.garnier_q3;
                            garnier_q4 = o.formBind.genericFields.fields.garnier_q4;
                            garnier_q5 = o.formBind.genericFields.fields.garnier_q5;
                            garnier_q6 = o.formBind.genericFields.fields.garnier_q6;
                            garnier_q7 = o.formBind.genericFields.fields.garnier_q7;
                            garnier_q8 = o.formBind.genericFields.fields.garnier_q8;
                            break;
                    }
                }
            });

            let nap_min_score =
                q2_fort * q3_fort +
                q15_fort * q16_fort +
                (q2_modere * q3_modere + q6_pied * q7_pied + q6_velo * q7_velo + q15_modere * q16_modere);
            this.fieldsForm.get('fields.nap_min_score')!.setValue(Number(nap_min_score).toFixed(1));

            let nap_mets_score =
                8 * (q2_fort * q3_fort) +
                (4 * (q2_modere * q3_modere + q15_modere * q16_modere) +
                    3.3 * (q6_pied * q7_pied) +
                    6 * (q6_velo * q7_velo));
            this.fieldsForm.get('fields.nap_mets_score')!.setValue(Number(nap_mets_score).toFixed(1));

            let sed_score = q4 + (q9 * q10 + q18_ecran * q19_ecran + q18_autre * q19_autre) / 7;
            this.fieldsForm.get('fields.sed_score')!.setValue(Number(sed_score).toFixed(1));

            let ps_score =
                (garnier_q1 +
                    garnier_q2 +
                    garnier_q3 +
                    garnier_q4 +
                    garnier_q5 +
                    garnier_q6 +
                    garnier_q7 +
                    garnier_q8) /
                8;
            this.fieldsForm.get('fields.ps_score')!.setValue(Number(ps_score).toFixed(1));

            //this.writeValue(this.inData.formBind.genericFields);
        } else {
            // On place les scores à 0
            this.fieldsForm.get('fields.nap_min_score')!.setValue(Number(0));
            this.fieldsForm.get('fields.nap_mets_score')!.setValue(Number(0));
            this.fieldsForm.get('fields.sed_score')!.setValue(Number(0));
            this.fieldsForm.get('fields.ps_score')!.setValue(Number(0));
        }
    }
}
