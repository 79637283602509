import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Presences {
    presences: Array<string> = [];

    constructor(private translate: TranslateService) {
        // Types de présences pour les participants
        this.presences[this.translate.instant('constant.ABSENT')] = this.translate.instant(
            'seance.participants.label.absent'
        );
        this.presences[this.translate.instant('constant.EXCUSE')] = this.translate.instant(
            'seance.participants.label.excuse'
        );
        this.presences[this.translate.instant('constant.PRESENT')] = this.translate.instant(
            'seance.participants.label.present'
        );
        this.presences[this.translate.instant('constant.NON_INSCRIT')] = this.translate.instant(
            'seance.participants.label.nonInscrit'
        );
    }
}
