import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Company, Enseignant } from '@/_models';
import { IStest } from '@/_interfaces';

@Injectable({
    providedIn: 'root',
})
export class TestService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    currentUser: User = new User();

    constructor(private http: HttpClient) {}

    setTest(id: number, test: IStest, action: string, test_type: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'tests/edit',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                test: test,
                action: action,
                test_type: test_type,
            })
        );
    }

    setCsv(data: any) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'tests/setcsv',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                data: data,
            })
        );
    }

    getAll() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'tests/tests',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    getTest(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'tests/test',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    delete(ids: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'tests/delete',
            JSON.stringify({
                appKey: this.appKey,
                ids: ids,
                company: this.currentUser.company,
            })
        );
    }

    copy(ids: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'tests/copy',
            JSON.stringify({
                appKey: this.appKey,
                ids: ids,
                company: this.currentUser.company,
            })
        );
    }
}
