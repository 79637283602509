import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Structure } from '@/_models';
@Injectable({
    providedIn: 'root',
})
export class ProService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    currentUser: User = new User();

    constructor(private http: HttpClient) {}
}
