import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import { User, Company } from '@/_models';
import { ISperiodic, IScalcPeriodic } from '@/_interfaces';
import { formatDate } from '@angular/common';
@Injectable({
    providedIn: 'root',
})
export class CalendarService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    profil: User = new User();
    currentUser: User = new User();

    constructor(private http: HttpClient, private translate: TranslateService) {}

    // Procédure gestion profils (user + enseignant)
    // ==========================================

    // On sauvegardes le calendrier
    setEvents(events: any, user_id: number, category: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'calendar/setEvents',
            JSON.stringify({
                appKey: this.appKey,
                events: events,
                id: user_id,
                category: category,
                company: this.currentUser.company,
            })
        );
    }

    // On récupère le calendrier
    getEvents(user_id: number, category: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'calendar/getEvents',
            JSON.stringify({
                appKey: this.appKey,
                id: user_id,
                category: category,
                company: this.currentUser.company,
            })
        );
    }

    // On récupère les parametres du calendrier
    getParams(user_id: number, category: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'calendar/getParams',
            JSON.stringify({
                appKey: this.appKey,
                id: user_id,
                category: category,
                company: this.currentUser.company,
            })
        );
    }

    // On set les parametres du calendrier
    setParams(params: any, user_id: number, category: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'calendar/setParams',
            JSON.stringify({
                appKey: this.appKey,
                id: user_id,
                params: params,
                category: category,
                company: this.currentUser.company,
            })
        );
    }

    // On calcul la périodicité
    // ========================
    calcPeriodic(periodicite: ISperiodic, dateDebut = 0, dateFin = 0) {
        let calcPeriode = new IScalcPeriodic();

        if (periodicite !== null && Object.entries(periodicite).length > 0) {
            // Date début et fin sont celles définies dans la périodicité
            let heureDebut = periodicite.debut.split(':');
            dateDebut = new Date(periodicite.plage.debut).setHours(Number(heureDebut[0]), Number(heureDebut[1]));
            let heureFin = periodicite.fin.split(':');
            dateFin = new Date(periodicite.plage.fin).setHours(Number(heureFin[0]), Number(heureFin[1]));

            calcPeriode.debut = periodicite.debut; // On récupère heures début et fin
            calcPeriode.fin = periodicite.fin;
            let increment = 0;
            let counter = 0;
            let timestamp = new Date(dateDebut);
            let fin = new Date(periodicite.plage.fin).setHours(23, 59); // Fin avec une plage complète sur la journée

            switch (parseInt(periodicite.periodicite)) {
                case this.translate.instant('constant.DAILY'):
                    switch (periodicite.daily.alldays) {
                        case true:
                            increment = 1;
                            break;

                        case false:
                            increment = parseInt(periodicite.daily.days);
                            break;
                    }
                    switch (parseInt(periodicite.plage.choixPlage)) {
                        case this.translate.instant('constant.PLAGE_FIN_LE'):
                            while (timestamp.getTime() <= fin) {
                                calcPeriode.dates.push(timestamp.getTime());
                                timestamp.setDate(timestamp.getDate() + increment);
                            }
                            break;
                        case this.translate.instant('constant.PLAGE_OCCURENCE'):
                            while (counter < parseInt(periodicite.plage.occurences)) {
                                calcPeriode.dates.push(timestamp.getTime());
                                timestamp.setDate(timestamp.getDate() + increment);
                                counter++;
                            }
                            break;
                    }

                    break;
                case this.translate.instant('constant.WEEKLY'):
                    increment = parseInt(periodicite.weekly.weeks);
                    switch (parseInt(periodicite.plage.choixPlage)) {
                        case this.translate.instant('constant.PLAGE_FIN_LE'):
                            while (timestamp.getTime() <= fin) {
                                let jours = 0;
                                while (jours < 7 && timestamp.getTime() <= fin) {
                                    // On compte les semaines de 7 jours
                                    let day = new Date(timestamp).getDay();
                                    if (periodicite.weekly.daysOfWeek[day]) calcPeriode.dates.push(timestamp.getTime());
                                    timestamp.setDate(timestamp.getDate() + increment);
                                    jours++;
                                }
                            }
                            break;
                        case this.translate.instant('constant.PLAGE_OCCURENCE'):
                            while (counter < parseInt(periodicite.plage.occurences)) {
                                let jours = 0;
                                while (jours < 7 && counter < parseInt(periodicite.plage.occurences)) {
                                    // On compte les semaines de 7 jours
                                    let day = new Date(timestamp).getDay();
                                    if (periodicite.weekly.daysOfWeek[day]) calcPeriode.dates.push(timestamp.getTime());
                                    timestamp.setDate(timestamp.getDate() + increment);
                                    jours++;
                                }
                                counter++;
                            }
                            break;
                    }

                    break;
            }
        } else {
            // Un seul évènement dans le calendrier
            calcPeriode.dates.push(new Date(dateDebut).getTime()); // temps en Millisecondes
            calcPeriode.debut = formatDate(dateDebut, 'HH:mm', 'fr-FR');
            calcPeriode.fin = formatDate(dateFin, 'HH:mm', 'fr-FR');
        }

        return calcPeriode;
    }
}
