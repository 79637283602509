<div *ngIf="loading" class="d-flex justify-content-center loader">
    <div class="spinner-border text-danger" role="status" style="width: 3rem; height: 3rem"></div>
</div>
<ng-container *ngIf="!initializing">
    <div class="row" *ngIf="ownerAgenda != ''">
        <div class="col-12">
            <h4>
                {{ 'calendar.titre.owner' | translate }} : <span class="text-primary">{{ ownerAgenda }}</span>
            </h4>
        </div>
    </div>
    <mat-tab-group dynamicHeight (selectedTabChange)="onTabChange($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">calendar_today</mat-icon>&nbsp;
                {{ 'calendar.titre.agenda' | translate }}
            </ng-template>

            <div id="external-events" #external class="mt-3">
                <div class="row">
                    <div class="col-md-3">
                        <div
                            class="fc-event btn-primary light"
                            data-type='{"extendedProps":{"type":"RDV","class":"event-rdv"}}'
                            matTooltipClass="custom-tooltip"
                            matToolTipPosition="above"
                            matTooltip="{{ 'calendar.help.draganddrop' | translate }}"
                        >
                            <span>{{ 'calendar.label.rdv' | translate }}</span>
                        </div>
                    </div>
                    <div class="col-md-3" *ngIf="currentUser.params.rights.editSeances || isAdmin">
                        <div
                            *ngIf="!IsPratiquant"
                            class="fc-event btn-danger light"
                            data-type='{"extendedProps":{"type":"SEANCE","class":"event-seance"}}'
                            matTooltipClass="custom-tooltip"
                            matToolTipPosition="above"
                            matTooltip="{{ 'calendar.help.draganddrop' | translate }}"
                        >
                            <span>{{ 'calendar.label.dateSeance' | translate }}</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <!-- <div
                                class="fc-event btn-info light"
                                data-type='{"extendedProps":{"type":"RAPPEL","class":"event-alarme"}}'
                            >
                                <span>{{ 'calendar.label.rappelEvent' | translate }}</span>
                            </div> -->
                    </div>
                    <div class="col-md-3">
                        <mat-icon (click)="synchronization()" class="text-primary pointer">sync_problem</mat-icon>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12" fxLayout="row" fxLayout.xs="column" style="height: 50px">
                        <div
                            id="delete-zone"
                            fxLayoutAlign="center center"
                            #deleteZone
                            style="border: 1px solid #fff; width: 100%; height: 100%"
                        >
                            {{ 'calendar.label.delete' | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <full-calendar
                id="fullcalendar"
                #fullcalendar
                class="dashboard-full-calendar"
                *ngIf="calendarVisible"
                [options]="calendarOptions"
            ></full-calendar>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">build</mat-icon>&nbsp;
                {{ 'calendar.titre.parametrage' | translate }}
            </ng-template>
            <div class="row mt-3">
                <div class="col-12">
                    <h4>{{ 'calendar.titre.plageActivites' | translate }}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div *ngFor="let control of daysOfWeekArray.controls; let i = index">
                        <div class="row">
                            <div class="col-sm-4">
                                <mat-checkbox
                                    [checked]="control.value"
                                    (change)="getSelectedDaysOfWeekValue('daysOfWeek-' + i)"
                                    id="daysOfWeek-{{ i }}"
                                    >{{ calendarParams.daysOfWeek[i].name }}
                                </mat-checkbox>
                            </div>
                            <div class="col-sm-3">
                                <input
                                    type="time"
                                    id="startTime-{{ i }}"
                                    *ngIf="showDays[i] != null"
                                    class="time"
                                    (change)="getSelectedDaysOfWeekValue('startTime-' + i)"
                                    [value]="showDays[i].startTime"
                                />
                            </div>
                            <div class="col-sm-3">
                                <input
                                    type="time"
                                    id="endTime-{{ i }}"
                                    *ngIf="showDays[i] != null"
                                    class="time"
                                    (change)="getSelectedDaysOfWeekValue('endTime-' + i)"
                                    [value]="showDays[i].endTime"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-container>
