import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@/_models';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LicenceService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    currentUser!: User;

    constructor(private http: HttpClient) {}

    getAll() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'licences',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser!.company,
                id: this.currentUser.id,
            })
        );
    }

    getParams() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'licence/getParams',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser!.company,
                id: this.currentUser.id,
            })
        );
    }

    setParams(parametres: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'licence/setParams',
            JSON.stringify({
                appKey: this.appKey,
                params: parametres,
                company: this.currentUser!.company,
                id: this.currentUser.id,
            })
        );
    }
}
