import { Component, OnInit } from '@angular/core';
import { ISimage } from '@/_interfaces';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    ToolsService,
    DocumentService,
    AuthenticationService,
    CompanyService,
    ReglementService,
    PratiquantService,
} from '@/_services';
import { ISinscrit, IScompany, ISprogrammation, ISpresence } from '@/_interfaces';
import { Seance, Company, User, Reglement } from '@/_models';
import { Presences } from '@/_helpers';

/* Pdf fiche de présence */
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { formatDate } from '@angular/common';

@Component({
    selector: 'app-presence',
    templateUrl: './presence.component.html',
    styleUrls: ['./presence.component.scss'],
})
export class PresenceComponent implements OnInit {
    loading = false;
    submitted = false;
    inscritsBind: Array<ISinscrit> = [];
    presencesBind: Array<ISpresence> = [];
    nbInscrits = 0;
    seanceBind = new Seance();
    companyBind = new Company();
    adminBind: Array<{ total: number; complete: false }> = [];
    currentUser: User;
    programmationBind!: ISprogrammation;
    allReglements: Array<Reglement> = [];
    seanceID = 0;
    returnUrl = { root: '', programmation: '', returnurl: '' };

    // Set local for file upload
    currentFile!: File;
    progress = 0;

    // Set local variables
    headerNumLine = 0;

    // Zoning setting
    leftMargin = 12; // Marge gauche
    fontSize = 12;
    fontSizeMedium = 10;
    fontSizeSmall = 8;
    fontSizeExtraSmall = 6;
    interLigne = 5; // Interligne des textes
    headerHeight = 55; // Margin top
    footerHeight = 30; // Margin bottom
    textColor = 0;
    widthLogo = 50;
    heightLogo = 50;
    widthMiniLogo = 20;
    heightMiniLogo = 14.5;

    logo: ISimage = { id: '0', name: '', image: '', extension: '' };

    constructor(
        private documentService: DocumentService,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private companyService: CompanyService,
        private router: Router,
        private reglementService: ReglementService,
        public presences: Presences,
        public pratiquantService: PratiquantService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;

        this.inscritsBind = JSON.parse(sessionStorage.getItem('inscritsPresence')!);
        this.seanceBind = JSON.parse(sessionStorage.getItem('seancePresence')!);
        this.programmationBind = JSON.parse(sessionStorage.getItem('programmationPresence')!);
        this.presencesBind = JSON.parse(sessionStorage.getItem('presencesPresence')!);
        this.seanceID = JSON.parse(sessionStorage.getItem('seanceIDPresence')!);
        this.returnUrl = JSON.parse(sessionStorage.getItem('returnUrlPresence')!);
    }

    ngOnInit(): void {
        this.nbInscrits = this.inscritsBind.length;

        // On récupère les éléments de la structure
        this.loading = true;
        this.companyService.getProfil(this.currentUser.id!).subscribe({
            next: (data) => {
                let datas: IScompany = Object(data);
                if (data !== null && Object.keys(data).length !== 0) {
                    this.companyBind = datas['company'];

                    // On récupère le logo de la structure
                    this.documentService.getImage('', this.translate.instant('constant.DOCU_TYPE_LOGO')).subscribe({
                        next: (image) => {
                            this.logo = image;
                        },
                        error: () => {
                            this.logo.name = '';
                        },
                    });
                }

                this.reglementService.getAllReglements(this.seanceID).subscribe({
                    next: (data) => {
                        this.allReglements = data;

                        // On prépare les donnés de la colonne administratif
                        this.presencesBind.forEach((o: any, i) => {
                            this.loading = true;
                            // Calcul de l'encourt des paiements
                            let total = 0;
                            if (this.allReglements.length > 0) {
                                let historique = this.allReglements.find((x) => x.uid == o.uid)?.historique;
                                historique!.forEach((o: any, i) => {
                                    total += o.montant;
                                });

                                let inscrit = this.inscritsBind.find((x) => x.uid == o.uid);
                                total = inscrit!.inscription.details.cout! - total;
                            }
                            // récupération état de saisie du profil
                            this.pratiquantService.getProfil(o.uid).subscribe({
                                next: (data: any) => {
                                    this.loading = false;
                                    let datas = Object(data);
                                    this.adminBind[o.uid] = { total: total, complete: datas.pratiquant.complete };
                                    this.sendPresence();
                                    this.router
                                        .navigateByUrl('/dashboard/seances', { skipLocationChange: true })
                                        .then(() => {
                                            this.router.navigate([this.returnUrl.root], {
                                                queryParams: {
                                                    returnUrl: this.returnUrl.returnurl,
                                                    programmation: this.returnUrl.programmation,
                                                },
                                            });
                                        });
                                },
                            });
                        });
                    },
                    error: () => {
                        this.loading = false;
                    },
                });
            },
            error: () => {
                this.loading = false;
            },
        });
    }

    sendPresence() {
        let body: Array<any> = [];

        // On construit le PDF pour l'impression de la fiche de présence
        const pdf = new jsPDF({ unit: 'mm', format: 'a4', orientation: 'p', compress: true });
        let widthPage = pdf.internal.pageSize.getWidth();
        let midPage = widthPage / 2;
        let heightPage = pdf.internal.pageSize.getHeight();

        pdf.setTextColor(this.textColor);

        // On renseigne les champs en tête de la première page
        (pdf as any).autoTable({
            margin: {
                top: this.headerHeight,
                left: this.leftMargin,
                bottom: this.footerHeight,
            },
            styles: {
                fontSize: this.fontSize,
                textColor: this.textColor,
                lineWidth: 0,
                lineColor: '#000',
            },
            columnStyles: {
                0: {
                    // Titre
                    halign: 'left',
                    fontStyle: 'bold',
                },
                1: {
                    // Texte
                    halign: 'left',
                    fontStyle: 'normal',
                },
            },
            theme: 'plain',
            html: '#headerTable',
        });

        // Tableau des états des  participants
        (pdf as any).autoTable({
            margin: {
                top: this.headerHeight,
                left: this.leftMargin,
                bottom: this.footerHeight,
            },
            styles: {
                fontSize: this.fontSize,
                textColor: this.textColor,
                lineWidth: 0.1,
                lineColor: '#000',
            },
            headStyles: {
                halign: 'center',
                valign: 'middle',
                fontStyle: 'bold',
            },
            columnStyles: {
                0: {
                    // Nom du participant
                    halign: 'left',
                    cellWidth: 60,
                },
                1: {
                    // Etat des présences
                    halign: 'left',
                    cellWidth: 25,
                },
                2: {
                    // Commentaire individuel
                    halign: 'left',
                    cellWidth: 60,
                },
                3: {
                    // Administratif (Alertes)
                    halign: 'left',
                    cellWidth: 40,
                },
            },
            pageBreak: 'auto',
            rowPageBreak: 'avoid',
            showFoot: 'lastPage',
            html: '#participantTable',
            theme: 'plain',
            didParseCell: (data: any) => {},
            didDrawCell: (data: any) => {},
            didDrawPage: (data: any) => {
                // La page est terminée

                // Modification du top du tableau pour la prochaine page
                // data.settings.margin.top = this.headerHeight,

                // Insertion header et footer
                this.setHeaderFooter(pdf);
            },
        });

        // On numérote les pages
        let pageCount = pdf.getNumberOfPages();
        pdf.setFont('helvetica', 'normal');
        pdf.setFontSize(this.fontSizeSmall);
        for (let i = 1; i <= pageCount; i++) {
            pdf.setPage(i);
            pdf.text(
                this.translate.instant('presence.preview.pageNumber') +
                    i +
                    this.translate.instant('presence.preview.totalPageNumber') +
                    pageCount,
                widthPage - this.leftMargin * 2,
                heightPage - (10 - this.interLigne),
                { align: 'left' }
            );
        }

        // Open PDF document in browser's new tab
        pdf.output('dataurlnewwindow');
    }

    private setHeaderFooter(pdf: jsPDF) {
        // ==========================================
        // Insertion header et footer sur chaque page
        // ==========================================

        let midPage = pdf.internal.pageSize.getWidth() / 2;
        let heightPage = pdf.internal.pageSize.getHeight();

        pdf.setFontSize(this.fontSize);

        // header - LOGO
        if (this.logo.name !== '')
            pdf.addImage(this.logo.image, this.logo.extension, 5, 5, this.widthLogo, this.heightLogo);
        else pdf.text(this.companyBind.name, this.leftMargin, 10);

        // header - Adresses entreprise et Client
        this.headerNumLine = 0;
        let seance = '';
        switch (this.seanceBind.lieuPratique) {
            case this.translate.instant('constant.SEANCE_LIEU_INTERNE'):
                seance =
                    this.seanceBind.activite.nom + ' - ' + this.translate.instant('seance.participants.label.local');
                break;
            case this.translate.instant('constant.SEANCE_LIEU_EXTERNE'):
                seance = this.seanceBind.activite.nom + ' - ' + this.seanceBind.lieuExterne.organisation;
                break;
            case this.translate.instant('constant.SEANCE_LIEU_VISIO'):
                seance =
                    this.seanceBind.activite.nom + ' - ' + this.translate.instant('seance.participants.label.visio');
                break;
        }

        seance +=
            '   ' +
            this.translate.instant('seance.participants.titre.date') +
            formatDate(this.programmationBind.date, 'longDate', 'fr-FR');

        pdf.setFont('helvetica', 'bold');
        pdf.text(seance, this.widthLogo + this.leftMargin, this.headerNumLine * this.interLigne + 10);

        // ======
        // footer
        // ======

        // footer - LOGO
        let img = new Image();
        let src = '/assets/images/logo-acti3s.jpg';
        img.src = src;

        pdf.addImage(
            img,
            'JPEG',
            5,
            heightPage - this.heightMiniLogo - this.interLigne,
            this.widthMiniLogo,
            this.heightMiniLogo
        );

        // footer - Text
        let footer = [
            this.translate.instant('document.footer.date', {
                date: formatDate(new Date(), 'shortDate', 'fr-FR'),
            }),
            this.translate.instant('document.footer.suivi'),
        ];

        pdf.setFont('helvetica', 'normal');
        pdf.setFontSize(this.fontSize);
        pdf.text(footer, midPage, heightPage - this.interLigne * 2, {
            align: 'center',
        });

        // pdf.setFont('helvetica', 'normal');
        // pdf.setFontSize(this.fontSizeSmall);
        // pdf.text(
        //     this.entrepriseBind.nom +
        //         ', ' +
        //         this.entrepriseBind.address +
        //         ' - ' +
        //         this.entrepriseBind.complementAdresse +
        //         ' - ' +
        //         this.entrepriseBind.zip +
        //         ' ' +
        //         this.entrepriseBind.city,
        //     midPage,
        //     heightPage - 10,
        //     { align: 'center' }
        // );
        // pdf.text(
        //     this.translate.instant('devis.preview.siretNumber') +
        //         this.entrepriseBind.siret +
        //         this.translate.instant('devis.preview.tvaIntra') +
        //         this.entrepriseBind.tva,
        //     midPage,
        //     heightPage - (10 - this.interLigne),
        //     { align: 'center' }
        // );
    }
}
