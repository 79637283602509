import { Component, OnInit, ViewChild } from '@angular/core';

import { User } from '@/_models';
import { ISprofil } from '@/_interfaces';
import { ToolsService, AuthenticationService, EnseignantService } from '@/_services';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { Subject } from 'rxjs';

@Component({
    selector: 'app-board-agendas',
    templateUrl: './board-agendas.component.html',
    styleUrls: ['./board-agendas.component.scss'],
})
export class BoardAgendasComponent implements OnInit {
    currentUser: User;
    params: [] = [];
    user = new User();
    selectedRows = <any>[];
    inscriptionsBind = <any>[];
    rawBind = <any>[];
    loading = false;
    returnUrl: string = '/dashboard/company/';
    membres: Array<any> = [];
    isPro = false;

    listEnseignants: Array<ISprofil> = [];
    userID = 0;
    sendData: Subject<{}> = new Subject<{}>();

    // Trie des colonnes de tableau
    displayedColumns: string[] = ['firstname', 'lastname'];
    dataSource = new MatTableDataSource(this.listEnseignants);

    constructor(
        private authenticationService: AuthenticationService,

        private toolsService: ToolsService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private enseignantService: EnseignantService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isPro = this.authenticationService.isPro(this.currentUser);
    }

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    ngOnInit(): void {
        // On charge la liste des enseignants
        this.loading = true;
        this.enseignantService.getEnseignants().subscribe({
            next: (enseignants) => {
                this.loading = false;
                this.listEnseignants = Object(enseignants);
                this.dataSource = new MatTableDataSource(this.listEnseignants);
                setTimeout(() => {
                    this.toolsService.tabUpdate('BoardEnseignants', this.sort, this.dataSource, this.paginator, {
                        active: 'lastname',
                        direction: 'asc',
                    });
                });
                this.sendData.next({ userID: this.listEnseignants[0].id });
            },
            error: () => {
                this.loading = false;
            },
        });
    }

    getAgendaOfEnseignant(id: number) {
        this.userID = id;
        this.sendData.next({ userID: this.userID });
    }

    applyFilter(filterValue: any) {
        this.dataSource.filter = filterValue.target!.value.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    sortData($event: any, tabName: string) {
        // On mémorise les filtres
        sessionStorage.setItem(tabName, JSON.stringify($event));
    }

    onPaginateChange($event: any, tabName: string) {
        sessionStorage.setItem(tabName, JSON.stringify($event));
    }
}
