import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@/_models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ParametresService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    currentUser: User | undefined;
    constructor(private http: HttpClient) {}

    get() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'parametres/get',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser!.company,
            })
        );
    }

    set(parametres: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'parametres/set',
            JSON.stringify({
                appKey: this.appKey,
                parametres: parametres,
                company: this.currentUser!.company,
            })
        );
    }
}
