<div id="modal-content-wrapper">
    <div class="card">
        <div class="card-header d-block">
            <p class="text-primary">
                {{ modalData.title }}
                <span class="pull-right" (click)="close()"> <mat-icon class="text-white">highlight_off</mat-icon></span>
            </p>
        </div>
        <div class="card-body">
            <p [innerHTML]="modalData.description"></p>
            <img *ngIf="modalData.image" src="{{ modalData.image['image'] }}" />
        </div>
    </div>
</div>
