import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Company, Enseignant } from '@/_models';
@Injectable({
    providedIn: 'root',
})
export class EventsService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    currentUser: User = new User();

    constructor(private http: HttpClient) {}

    setEvent(type: number, event: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'notifs/set',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                type: type,
                notif: event,
            })
        );
    }
}
