import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@/_services';
import { ISerrors } from '@/_interfaces';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    errors: ISerrors = { status: 0, message: '' };
    errorMessage = '';

    constructor(
        private injector: Injector,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 401:
                            if (
                                error.error.message != 'Invalid credentials.' &&
                                error.error.message != 'Identifiants invalides.'
                            ) {
                                if (error.error.message == 'Expired JWT Token') {
                                    return this.handle401Error(request, next);
                                } else {
                                    return this.handle401Error(request, next);
                                    // error.error.message = this.translate.instant(error.error.message);
                                    // this.errorMessage = `Api: ${error.error.message}`;
                                    // this.toastr.error(this.errorMessage);
                                    // this.router.navigate(['/dashboard/access-denied']);
                                }
                            } else {
                                error.error.message = this.translate.instant(error.error.message);
                                this.errorMessage = `Api: ${error.error.message}`;
                                this.toastr.warning(this.errorMessage);
                            }
                            break;

                        case 404:
                            // To do - créer une page erreur et inviter l'utilisateur à générer une réclamation
                            error.error.message = this.translate.instant(error.error.message);
                            this.errorMessage = `Api: ${error.error.message}`;
                            this.toastr.warning(this.errorMessage);
                            request = request.clone({
                                setHeaders: {
                                    Authorization: 'Bearer ' + sessionStorage.getItem('jwt_token'),
                                },
                            });
                            return next.handle(request);
                            break;

                        case 500:
                            // To do - créer une page erreur et inviter l'utilisateur à générer une réclamation
                            this.errorMessage = `Api: ${error.message}`;
                            this.toastr.error(this.errorMessage);
                            break;

                        default:
                            error.error.message = this.translate.instant(error.error.message);
                            if (error.error instanceof ErrorEvent) {
                                // client-side error
                                this.errorMessage = `Erreur: ${error.error.message}`;
                            } else {
                                // server-side error
                                this.errorMessage = `Api: ${error.error.message}`;
                            }

                            this.toastr.info(this.errorMessage);
                            break;
                    }
                }
                return throwError(() => error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        // if 401 response returned from api - Test si jwt refresh nécessaire
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authenticationService.refresh().pipe(
                switchMap((data) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(sessionStorage.getItem('jwt_token'));
                    request = request.clone({
                        setHeaders: {
                            Authorization: 'Bearer ' + sessionStorage.getItem('jwt_token'),
                        },
                    });
                    return next.handle(request);
                }),
                catchError((error) => {
                    this.isRefreshing = false;
                    this.authenticationService.logout();
                    return throwError(() => error);
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => {
                    request = request.clone({
                        setHeaders: {
                            Authorization: 'Bearer ' + sessionStorage.getItem('jwt_token'),
                        },
                    });
                    return next.handle(request);
                })
            );
        }
    }
}
