// CARACTÉRISTIQUES SOCIO-ÉCONOMIQUES ET DÉMOGRAPHIQUES (ONAPS)
// ============================================================
//

import { Component, Input, Output, forwardRef, OnDestroy, EventEmitter } from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
    NG_VALIDATORS,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Pathologies } from '@/_helpers';

import { AuthenticationService, ToolsService } from '@/_services';

import { User } from '@/_models';

// describes what the return value of the form control will look like
export interface csedFormValues {
    titre: string;
    description: string;
    selector: string;
    type: 0;
    fields: {
        age: number;
        sexe: number;
        prescription: string;
        raison: string;
        raison_detail: string;
        ald1: string;
        ald1_detail: string;
        ald2: string;
        ald2_detail: string;
        ald3: string;
        ald3_detail: string;
        ald4: string;
        ald4_detail: string;
    };
}

@Component({
    selector: 'app-csed',
    templateUrl: './csed.component.html',
    styleUrls: ['./csed.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CsedComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CsedComponent),
            multi: true,
        },
    ],
})
export class CsedComponent implements ControlValueAccessor, OnDestroy {
    @Input() inData: any;
    @Input() inProduction: any;
    @Output() parentFormSubmittedChange = new EventEmitter<boolean>();
    @Input() set parentFormSubmitted(isSubmitted: boolean) {
        if (isSubmitted) {
            this.submitted = true;
            setTimeout(() => {
                this.parentFormSubmittedChange.next(false);
            });
        }
    }

    currentUser: User;
    loading = false;
    submitted = false;
    isAdmin: boolean;
    formID = 0;
    returnUrl: string = '/dashboard/forms';
    fieldsForm: FormGroup;
    subscriptions: Subscription[] = [];
    listePathologies: Array<any> = [];

    ouiPec: string = '0';
    choixRaison: number = -1;
    choixAld1: number = -1;
    choixAld2: number = -1;
    choixAld3: number = -1;
    choixAld4: number = -1;

    numericMask = '0*';

    constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private pathologies: Pathologies,
        private toolsService: ToolsService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);
        // Le nom des champs correspond aux noms ONAPS
        this.fieldsForm = this.formBuilder.group({
            selector: this.formBuilder.control('app-csed'),
            fields: this.formBuilder.group({
                age: this.formBuilder.control('', [Validators.required, this.toolsService.plage(1, 150)]),
                sexe: this.formBuilder.control('', Validators.required),
                prescription: this.formBuilder.control('', Validators.required),
                // pec: this.formBuilder.control('', Validators.required),
                // pec_pc: this.formBuilder.control(''),
                raison: this.formBuilder.control('', Validators.required),
                raison_detail: this.formBuilder.control('', Validators.required),
                ald1: this.formBuilder.control(''),
                ald1_detail: this.formBuilder.control(''),
                ald2: this.formBuilder.control(''),
                ald2_detail: this.formBuilder.control(''),
                ald3: this.formBuilder.control(''),
                ald3_detail: this.formBuilder.control(''),
                ald4: this.formBuilder.control(''),
                ald4_detail: this.formBuilder.control(''),
            }),
        });

        this.subscriptions.push(
            // any time the inner form changes update the parent of any change
            this.fieldsForm.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );

        // On crée la liste des types de pathologies possibles
        this.pathologies.pathologiesOnaps.forEach((o, i) => {
            this.listePathologies.push({
                nom: o.nom,
                cat: o.cat,
            });
        });
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.fieldsForm.controls;
    }

    get value(): csedFormValues {
        return this.fieldsForm.value;
    }

    set value(value: csedFormValues) {
        this.fieldsForm.patchValue(value);
        this.onChange(value);
        this.onTouched();
    }

    // communicate the inner form validation to the parent form
    validate(_: FormControl) {
        return this.fieldsForm.valid ? null : { genericFields: { valid: false } };
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
            this.fieldsForm.patchValue(this.value);
        }

        if (value === null) {
            this.fieldsForm.reset();
        }
    }

    // On initialise le formulaire
    ngAfterContentInit(): void {
        // console.log(this.inData);
        this.writeValue(this.inData.formBind.genericFields);

        if (this.inData.formBind.hasOwnProperty('genericFields')) {
            // // Gestion Pec
            // this.ouiPec = this.inData.formBind.genericFields.fields.pec;
            // if (this.ouiPec == '1') {
            //     this.fieldsForm.get('fields.pec_pc')?.setValidators([Validators.required, plage(0, 100)]);
            // }

            // Gestion Raison / Raison_detail
            this.choixRaison = this.listePathologies.findIndex(
                (x) => x.nom === this.inData.formBind.genericFields.fields.raison
            );

            // Gestion Ald1
            this.choixAld1 = this.listePathologies.findIndex(
                (x) => x.nom === this.inData.formBind.genericFields.fields.ald1
            );

            // Gestion Ald2
            this.choixAld2 = this.listePathologies.findIndex(
                (x) => x.nom === this.inData.formBind.genericFields.fields.ald2
            );

            // Gestion Ald3
            this.choixAld3 = this.listePathologies.findIndex(
                (x) => x.nom === this.inData.formBind.genericFields.fields.ald3
            );

            // Gestion Raison / Raison_detail
            this.choixAld4 = this.listePathologies.findIndex(
                (x) => x.nom === this.inData.formBind.genericFields.fields.ald4
            );
        }

        // console.log(this.fieldsForm);
    }

    onSetYN(event: any, group: string) {
        switch (group) {
            case 'pec':
                this.ouiPec = event.value;
                if (event.value == '1')
                    this.fieldsForm
                        .get('fields.pec_pc')
                        ?.setValidators([Validators.required, this.toolsService.plage(0, 100)]);
                else this.fieldsForm.get('fields.pec_pc')?.setValidators(null);

                this.fieldsForm.get('fields.pec_pc')!.updateValueAndValidity();
                break;
        }
    }

    onRaisonChange(event: any, field: string) {
        // On calcul l'index de la raison
        switch (field) {
            case 'raison':
                this.choixRaison = this.listePathologies.findIndex((x) => x.nom === event.value);
                break;

            case 'ald1':
                this.choixAld1 = this.listePathologies.findIndex((x) => x.nom === event.value);
                break;

            case 'ald2':
                this.choixAld2 = this.listePathologies.findIndex((x) => x.nom === event.value);
                break;

            case 'ald3':
                this.choixAld3 = this.listePathologies.findIndex((x) => x.nom === event.value);
                break;

            case 'ald4':
                this.choixAld4 = this.listePathologies.findIndex((x) => x.nom === event.value);
                break;
        }
    }
}
