import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Activites {
    constructor(private translate: TranslateService) {}
    selectedActivitesValues: string[] = [];
    activites = [
        { value: 'ACTIVITE_ETABLISSEMENT', name: this.translate.instant('ACTIVITE_ETABLISSEMENT') },
        { value: 'ACTIVITE_DOMICILE', name: this.translate.instant('ACTIVITE_DOMICILE') },
        { value: 'ACTIVITE_INDIVIDUEL', name: this.translate.instant('ACTIVITE_INDIVIDUEL') },
        { value: 'ACTIVITE_SALLE', name: this.translate.instant('ACTIVITE_SALLE') },
        { value: 'ACTIVITE_VISIO', name: this.translate.instant('ACTIVITE_VISIO') },
        { value: 'ACTIVITE_EXTERIEUR', name: this.translate.instant('ACTIVITE_EXTERIEUR') },
        { value: 'ACTIVITE_COLLECTIF', name: this.translate.instant('ACTIVITE_COLLECTIF') },
    ];

    listActivitesError: boolean = true;
}
