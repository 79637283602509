import { ISactivitesCompany, ISadministratif, ISimage, ISlicence, ISstructure } from '@/_interfaces';
export class Company {
    id: number = 0;
    licence: ISlicence = { id: 0, params: '', ref: '' };
    name: string = '';
    address: string = '';
    complementAdresse: string = '';
    zip: string = '';
    city: string = '';
    phone_std: string = '';
    siret: string = '';
    tva: string = '';
    description: ISstructure = { territoireIntervention: '', rayonDeplacement: '' };
    administratif: ISadministratif = {
        horaires: '',
        nomAssurance: '',
        numAssurance: '',
        labelsCertif: '',
    };
    activites: ISactivitesCompany = {
        presentationParticipants: '',
        presentationPartenaires: '',
        lieuxActivites: [],
        pratiquantsAcceptes: [],
        typePublic: false,
    };
    logoImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Logo de la structure
    assuranceImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Attestation d'assurance
    reglementImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Règlement intérieur
    rgpdImage: ISimage = { image: '', extension: '', name: '', id: '' }; // RGPD
    statutsImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Statuts
    activiteImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Photo d'activité
    afficheImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Affiche / Flyer
}
