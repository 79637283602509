import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Documents {
    constructor(private translate: TranslateService) {}

    // Différents type de documents
    docs = [
        {
            value: this.translate.instant('constant.DOCU_TYPE_DIVERS'),
            name: this.translate.instant('DOCU_NAME_DIVERS'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_SEANCE'),
            name: this.translate.instant('DOCU_NAME_SEANCE'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_CARTE_PRO'),
            name: this.translate.instant('DOCU_NAME_CARTE_PRO'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_LOGO'),
            name: this.translate.instant('DOCU_NAME_LOGO'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_DIPLOME'),
            name: this.translate.instant('DOCU_NAME_DIPLOME'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_ASSURANCE'),
            name: this.translate.instant('DOCU_NAME_ASSURANCE'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_REGLEMENT'),
            name: this.translate.instant('DOCU_NAME_REGLEMENT'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_ACTIVITE'),
            name: this.translate.instant('DOCU_NAME_ACTIVITE'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_AFFICHE'),
            name: this.translate.instant('DOCU_NAME_AFFICHE'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_MUTUELLE'),
            name: this.translate.instant('DOCU_NAME_MUTUELLE'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_AVATAR'),
            name: this.translate.instant('DOCU_NAME_AVATAR'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_RGPD'),
            name: this.translate.instant('DOCU_NAME_RGPD'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_ALD'),
            name: this.translate.instant('DOCU_NAME_ALD'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_PCH'),
            name: this.translate.instant('DOCU_NAME_PCH'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_APA'),
            name: this.translate.instant('DOCU_NAME_APA'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_CM'),
            name: this.translate.instant('DOCU_NAME_CM'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_PA'),
            name: this.translate.instant('DOCU_NAME_PA'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_AP'),
            name: this.translate.instant('DOCU_NAME_AP'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_LF'),
            name: this.translate.instant('DOCU_NAME_LF'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_AI'),
            name: this.translate.instant('DOCU_NAME_AI'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_STATUTS'),
            name: this.translate.instant('DOCU_NAME_STATUTS'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_FI'),
            name: this.translate.instant('DOCU_NAME_FI'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_TEST'),
            name: this.translate.instant('DOCU_NAME_TEST'),
        },
        {
            value: this.translate.instant('constant.DOCU_TYPE_ONAPS'),
            name: this.translate.instant('DOCU_NAME_ONAPS'),
        },
    ];

    // Différents type de documents
    docs_seances = [
        {
            value: this.translate.instant('constant.DOCU_TYPE_SEANCE'),
            name: this.translate.instant('DOCU_NAME_SEANCE'),
        },
    ];
}
