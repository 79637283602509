import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Lieux } from '@/_helpers';
import { ISlistSeance } from '@/_interfaces';
import { User } from '@/_models';
import { AuthenticationService, SeanceService } from '@/_services';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-calendar-programmation',
    templateUrl: './programmation.component.html',
    styleUrls: ['./programmation.component.scss'],
})
export class ProgrammationComponent implements OnInit {
    loading = true;
    submitted = false;
    isAdmin = false;
    programmationForm: FormGroup;
    listSeances: Array<ISlistSeance> = [];
    currentUser: User;

    constructor(
        public dialogRef: MatDialogRef<ProgrammationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private seanceService: SeanceService,
        private translate: TranslateService,
        private toastr: ToastrService,
        private locations: Lieux
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);

        this.programmationForm = this.formBuilder.group({
            seanceId: this.formBuilder.control('', Validators.required),
            seanceNom: this.formBuilder.control(''),
            date: this.formBuilder.control(0, Validators.required),
            objectifCycle: this.formBuilder.control(''),
            objectifSeance: this.formBuilder.control(''),
            alertes: this.formBuilder.control([]),
            commentaire: this.formBuilder.control(''),
            backgroundColor: this.formBuilder.control(''),
        });
    }

    ngOnInit(): void {
        this.programmationForm.reset();
        this.loadAllSeances();

        this.programmationForm.get('date')?.setValue(formatDate(this.data.date, 'YYYY-MM-ddTHH:mm', 'fr-FR')); // On initialise la date venant du drop dans le calendar
    }

    // convenience getter for easy access to form fields
    get p() {
        return this.programmationForm.controls;
    }

    onSubmit(result: string) {
        this.submitted = true;

        if (result == 'Cancel') {
            this.dialogRef.close(result);
            return;
        }

        // stop here if form is invalid
        if (this.programmationForm.invalid) {
            this.toastr.info(this.translate.instant('error.form.invalid'));
            return;
        }

        // On récupère la séance choisie
        let seance = this.listSeances.filter((row: any) => row.id == this.programmationForm.get('seanceId')!.value);

        // On renvoie son nom complet avec le lieu
        this.programmationForm.get('seanceNom')?.setValue(seance[0].nom + ' - ' + seance[0].lieu);

        // On renvoie son background-color
        this.programmationForm.get('backgroundColor')?.setValue(seance[0].backgroundColor);

        this.dialogRef.close(this.programmationForm.value);
    }

    private loadAllSeances() {
        this.loading = true;
        this.seanceService.getAllByEnseignant(this.data.userID).subscribe({
            next: (data) => {
                this.loading = false;

                // Liste optimisée pour le tri des colonnes pour l'html
                let lieu = { value: -1, name: <any>'' };

                for (let seance of data) {
                    lieu = this.locations.lieux.find((x) => x.value == seance.seance.lieuPratique)!;
                    if (lieu.value == this.translate.instant('constant.SEANCE_LIEU_EXTERNE')) {
                        // On remplace le nom par la structure
                        lieu.name = seance.seance.lieuExterne.organisation;
                    }
                    this.listSeances.push({
                        id: seance.id,
                        nom: seance.seance.activite.nom,
                        lieu: lieu.name,
                        enseignant: seance.seance.enseignant.firstname + ' ' + seance.seance.enseignant.lastname,
                        debut: seance.seance.dateDebut,
                        fin: seance.seance.dateFin,
                        maxParticipants: seance.seance.maxParticipants,
                        inscrits: seance.inscrits + '/' + seance.seance.maxParticipants,
                        typeClient: seance.seance.typeClient,
                        sid: seance.sid,
                        backgroundColor: seance.seance.activite.color,
                    });
                }
            },
            error: (error) => {
                this.loading = false;
            },
        });
    }
}
