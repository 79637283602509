import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@/_models';
import { AuthenticationService } from '@/_services';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-calendar-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
    loading = false;
    submitted = false;
    rdvForm: FormGroup;
    userID: number = 0;
    currentUser: User;

    linksArray = [
        { link: '/dashboard/participants/inscription', label: this.translate.instant('calendar.actions.inscription') },
        { link: '/dashboard/participants/presence', label: this.translate.instant('calendar.actions.presence') },
    ];

    constructor(
        public dialogRef: MatDialogRef<ActionsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private toastr: ToastrService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.userID = this.currentUser.id; // TODO Ajouter un filtre pour avoir les séances d'un enseignant en particulier

        this.rdvForm = this.formBuilder.group({
            objet: this.formBuilder.control('', Validators.required),
            commentaire: this.formBuilder.control(''),
        });
    }

    ngOnInit(): void {}
}
