<div class="row" *ngIf="!inProduction">
    <div class="col-12">
        <h4>{{ 'forms.titre.situation' | translate }}</h4>
    </div>
</div>
<div class="form-group" [formGroup]="fieldsForm">
    <ng-container formGroupName="fields">
        <div class="row mb-3">
            <div class="col-sm-6">
                <label>{{ 'forms.label.qpv' | translate }}</label
                ><br />
                <mat-radio-group formControlName="qpv">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('qpv')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-6">
                <label>{{ 'forms.label.zrr' | translate }}</label
                ><br />
                <mat-radio-group formControlName="zrr">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('zrr')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.heure' | translate }}</mat-label>
                    <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.heure' | translate }}"
                        matPrefix
                        >help</mat-icon
                    >
                    <input
                        placeholder="{{ 'forms.label.heure' | translate }}"
                        matInput
                        formControlName="heures"
                        [mask]="numericMask"
                    />
                    <mat-error *ngIf="submitted && f['fields'].get('heures')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'forms.label.mode' | translate }}</mat-label>
                    <mat-select formControlName="mode">
                        <mat-option *ngFor="let mode of listeModes" [value]="mode.value">
                            {{ mode.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && f['fields'].get('mode')!.hasError('required')"
                        >{{ 'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
</div>
