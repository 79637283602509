<div id="modal-content-wrapper">
    <form [formGroup]="paiementForm" (ngSubmit)="onSubmit('OK')">
        <div class="row">
            <div class="col-md-6">
                <h4 class="bordure mb-5">{{ seance.seance.activite.nom }} - {{ lieu }}</h4>
                <p *ngIf="structure">
                    {{ 'paiement.titre.nbInscrits' | translate }} :
                    <span class="text-primary">{{ seance.inscrits }}</span>
                </p>
                <p>
                    {{ 'paiement.titre.total' | translate }} :
                    <span class="text-primary">{{ prixSeance | currency: 'EUR' }}</span
                    ><span *ngIf="structure && seance.seance.tarifsOrganisation.choix" class="ml-1"
                        >({{ 'seance.label.tarifs.forfaitSeance' | translate }})</span
                    >
                    <span *ngIf="structure && !seance.seance.tarifsOrganisation.choix" class="ml-1"
                        >({{ 'seance.label.tarifs.forfaitParticipant' | translate }})</span
                    >
                </p>
                <p>
                    {{ 'paiement.titre.totalEncours' | translate }} :
                    <span class="text-primary">{{ reglements | currency: 'EUR' }}</span
                    ><br />
                    {{ 'paiement.titre.resteDu' | translate }} :
                    <span class="text-primary">{{ prixSeance - reglements | currency: 'EUR' }}</span
                    ><br />
                </p>
            </div>
            <div class="col-md-6">
                <p class="text-primary">
                    {{ 'paiement.titre.historique' | translate }}
                </p>
                <table>
                    <tr>
                        <th style="width: 50%">{{ 'paiement.label.date' | translate }}</th>
                        <th>{{ 'paiement.label.montant' | translate }}</th>
                    </tr>
                    <tr *ngFor="let reglement of paiementData.historique">
                        <td>{{ reglement.date | date }}</td>
                        <td>{{ reglement.montant | currency: 'EUR' }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row mt-5" *ngIf="isAdmin">
            <div class="col-md-6">
                <p class="text-primary">
                    {{ 'paiement.titre.seance' | translate }}
                </p>
            </div>
        </div>

        <div class="form-group" *ngIf="isAdmin">
            <div class="row">
                <div class="col-md-6 mb-5">
                    <mat-form-field>
                        <mat-label>{{ 'paiement.label.versement' | translate }}</mat-label>
                        <input matInput formControlName="versement" type="number" />
                        <mat-error *ngIf="submitted && p['versement'].hasError && p['versement'].hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <div class="row mt-5 text-center">
                        <div class="col-md-6">
                            <button [disabled]="loading" class="btn btn-primary mb-3">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                {{ 'button.envoyer' | translate }}
                            </button>
                        </div>
                        <div class="col-md-6">
                            <span role="button" class="btn btn-secondary" (click)="onSubmit('Cancel')">{{
                                'button.annuler' | translate
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5" *ngIf="!isAdmin">
            <div class="col-md-6">
                <span role="button" class="btn btn-secondary" (click)="onSubmit('Cancel')">{{
                    'button.back' | translate
                }}</span>
            </div>
        </div>
    </form>
</div>
