import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { nl2brPipe } from './nl2br.pipe';
import { SanitizeHtmlPipe } from './sanitizeHtml.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [nl2brPipe, SanitizeHtmlPipe],

    exports: [nl2brPipe, SanitizeHtmlPipe],
})
export class SharedPipesModule {}
