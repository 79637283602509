<div *ngIf="loading" class="d-flex justify-content-center loader">
    <div class="spinner-border text-danger" role="status" style="width: 3rem; height: 3rem"></div>
</div>
<div *ngIf="!loading" class="container-fluid">
    <div class="col-12">
        <div class="card mb-3">
            <div class="card-body table-responsive">
                <mat-form-field>
                    <span mat-icon-button matSuffix><mat-icon>filter_list</mat-icon></span>
                    <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'filtre' | translate }}" />
                </mat-form-field>
                <mat-accordion>
                    <table
                        mat-table
                        [dataSource]="dataSource"
                        matSort
                        class="mat-elevation-z8"
                        (matSortChange)="sortData($event, 'tabBoardParticipants')"
                    >
                        <!-- lastname Column -->
                        <ng-container matColumnDef="lastname">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Tri par nom">
                                {{ 'user.label.lastname' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-primary">
                                <span role="button">{{ element.lastname | uppercase }}</span>
                            </td>
                        </ng-container>

                        <!-- firstname Column -->
                        <ng-container matColumnDef="firstname">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                                sortActionDescription="Tri par prénom"
                            >
                                {{ 'user.label.firstname' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">{{ element.firstname }}</td>
                        </ng-container>

                        <!-- firstname Column -->
                        <ng-container matColumnDef="cycles">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'board.label.cycles' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container *ngIf="inscriptionsBind[element.id]">
                                    <mat-expansion-panel class="mat-elevation-z0">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title class="text-primary">{{
                                                'board.label.inscriptions' | translate
                                            }}</mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <table>
                                            <tr>
                                                <th>{{ 'board.label.seances' | translate }}</th>
                                                <th>{{ 'board.label.sessions' | translate }}</th>
                                            </tr>
                                            <tr *ngFor="let inscription of inscriptionsBind[element.id]">
                                                <td>{{ inscription.nom }} - {{ inscription.lieu }}</td>
                                                <td>{{ inscription.used }} / {{ inscription.sessions }}</td>
                                            </tr>
                                        </table>
                                    </mat-expansion-panel>
                                </ng-container>
                                <ng-container *ngIf="!inscriptionsBind[element.id]"> -- </ng-container>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </mat-accordion>
                <mat-paginator
                    [pageSizeOptions]="[15, 25, 50, 100]"
                    (page)="onPaginateChange($event, 'pageBoardParticipants')"
                ></mat-paginator>
            </div>
        </div>
    </div>
</div>
