import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Company, Pratiquant } from '@/_models';
@Injectable({
    providedIn: 'root',
})
export class PratiquantService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    profil: User = new User();
    currentUser: User = new User();

    constructor(private http: HttpClient) {}

    // Procédure gestion profils (user + enseignant)
    // ==========================================

    // On télécharge user + pratiquant
    getProfil(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'pratiquant/profil',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    // On télécharge une liste de pratiquants (users) de la company en cours
    getPratiquants() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'pratiquants',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    setJson(id: number, pratiquantData: Pratiquant, field: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'pratiquant/edit/json',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                pratiquantData: JSON.stringify(pratiquantData),
                field: field,
            })
        );
    }

    // On récupère la liste des destinataires à la newsletter
    getDestinatairesNewsletter() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'pratiquant/newsletter',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }
}
