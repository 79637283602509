export const environment = {
    production: false,
    apiUrl: 'https://dev.acti3s.fr/server/public/',
    appKey: 'kid_HyHoT_REf',
    appUrl: 'https://dev.acti3s.fr/',
    authHeader: 'Basic a2lkX0h5SG9UX1JFZjo1MTkxMDJlZWFhMzQ0MzMyODFjN2MyODM3MGQ5OTIzMQ',
    token: '',
    mercureUrl: 'https://gestion.acti3s.fr:4001/.well-known/mercure',
    mercureTopic: 'https://notif-dev.acti3s.fr/',
    secretKey: '9ea689e7-5a41-4534-b1b9-2b3d41b7e6e9',
};
