<div id="modal-content-wrapper">
    <div class="card">
        <div class="card-header d-block">
            <p class="text-primary">
                {{ modalData.title }}
            </p>
        </div>
        <div class="card-body">
            <ng-container *ngIf="modalData.type == 'pdf'">
                <pdf-viewer
                    [src]="modalData.myBlobURL"
                    [render-text]="true"
                    [original-size]="false"
                    style="width: 70vw; height: 70vh"
                ></pdf-viewer>
            </ng-container>
            <ng-container *ngIf="modalData.type == 'csv'">
                <i class="fas fa-4x fa-file-csv white" aria-hidden="true"></i>
            </ng-container>
        </div>
        <div class="card-footer">
            <div class="form-group text-center mt-3">
                <button class="btn btn-primary mr-5" (click)="close('OK')">
                    {{ modalData.actionButtonText }}
                </button>
                <button class="btn btn-secondary" (click)="close('Cancel')">
                    {{ modalData.annuler }}
                </button>
            </div>
        </div>
    </div>
</div>
