import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-showdocs',
    templateUrl: './showdocs.component.html',
    styleUrls: ['./showdocs.component.scss'],
})
export class ShowdocsComponent implements OnInit {
    csv: string = '';

    constructor(public dialogRef: MatDialogRef<ShowdocsComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {}

    ngOnInit() {
        switch (this.modalData.type) {
            case 'csv':
                this.modalData.content.text().then((data: any) => {
                    this.csv = data;
                });
                // Use embedded icon
                break;

            case 'pdf':
                // Use embedded pdf-viewer
                break;
        }
    }

    public close(result: string) {
        this.dialogRef.close(result);
    }
}
