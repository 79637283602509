import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from '@/_models';

@Injectable({
    providedIn: 'root',
})
export class PathologiesService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    currentUser: User = new User();
    constructor(private http: HttpClient) {}

    getPathologies() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'listes/getPathologies',
            JSON.stringify({
                appKey: this.appKey,
                id: this.currentUser.id,
            })
        );
    }

    setPathologies(value: any) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'listes/setPathologies',
            JSON.stringify({
                appKey: this.appKey,
                value: value,
                id: this.currentUser.id,
            })
        );
    }
}
