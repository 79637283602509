import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import { User } from '@/_models';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    currentUser: User | undefined;
    private mercureUrl = environment.mercureUrl;
    private mercureTopic = environment.mercureTopic;

    constructor(private zone: NgZone) {}

    Notifications(): Observable<MessageEvent> {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);

        const url = new URL(this.mercureUrl); // Adresse du serveur MERCURE

        // Inscription au canal de diffusion de la compagnie
        url.searchParams.append('topic', this.mercureTopic + this.currentUser!.company); // Topics publiés par le serveur MERCURE

        return new Observable((observer) => {
            const eventSource = new EventSource(url.toString());
            eventSource.onmessage = (event) => {
                this.zone.run(() => observer.next(event));
            };
        });
    }
}
