import { ISimage, ISparams, ISuserParams } from '@/_interfaces';
export class User {
    id: number = 0;
    created: number = 0;
    firstname: string = '';
    lastname: string = '';
    username: string = '';
    email: string = '';
    password: string = '';
    roles: string[] = [];
    token?: string = '';
    company: string = '';
    companyName: string = '';
    sid: string = '';
    enable: boolean = false;
    avatarImage: ISimage = { image: '', extension: '', name: '', id: '' }; // Avatar du profil
    params: ISuserParams = {
        rights: {
            editProfil: true,
            editSeances: true,
            editTests: true,
            editEpreuves: true,
            inscrire: true,
            seeAllMembers: true,
            allDocs: true,
        },
        fonctions: [
            {
                nom: '',
                descriptif: '',
            },
        ],
    };
}
