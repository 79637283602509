import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService, AuthenticationService, EnseignantService, ToolsService } from '@/_services';

import { User } from '@/_models';

import { Subject } from 'rxjs';

@Component({
    selector: 'app-enseignants',
    templateUrl: './enseignants.component.html',
    styleUrls: ['./enseignants.component.scss'],
})
export class EnseignantsComponent implements OnInit {
    loading = false;
    userID: number = 0;
    currentUser: User;
    isAdmin = false;

    sendData: Subject<{}> = new Subject<{}>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private enseignantService: EnseignantService,
        private toolsService: ToolsService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);
    }

    ngAfterViewInit() {
        this.sendData.next({ userID: this.userID });
    }

    ngOnInit(): void {
        this.userID = Number(this.route.snapshot.paramMap.get('id'));
        if (this.userID == 0) this.userID = this.currentUser.id;
    }
}
