import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Roles {
    constructor(private translate: TranslateService) {}
    selectedRolesValues: string[] = [];

    // rôles que les différents profils peuvent assigner
    admins = [
        { value: 'ROLE_ADMIN', name: this.translate.instant('ROLE_ADMIN') },
        { value: 'ROLE_ENSEIGNANT', name: this.translate.instant('ROLE_ENSEIGNANT') },
        { value: 'ROLE_PRATIQUANT', name: this.translate.instant('ROLE_PRATIQUANT') },
        { value: 'ROLE_PRO', name: this.translate.instant('ROLE_PRO') },
    ];
    enseignants = [
        { value: 'ROLE_PRATIQUANT', name: this.translate.instant('ROLE_PRATIQUANT') },
        { value: 'ROLE_PRO', name: this.translate.instant('ROLE_PRO') },
    ];
    pros = [{ value: 'ROLE_PRATIQUANT', name: this.translate.instant('ROLE_PRATIQUANT') }];

    pratiquants = [{ value: '', name: '' }];

    listRolesError: boolean = false;
}
