import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@/_helpers';

import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { PreviewLayoutComponent } from './preview-layout/preview-layout.component';
import { PresenceComponent } from './print-layout/presence/presence.component';

const routes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('@/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    },

    {
        path: 'login',
        loadChildren: () => import('@/pages/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'company',
        loadChildren: () => import('@/pages/company/company.module').then((m) => m.CompanyModule),
    },

    {
        path: 'printer',
        children: [
            {
                path: 'print',
                outlet: 'print',
                component: PrintLayoutComponent,
                children: [{ path: 'presence/', component: PresenceComponent }],
            },
            {
                path: 'preview',
                outlet: 'preview',
                component: PreviewLayoutComponent,
                children: [{ path: 'presence/', component: PresenceComponent }],
            },
        ],
    },

    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'not-found' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
