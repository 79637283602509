import { IScontactUrgence, ISimage, ISdiplome, ISinfoEnseignant, ISqualifications } from '@/_interfaces';
export class Enseignant {
    id: number = 0;
    complete: boolean = false;
    contactUrgence: IScontactUrgence = { lastname: '', firstname: '', phone: '', email: '', parente: '' };
    infoEnseignant: ISinfoEnseignant = {
        carteProNumber: '',
        carteProValidite: 0,
        certificatMedicalValidite: 0,
    };
    parcours: ISqualifications = {
        psc1: false,
        presentation: '',
        diplomes: Array<ISdiplome>(),
    };

    carteProImage: ISimage = { image: '', extension: '', name: '', id: '' };
    certificatMedicalImage: ISimage = { image: '', extension: '', name: '', id: '' };
    diplomeImage: ISimage = { image: '', extension: '', name: '', id: '' };
}
