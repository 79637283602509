import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User, Structure } from '@/_models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class StructureService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    currentUser = new User();
    constructor(private http: HttpClient) {}

    getAll() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'structures',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    edit(structure: Structure, id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'structure/edit',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                structure: structure,
                id: id,
            })
        );
    }

    // get structure by id
    getStructure(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'structure',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                id: id,
            })
        );
    }

    // get structure by owner ID
    getStructureUser(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'structure/user',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                id: id,
            })
        );
    }

    // get all admin members of one structure
    getMembers(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'structure/members',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                id: id,
            })
        );
    }
}
