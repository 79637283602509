<div id="content-wrapper">
    <div id="previewMe">
        <table style="width: 100%" id="headerTable">
            <tr>
                <td style="width: 30%">
                    <strong>{{ 'seance.participants.titre.objectifCycle' | translate }} : </strong>
                </td>
                <td style="width: 70%">
                    {{ programmationBind.objectifCycle }}
                </td>
            </tr>
            <tr>
                <td class="cell-top">
                    <strong>{{ 'seance.participants.titre.objectifSeance' | translate }} : </strong>
                </td>
                <td class="cell-top">
                    {{ programmationBind.objectifSeance }}
                </td>
            </tr>
            <tr>
                <td class="cell-top">
                    <strong>{{ 'seance.label.enseignant' | translate }} : </strong>
                </td>
                <td class="cell-top">
                    {{ seanceBind.enseignant.firstname }}&nbsp;{{ seanceBind.enseignant.lastname }}
                </td>
            </tr>
            <tr>
                <td class="cell-top">
                    <strong>{{ 'seance.label.nbInscrits' | translate }} : </strong>
                </td>
                <td class="cell-top">
                    {{ nbInscrits }}
                </td>
            </tr>
            <tr>
                <td class="cell-top">
                    <strong>{{ 'seance.participants.titre.retourSeance' | translate }} : </strong>
                </td>
                <td class="cell-top">
                    {{ programmationBind.commentaire }}
                </td>
            </tr>
        </table>
        <table id="participantTable" style="width: 100%; border-top: 1px solid; border-bottom: 1px solid">
            <!-- Entête du tableau -->
            <thead>
                <tr>
                    <th style="width: 50%; border: 1px solid; text-align: center">
                        {{ 'presence.preview.nom' | translate }}
                    </th>
                    <th style="width: 15%; border: 1px solid; text-align: center">
                        {{ 'presence.preview.presence' | translate }}
                    </th>
                    <th style="width: 15%; border: 1px solid; text-align: center">
                        {{ 'presence.preview.commentaire' | translate }}
                    </th>
                    <th style="width: 20%; border: 1px solid; text-align: center">
                        {{ 'presence.preview.administratif' | translate }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- Lignes des participants -->
                <tr *ngFor="let present of presencesBind; let i = index">
                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 10px; vertical-align: top">
                        <strong>{{ present.nom }}</strong>
                    </td>
                    <td style="border-right: 1px solid; padding: 10px; text-align: center; vertical-align: top">
                        {{ presences.presences[present.presence] }}
                    </td>
                    <td style="border-right: 1px solid; padding: 10px; text-align: center; vertical-align: top">
                        {{ present.commentaire }}
                    </td>
                    <td style="border-right: 1px solid; padding: 10px; text-align: center; vertical-align: top">
                        <p *ngIf="adminBind[present.uid] != undefined && adminBind[present.uid].total > 0">
                            {{ 'presence.preview.solde' | translate : { solde: adminBind[present.uid].total } }}
                        </p>

                        <p *ngIf="adminBind[present.uid] != undefined && !adminBind[present.uid].complete">
                            {{ 'presence.preview.noComplete' | translate }}
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
