<div class="row mb-3" *ngIf="!inProduction">
    <div class="col-12">
        <h4>{{ 'forms.titre.scores' | translate }}</h4>
    </div>
</div>
<div class="form-group" [formGroup]="fieldsForm">
    <ng-container formGroupName="fields">
        <div class="row mb-3">
            <div class="col-sm-6">
                <mat-form-field>
                    <!-- <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.nap_min_score' | translate }}"
                        matPrefix
                        >help</mat-icon
                    > -->
                    <mat-label>{{ 'forms.label.nap_min_score' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.nap_min_score' | translate }}"
                        matInput
                        formControlName="nap_min_score"
                        [readonly]="true"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <!-- <span class="text-warning">{{ 'forms.consigne.nap_min_score' | translate }} </span> -->
                        <span
                            class="text-danger mt-2"
                            *ngIf="submitted && f['fields'].get('nap_min_score')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <!-- <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.nap_mets_score' | translate }}"
                        matPrefix
                        >help</mat-icon
                    > -->
                    <mat-label>{{ 'forms.label.nap_mets_score' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.nap_mets_score' | translate }}"
                        matInput
                        formControlName="nap_mets_score"
                        [readonly]="true"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <!-- <span class="text-warning">{{ 'forms.consigne.nap_mets_score' | translate }} </span> -->
                        <span
                            class="text-danger mt-2"
                            *ngIf="submitted && f['fields'].get('nap_mets_score')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-6">
                <mat-form-field>
                    <!-- <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.sed_score' | translate }}"
                        matPrefix
                        >help</mat-icon
                    > -->
                    <mat-label>{{ 'forms.label.sed_score' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.sed_score' | translate }}"
                        matInput
                        formControlName="sed_score"
                        [readonly]="true"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <!-- <span class="text-warning">{{ 'forms.consigne.sed_score' | translate }} </span> -->
                        <span
                            class="text-danger mt-2"
                            *ngIf="submitted && f['fields'].get('sed_score')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <!-- <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.ps_score' | translate }}"
                        matPrefix
                        >help</mat-icon
                    > -->
                    <mat-label>{{ 'forms.label.ps_score' | translate }}</mat-label>
                    <input
                        placeholder="{{ 'forms.label.ps_score' | translate }}"
                        matInput
                        formControlName="ps_score"
                        [readonly]="true"
                    />
                    <mat-hint style="display: flex; flex-direction: column">
                        <!-- <span class="text-warning">{{ 'forms.consigne.ps_score' | translate }} </span> -->
                        <span
                            class="text-danger mt-2"
                            *ngIf="submitted && f['fields'].get('ps_score')!.hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </span>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
</div>
