import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { EnseignantsComponent } from '@/pages/enseignants/enseignants.component';
import { CalendarSharedModule } from '@/_shared';

// export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    imports: [CommonModule, TranslateModule, CalendarSharedModule],

    declarations: [EnseignantsComponent],

    exports: [EnseignantsComponent],
})
export class EnseignantsSharedModule {}
