import { Component, Input, Output, forwardRef, OnDestroy, EventEmitter } from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
    NG_VALIDATORS,
} from '@angular/forms';

import { Subscription } from 'rxjs';

import { AuthenticationService, ToolsService } from '@/_services';

import { User } from '@/_models';

// describes what the return value of the form control will look like
export interface aatFormValues {
    titre: string;
    description: string;
    selector: string;
    type: 0;
    fields: {
        q0: string;
        q1_fort: string;
        q1_modere: string;
        q2_fort: string;
        q2_modere: string;
        q3_fort: string;
        q3_modere: string;
        q4: string;
    };
}

@Component({
    selector: 'app-aat',
    templateUrl: './aat.component.html',
    styleUrls: ['./aat.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AatComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AatComponent),
            multi: true,
        },
    ],
})
export class AatComponent implements ControlValueAccessor, OnDestroy {
    @Input() inData: any;
    @Input() inProduction: any;
    @Output() parentFormSubmittedChange = new EventEmitter<boolean>();
    @Input() set parentFormSubmitted(isSubmitted: boolean) {
        if (isSubmitted) {
            this.submitted = true;
            setTimeout(() => {
                this.parentFormSubmittedChange.next(false);
            });
        }
    }

    currentUser: User;
    loading = false;
    submitted = false;
    isAdmin: boolean;
    formID = 0;
    returnUrl: string = '/dashboard/forms';
    fieldsForm: FormGroup;
    subscriptions: Subscription[] = [];
    listeModes: Array<any> = [];
    ouiq0: string = '0';
    ouiq1_fort: string = '0';
    ouiq1_modere: string = '0';

    numericMask = '0*';
    heureMask = 'Hh:m0';

    constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private toolsService: ToolsService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);
        // Le nom des champs correspond aux noms ONAPS
        this.fieldsForm = this.formBuilder.group({
            selector: this.formBuilder.control('app-aat'),
            fields: this.formBuilder.group({
                q0: this.formBuilder.control('', Validators.required),
                q1_fort: this.formBuilder.control(''),
                q1_modere: this.formBuilder.control(''),
                q2_fort: this.formBuilder.control(''),
                q2_modere: this.formBuilder.control(''),
                q3_fort: this.formBuilder.control(''),
                q3_modere: this.formBuilder.control(''),
                q4: this.formBuilder.control(''),
            }),
        });

        this.subscriptions.push(
            // any time the inner form changes update the parent of any change
            this.fieldsForm.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.fieldsForm.controls;
    }

    get value(): aatFormValues {
        return this.fieldsForm.value;
    }

    set value(value: aatFormValues) {
        this.fieldsForm.patchValue(value);
        this.onChange(value);
        this.onTouched();
    }

    // communicate the inner form validation to the parent form
    validate(_: FormControl) {
        return this.fieldsForm.valid ? null : { genericFields: { valid: false } };
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
            this.fieldsForm.patchValue(this.value);
        }

        if (value === null) {
            this.fieldsForm.reset();
        }
    }

    // On initialise le formulaire
    ngAfterContentInit(): void {
        // console.log(this.inData);

        this.writeValue(this.inData.formBind.genericFields);

        // Conversion en heures:minutes des minutes
        this.fieldsForm
            .get('fields.q3_fort')!
            .setValue(this.toolsService.convertMinutes(this.fieldsForm.get('fields.q3_fort')!.value));

        this.fieldsForm
            .get('fields.q3_modere')!
            .setValue(this.toolsService.convertMinutes(this.fieldsForm.get('fields.q3_modere')!.value));

        this.fieldsForm
            .get('fields.q4')!
            .setValue(this.toolsService.convertMinutes(this.fieldsForm.get('fields.q4')!.value));

        if (this.inData.formBind.hasOwnProperty('genericFields')) {
            // Gestion q0
            this.ouiq0 = this.inData.formBind.genericFields.fields.q0;
            if (this.ouiq0 == '1') {
                this.fieldsForm.get('fields.q1_fort')?.setValidators(Validators.required);
                this.fieldsForm.get('fields.q1_modere')?.setValidators(Validators.required);
                this.fieldsForm.get('fields.q4')?.setValidators(Validators.required);
                this.fieldsForm.get('fields.q1_fort')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q1_modere')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q4')!.updateValueAndValidity();
            }

            // Gestion q1_fort
            this.ouiq1_fort = this.inData.formBind.genericFields.fields.q1_fort;
            if (this.ouiq1_fort == '1') {
                this.fieldsForm
                    .get('fields.q2_fort')
                    ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                this.fieldsForm.get('fields.q3_fort')?.setValidators(Validators.required);
                this.fieldsForm.get('fields.q2_fort')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q3_fort')!.updateValueAndValidity();
            }

            // Gestion q1_modere
            this.ouiq1_modere = this.inData.formBind.genericFields.fields.q1_modere;
            if (this.ouiq1_modere == '1') {
                this.fieldsForm
                    .get('fields.q2_modere')
                    ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                this.fieldsForm.get('fields.q3_modere')?.setValidators(Validators.required);
                this.fieldsForm.get('fields.q2_modere')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q3_modere')!.updateValueAndValidity();
            }
        }
    }

    onSetYN(event: any, field: string) {
        switch (field) {
            case 'q0':
                this.ouiq0 = event.value;
                if (event.value == '1') {
                    this.fieldsForm.get('fields.q1_fort')?.setValidators(Validators.required);
                    this.fieldsForm.get('fields.q1_modere')?.setValidators(Validators.required);
                    this.fieldsForm.get('fields.q4')?.setValidators(Validators.required);
                } else {
                    this.fieldsForm.get('fields.q1_fort')?.setValidators(null);
                    this.fieldsForm.get('fields.q1_fort')?.setValue(null);
                    this.fieldsForm.get('fields.q1_modere')?.setValidators(null);
                    this.fieldsForm.get('fields.q1_modere')?.setValue(null);
                    this.fieldsForm.get('fields.q4')?.setValidators(null);
                    this.fieldsForm.get('fields.q4')?.setValue(null);
                }

                this.fieldsForm.get('fields.q1_fort')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q1_modere')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q4')!.updateValueAndValidity();
                break;
            case 'q1_fort':
                this.ouiq1_fort = event.value;
                if (event.value == '1') {
                    this.fieldsForm
                        .get('fields.q2_fort')
                        ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                    this.fieldsForm.get('fields.q3_fort')?.setValidators(Validators.required);
                } else {
                    this.fieldsForm.get('fields.q2_fort')?.setValidators(null);
                    this.fieldsForm.get('fields.q2_fort')?.setValue(null);
                    this.fieldsForm.get('fields.q3_fort')?.setValidators(null);
                    this.fieldsForm.get('fields.q3_fort')?.setValue(null);
                }

                this.fieldsForm.get('fields.q2_fort')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q3_fort')!.updateValueAndValidity();
                break;
            case 'q1_modere':
                this.ouiq1_modere = event.value;
                if (event.value == '1') {
                    this.fieldsForm
                        .get('fields.q2_modere')
                        ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                    this.fieldsForm.get('fields.q3_modere')?.setValidators(Validators.required);
                } else {
                    this.fieldsForm.get('fields.q2_modere')?.setValidators(null);
                    this.fieldsForm.get('fields.q2_modere')?.setValue(null);
                    this.fieldsForm.get('fields.q3_modere')?.setValidators(null);
                    this.fieldsForm.get('fields.q3_modere')?.setValue(null);
                }

                this.fieldsForm.get('fields.q2_modere')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q3_modere')!.updateValueAndValidity();
                break;
        }
    }
}
