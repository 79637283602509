import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';

const PRIVATEKEY = environment.secretKey;
const production = environment.production;

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor() {}

    // Permet de crypter le sessionstorage automatiquement en overridant le storage original
    init() {
        if (production) {
            let _setItem = Storage.prototype.setItem.bind(sessionStorage);
            let _getItem = Storage.prototype.getItem.bind(sessionStorage);

            Storage.prototype.setItem = function (key, value) {
                _setItem(key, CryptoJS.AES.encrypt(value, PRIVATEKEY).toString());
            };

            Storage.prototype.getItem = function (key) {
                let value = _getItem(key);
                if (value) {
                    return CryptoJS.AES.decrypt(value, PRIVATEKEY).toString(CryptoJS.enc.Utf8);
                } else {
                    return null;
                }
            };
        }
    }
}
