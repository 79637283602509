import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup, FormArray, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

import { User } from '@/_models';
import { environment } from '../../environments/environment';

import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import * as FileSaver from 'file-saver';

const SECONDS_IN_DAY = 24 * 60 * 60;
const MISSING_LEAP_YEAR_DAY = SECONDS_IN_DAY;
const MAGIC_NUMBER_OF_DAYS = 25567 + 2;

@Injectable({ providedIn: 'root' })
export class ToolsService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    navSidebarClass: boolean = true;
    hamburgerClass: boolean = false;
    currentUser!: User;
    isPrinting = false;

    constructor(private http: HttpClient, private router: Router) {}

    /* NavSideBar Toogle*/

    toggleSidebarClass() {
        return (this.navSidebarClass = !this.navSidebarClass);
    }
    toggleHamburgerClass() {
        return (this.hamburgerClass = !this.hamburgerClass);
    }

    // Gestion de la validation d'une plage de valeur dans un formulaire
    // =================================================================
    plage(min: number, max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value < min || value > max) {
                return { plage: true };
            } else return null;
        };
    }

    // Conversion HHmm to minutes
    // ============================

    convertHeuresMinutes(heureMinute: string) {
        if (heureMinute == '' || heureMinute == null) return '';

        if (heureMinute.length < 4) heureMinute += '0';

        // On prend les heures
        let heures = parseInt(heureMinute.substring(0, 2));
        let minutes = parseInt(heureMinute.substring(2));

        return heures * 60 + minutes + '';
    }

    // Conversion minutes to HHmm
    // ============================

    convertMinutes(temps: string) {
        // On prend les heures
        if (temps == '' || temps == null) return '';

        let heures = Math.trunc(parseInt(temps) / 60);
        let minutes = parseInt(temps) - heures * 60;

        let h = heures.toString();
        let m = minutes.toString();

        if (h.length < 2) h = '0' + h;
        if (m.length < 2) m = '0' + m;

        return h + m;
    }

    // Gestion des champs obligatoires dans un formulaire
    // ==================================================
    isRequiredField(form: FormGroup, field: string, override: boolean = false) {
        const form_field = form.get(field);

        if ((form_field && !form_field.validator) || override) {
            if (override) {
                // Champs invisibles à la création devenant obligatoires à l'édition (required est ajouté aux autre validator présent dans le champ)
                if (form_field && form_field.validator)
                    form_field.setValidators([Validators.required, form_field.validator]);
                else form_field!.setValidators([Validators.required]);

                form_field!.updateValueAndValidity();
                return true;
            } else return false;
        }

        const validator = form_field!.validator ? form_field!.validator({} as AbstractControl) : '';
        return validator && validator['required'];
    }

    /* Reload current page */
    /* =================== */
    reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
        });
    }

    // Send Email
    // ==========

    sendMail(destinataire: number, expediteur: number, subject: string, body: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'user/sendmail',
            JSON.stringify({
                appKey: this.appKey,
                destinataire: destinataire,
                expediteur: expediteur,
                subject: subject,
                body: body,
                company: this.currentUser!.company,
            })
        );
    }

    // Download des datas dans un fichier CSV
    // ======================================

    /**
     * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
     *
     * @param rows array of data to be converted to CSV.
     * **/

    exportServertoCsv(rows: any, separator: string) {
        if (!rows || !rows.length) return;

        const keys = Object.keys(rows[0]);
        const csvContent =
            keys.join(separator) +
            '\n' +
            rows
                .map((row: { [x: string]: any }) => {
                    return keys
                        .map((k) => {
                            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                            cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
                            if (cell.search(/("|,|\n)/g) >= 0) {
                                cell = `"${cell}"`;
                            }
                            return cell;
                        })
                        .join(separator);
                })
                .join('\n');

        const data: Blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        FileSaver.saveAs(data, 'liste_newsletter.csv');

        return true;
    }

    /* renvoi un tableau avec les champs de formulaire non valides */
    findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
        var invalidControls: string[] = [];
        let recursiveFunc = (form: FormGroup | FormArray) => {
            Object.keys(form.controls).forEach((field) => {
                const control = form.get(field);
                if (control!.invalid) invalidControls.push(field);
                if (control instanceof FormGroup) {
                    recursiveFunc(control);
                } else if (control instanceof FormArray) {
                    recursiveFunc(control);
                }
            });
        };
        recursiveFunc(formToInvestigate);
        return invalidControls;
    }

    /* convertit un timestamp en date */
    timestampToDate(value: number): Date {
        if (value > 0) return new Date(value);
        else return new Date();
    }

    /* Convertit une date excel en timestamp javascript */
    getJsDateFromExcel(excelDate: number) {
        // const delta = excelDate - MAGIC_NUMBER_OF_DAYS;
        // const parsed = delta * MISSING_LEAP_YEAR_DAY;
        //return new Date(parsed).getTime();
        if (excelDate) return new Date(Date.UTC(0, 0, excelDate - 1)).getTime() / 1000;
        else return new Date().getTime() / 1000;
    }

    /* Convertit une date javascript en number excel */
    getExcelDateFromJs(date: Date) {
        return date.getTime() / MISSING_LEAP_YEAR_DAY + MAGIC_NUMBER_OF_DAYS;
    }

    // Print file
    // ============
    //
    // On reçoit un fichier PDF prêt à être enregistré ou imprimé
    printRemote(id: number, type: any) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<ArrayBuffer>(
            this.apiUrl + 'documents/print',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                type: type,
                company: this.currentUser.company,
            }),
            {
                observe: 'response',
                responseType: 'blob' as 'json',
            }
        );
    }

    // On reçoit un ordre d'impression en local
    //
    // documentName : le nom du controller dans la partie "print-layout"
    // documentData : data à passer dans l'url (optionnel)
    //
    // onDataPrintReady - gère le pop-up d'impression

    printDocument(documentName: string, documentData: string) {
        this.isPrinting = true;
        this.router.navigate(
            [
                'printer',
                {
                    outlets: {
                        print: ['print', documentName, documentData],
                    },
                },
            ],
            { skipLocationChange: true }
        );
    }

    onDataPrintReady() {
        setTimeout(() => {
            window.print();
            this.isPrinting = false;
            window.history.go(0); // On revient sur la page d'appel de l'impression
        });
    }

    // On reçoit un ordre de preview en local
    //
    // documentName : le nom du controller dans la partie "print-layout"
    // documentData : data à passer dans l'url (optionnel)
    //
    // onDataPreviewReady - gère la fenêtre de preview

    previewDocument(documentName: string, documentData: string) {
        this.isPrinting = true;
        this.router.navigate(
            [
                'printer',
                {
                    outlets: {
                        preview: ['preview', documentName, documentData],
                    },
                },
            ],
            { skipLocationChange: true }
        );
    }

    onDataPreviewReady() {
        setTimeout(() => {
            this.isPrinting = false;
        });
    }

    // Mémorisation des filtres des colonnes de tableau
    //
    // tabNames :
    //
    // tabSeances : Pages -> seances
    // tabBoardSeances : _components -> board-seances
    // tabBoardParticipants : _components -> board-seances
    // tabDocuments : Pages -> Documents
    // tabMembres : Pages -> Users
    // tabInscriptions : Pages -> Inscriptions
    // tabInscriptionsSeances : Pages -> Inscriptions
    // tabProgrammations : Pages -> seances -> programmations
    // tabPresences : Pages -> seances -> participants
    // tabBoardEnseignants : _components -> board-agendas
    // tabOnaps : Pages -> Onaps
    // tabEfforts : Pages -> Onaps

    tabUpdate(tabName: string, sort: MatSort, dataSource: any, paginator: MatPaginator, initial: any = null) {
        if ('tab' + tabName in sessionStorage) {
            sort.sort({
                id: JSON.parse(sessionStorage.getItem('tab' + tabName)!).active,
                start: JSON.parse(sessionStorage.getItem('tab' + tabName)!).direction,
                disableClear: true,
            } as MatSortable);
            if ('page' + tabName in sessionStorage)
                paginator.pageSize = JSON.parse(sessionStorage.getItem('page' + tabName)!).pageSize;
        } else {
            if (initial) {
                sort.sort({
                    id: initial.active,
                    start: initial.direction,
                    disableClear: true,
                } as MatSortable);
            }
        }
        sort.disableClear = true;
        dataSource.sort = sort;
        dataSource.paginator = paginator;
    }
}
