export class Structure {
    id: number = 0;
    categorie: number = 0;
    name: string = '';
    address: string = '';
    complementAdresse: string = '';
    zip: string = '';
    city: string = '';
    phone_std: string = '';
    siret: string = '';
    tva: string = '';
    complete: boolean = false;
}
