import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CalendarParams {
    constructor(private translate: TranslateService) {}

    // Jours de la semaine à afficher dans le calendrier (BusinessHours)
    selectedDaysOfWeek: number[] = [];
    daysOfWeek = [
        { value: 0, name: this.translate.instant('sunday') },
        { value: 1, name: this.translate.instant('monday') },
        { value: 2, name: this.translate.instant('tuesday') },
        { value: 3, name: this.translate.instant('wednesday') },
        { value: 4, name: this.translate.instant('thursday') },
        { value: 5, name: this.translate.instant('friday') },
        { value: 6, name: this.translate.instant('saturday') },
    ];
}
