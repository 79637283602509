import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { User, Seance } from '@/_models';
import { ToolsService, SeanceService, AuthenticationService, ReglementService } from '@/_services';
import { ISfullSeance, ISlistSeance, ISprogrammation } from '@/_interfaces';
import { Lieux } from '@/_helpers';

import { formatDate } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import { first } from 'rxjs/operators';

/* Modal Dialog */
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PaiementSeanceComponent } from '@/_components';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector: 'app-board-seances',
    templateUrl: './board-seances.component.html',
    styleUrls: ['./board-seances.component.scss'],
})
export class BoardSeancesComponent implements OnInit {
    @Input() inData: any;

    currentUser: User;
    loading = false;
    listSeances: Array<ISlistSeance> = [];
    seances: ISfullSeance[] = [];
    isAdmin: boolean;
    isPro: boolean;
    seanceID = 0;
    returnUrl: string = '';
    showProgrammation = false;

    programmationForm!: FormGroup;
    programmationBind: Array<ISprogrammation> = [];

    // Trie des colonnes de tableau séances
    displayedColumns: string[] = ['nom', 'lieu', 'debut', 'fin', 'enseignant', 'inscrits', 'paiements'];
    dataSource = new MatTableDataSource(this.listSeances);

    // Trie des colonnes de tableau programmations
    displayedProgrammationsColumns: string[] = ['groupe', 'date', 'objectifCycle', 'objectifSeance', 'commentaire'];
    dataProgrammationsSource!: MatTableDataSource<any>;

    constructor(
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private seanceService: SeanceService,
        private reglementService: ReglementService,
        private toolsService: ToolsService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        public matDialog: MatDialog,
        private locations: Lieux
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);
        this.isPro = this.authenticationService.isPro(this.currentUser);

        this.programmationForm = this.formBuilder.group({
            programmations: this.formBuilder.array([]),
        });
    }

    @ViewChild('paginator') paginator!: MatPaginator;
    @ViewChild('paginatorProgrammations') paginatorProgrammations!: MatPaginator;
    @ViewChild('sort') sort = new MatSort();
    @ViewChild('sortProgrammations') sortProgrammations = new MatSort();

    ngOnInit(): void {
        // On set l'url de retour au board
        if (this.isAdmin) this.returnUrl = '/dashboard/company';
        if (this.isPro) this.returnUrl = '/dashboard/pros';

        // Load All séances
        this.loading = true;
        this.seanceService.getAll().subscribe({
            next: (data) => {
                this.loading = false;
                setTimeout(() => {
                    this.toolsService.tabUpdate('BoardSeances', this.sort, this.dataSource, this.paginator, {
                        active: 'debut',
                        direction: 'asc',
                    });
                });
                this.seances = data;
                // Liste optimisée pour le tri des colonnes pour l'html
                let lieu = { value: -1, name: <any>'' };
                for (let seance of data) {
                    lieu = this.locations.lieux.find((x) => x.value == seance.seance.lieuPratique)!;
                    if (lieu.value == 1) {
                        // On remplace le nom par la structure
                        lieu.name = seance.seance.lieuExterne.organisation;
                    }
                    // Pour les pros on ne liste que les séances qui leurs appartiennent
                    if (this.isPro && seance.sid != this.currentUser.sid) {
                        continue;
                    }

                    // Correction artefact (SUM dans la base de données)
                    // =================================================
                    if (seance.inscrits == null) seance.inscrits = 0;

                    this.listSeances.push({
                        id: seance.id,
                        nom: seance.seance.activite.nom,
                        lieu: lieu.name,
                        enseignant: seance.seance.enseignant.firstname + ' ' + seance.seance.enseignant.lastname,
                        debut: seance.seance.dateDebut,
                        fin: seance.seance.dateFin,
                        maxParticipants: seance.seance.maxParticipants,
                        inscrits: seance.inscrits,
                        typeClient: seance.seance.typeClient,
                        sid: seance.sid,
                        backgroundColor: seance.seance.activite.color,
                    });
                }
            },
            error: () => {
                this.loading = false;
            },
        });
    }

    getPaiements(seance_id: number) {
        let seance = this.seances.find((x) => x.id == seance_id);
        if (seance!.sid) {
            // ***************************************** //
            // Validation du règlement pour une structure //
            // ***************************************** //

            let uid = seance!.seance.contactOrganisation.structure; // On passe la structure ID comme uid
            let prixSeance = seance!.seance.tarifsOrganisation.choix
                ? seance!.seance.tarifsOrganisation.prixForfaitSeance
                : Number(seance!.seance.tarifsOrganisation.prixForfaitParticipant) * seance!.inscrits; // On va chercher le prix

            this.reglementService.getReglements(seance_id, uid, seance!.seance.typeClient).subscribe({
                next: (data) => {
                    this.loading = false;
                    if (data !== null && Object.keys(data).length !== 0) {
                        let reglementsBind = data;

                        // On accepte le paiement
                        const dialogConfig = new MatDialogConfig();
                        this.breakpointObserver
                            .observe([Breakpoints.Small, Breakpoints.Large, Breakpoints.HandsetPortrait])
                            .pipe(first())
                            .subscribe((state: BreakpointState) => {
                                if (this.isAdmin) {
                                    dialogConfig.height = '80vh';
                                    dialogConfig.width = '80vw';
                                } else {
                                    dialogConfig.height = '80vh';
                                    dialogConfig.width = '40vw';
                                }

                                // The user can't close the dialog by clicking outside its body
                                dialogConfig.disableClose = true;
                                dialogConfig.id = 'paiement';
                                dialogConfig.maxWidth = '100vw';
                                dialogConfig.data = {
                                    historique: reglementsBind.historique,
                                    structure: true,
                                    prixSeance: prixSeance,
                                    seance: seance!.seance,
                                    inscrits: seance?.inscrits,
                                };
                                this.matDialog
                                    .open(PaiementSeanceComponent, dialogConfig)
                                    .afterClosed()
                                    .subscribe((paiement) => {
                                        if (paiement != 'Cancel') {
                                            this.loading = true;
                                            // On met à jour l'historique des versements
                                            let historique = {
                                                date: new Date().getTime(),
                                                montant: parseFloat(paiement.versement),
                                            };
                                            reglementsBind.historique.push(historique);

                                            // On met à jour l'état des versements pour la structure en cours

                                            let reglements = 0;
                                            for (let reglement of reglementsBind.historique) {
                                                reglements += reglement.montant;
                                            }

                                            // // On sauvegarde le tout en base de donnée
                                            this.reglementService.setReglement(reglementsBind).subscribe({
                                                next: () => {
                                                    this.loading = false;
                                                    this.toastr.success(
                                                        this.translate.instant('seance.success.historique')
                                                    );
                                                },
                                                error: () => {
                                                    this.loading = false;
                                                },
                                            });
                                        }
                                    });
                            });
                    }
                },
                error: () => {
                    this.loading = false;
                },
            });
        } else {
            // On redirige vers les fiches de présence pour valider les règlements personnels
            // ==============================================================================

            if (!this.isPro)
                this.router.navigate(['dashboard/seances/edit/' + seance_id], {
                    queryParams: { returnUrl: this.returnUrl, index: 1 },
                });
            else
                this.router.navigate(['dashboard/seances/edit/' + seance_id], {
                    queryParams: { returnUrl: this.returnUrl, index: 0 },
                });
        }
    }

    applyFilter(filterValue: any) {
        this.dataSource.filter = filterValue.target!.value.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    applyFilterProgrammations(filterValue: any) {
        this.dataProgrammationsSource.filter = filterValue.target!.value.trim().toLowerCase();

        if (this.dataProgrammationsSource.paginator) {
            this.dataProgrammationsSource.paginator.firstPage();
        }
    }

    getSeance(seanceId: number) {
        this.router.navigate(['dashboard/seances/edit/' + seanceId], {
            queryParams: { returnUrl: this.returnUrl, index: 0 },
        });
    }

    loadProgrammations(seance_id: number) {
        // On charge la programmation de la séance
        this.loading = true;
        this.seanceID = seance_id;

        // On clear le tableau de programmations
        this.programmationForm = this.formBuilder.group({
            programmations: this.formBuilder.array([]),
        });

        this.seanceService.getProgrammation(this.seanceID).subscribe({
            next: (data: any) => {
                this.loading = false;
                this.showProgrammation = true;
                if (data.programmation !== null && Object.entries(data.programmation).length !== 0) {
                    this.programmationBind = <any>data.programmation;

                    // On crée la liste pour les programmations
                    for (let val of this.programmationBind) {
                        let fields = this.newProgrammation();
                        fields.setValue({
                            id: val.id,
                            date: formatDate(val.date, 'YYYY-MM-ddTHH:mm', 'fr-FR'),
                            objectifCycle: val.objectifCycle,
                            objectifSeance: val.objectifSeance,
                            alertes: val.alertes,
                            commentaire: val.commentaire,
                        });

                        this.programmations().push(fields);
                    }
                    this.dataProgrammationsSource = new MatTableDataSource(
                        (this.programmationForm.get('programmations') as FormArray).controls
                    );
                } else {
                    this.addProgrammation();
                }

                this.dataProgrammationsSource.sortingDataAccessor = (data: AbstractControl, sortHeaderId: string) => {
                    const value: any = data.value[sortHeaderId];
                    return typeof value === 'string' ? value.toLowerCase() : value;
                };
                const filterPredicate = this.dataSource.filterPredicate;
                this.dataProgrammationsSource.filterPredicate = (data: AbstractControl, filter) => {
                    return filterPredicate.call(this.dataSource, data.value, filter);
                };
                setTimeout(() => {
                    this.toolsService.tabUpdate(
                        'BoardProgrammations',
                        this.sortProgrammations,
                        this.dataProgrammationsSource,
                        this.paginatorProgrammations
                    );
                });
            },
        });
    }

    programmations(): FormArray {
        return this.programmationForm.get('programmations') as FormArray;
    }

    addProgrammation() {
        this.programmations().push(this.newProgrammation());
        this.programmationBind.push({
            id: uuidv4(),
            date: 0,
            dateFin: 0,
            objectifCycle: '',
            objectifSeance: '',
            alertes: [],
            commentaire: '',
            info: '',
            annulation: false,
        });
        this.dataProgrammationsSource = new MatTableDataSource(
            (this.programmationForm.get('programmations') as FormArray).controls
        );
    }

    newProgrammation(): FormGroup {
        return this.formBuilder.group({
            id: this.formBuilder.control(''),
            date: this.formBuilder.control('', Validators.required),
            objectifCycle: this.formBuilder.control(''),
            objectifSeance: this.formBuilder.control(''),
            alertes: this.formBuilder.control([]),
            commentaire: this.formBuilder.control(''),
        });
    }

    goGroupe(programmationId: number) {
        this.router.navigateByUrl('/dashboard/seances', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/dashboard/seances/edit/' + this.seanceID], {
                queryParams: { programmation: programmationId, returnUrl: this.returnUrl, index: 2 },
            });
        });
    }

    back() {
        this.dataSource = new MatTableDataSource(this.listSeances);
        setTimeout(() => {
            this.toolsService.tabUpdate('BoardSeances', this.sort, this.dataSource, this.paginator);
        });
        this.showProgrammation = false;
    }

    sortData($event: any, tabName: string) {
        // On mémorise les filtres
        sessionStorage.setItem(tabName, JSON.stringify($event));
    }

    onPaginateChange($event: any, tabName: string) {
        sessionStorage.setItem(tabName, JSON.stringify($event));
    }
}
