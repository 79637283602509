<div *ngIf="loading" class="d-flex justify-content-center loader">
    <div class="spinner-border text-danger" role="status" style="width: 3rem; height: 3rem"></div>
</div>
<div *ngIf="!loading" [ngClass]="!isAdmin ? 'content-body' : ''">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4>{{ 'calendar.titre.accessAgenda' | translate }}</h4>
            </div>
        </div>
        <app-calendar [inData]="sendData.asObservable()"></app-calendar>
    </div>
</div>
