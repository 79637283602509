import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import { Company, User, Document } from '@/_models';
import { PromptComponent, ShowdocsComponent, LoadingComponent, ModalComponent } from '@/_components';

import { ISimage } from '@/_interfaces';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    currentUser: User = new User();

    constructor(private http: HttpClient, private translate: TranslateService, public matDialog: MatDialog) {}

    // Permet de visualiser tout type de documents dans une modale
    // ===========================================================
    showDoc(id: string, title: string) {
        this.getDetailsFile(id).subscribe({
            next: (details: any) => {
                const extension = details.filename.split('.');
                const dialogConfig = new MatDialogConfig();
                dialogConfig.panelClass = 'loading-doc';
                dialogConfig.height = '100vh';
                dialogConfig.width = '100vw';
                this.matDialog.open(LoadingComponent, dialogConfig);
                switch (extension[1]) {
                    case 'png':
                    case 'jpg':
                    case 'jpeg':
                        // Visualisation de l'image dans une modale
                        this.getImage(id).subscribe({
                            next: (image) => {
                                const dialogConfig = new MatDialogConfig();
                                dialogConfig.disableClose = false;
                                dialogConfig.id = 'modal-component';
                                dialogConfig.data = {
                                    title: this.translate.instant(title),
                                    image: image,
                                    actionButtonText: this.translate.instant('button.download'),
                                    annuler: this.translate.instant('button.annuler'),
                                };
                                this.matDialog.closeAll();
                                this.matDialog
                                    .open(PromptComponent, dialogConfig)
                                    .afterClosed()
                                    .subscribe((result) => {
                                        if (result == 'OK') {
                                            this.saveImage(image);
                                        }
                                    });
                            },
                            error: () => {
                                this.matDialog.closeAll();
                            },
                        });
                        break;

                    case 'csv':
                        // On lance le csv viewer
                        this.download(id).subscribe({
                            next: (data) => {
                                const contentDisposition = data.headers.get('content-disposition')!;
                                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                var matches = filenameRegex.exec(contentDisposition);
                                if (matches != null && matches[1]) {
                                    const filename = matches[1].replace(/['"]/g, '');
                                }
                                let blob = new Blob([data.body!], {
                                    type: data.headers.get('Content-Type')!,
                                });
                                //this.myBlobURL = this.documentService.getPdf(data);
                                const dialogConfig = new MatDialogConfig();
                                dialogConfig.disableClose = false;
                                dialogConfig.id = 'modal-component';
                                dialogConfig.data = {
                                    type: 'csv',
                                    title: this.translate.instant(title),
                                    content: blob,
                                    actionButtonText: this.translate.instant('button.download'),
                                    annuler: this.translate.instant('button.annuler'),
                                };
                                this.matDialog.closeAll();
                                this.matDialog
                                    .open(ShowdocsComponent, dialogConfig)
                                    .afterClosed()
                                    .subscribe((result) => {
                                        if (result == 'OK') {
                                            this.downLoadFile(data);
                                        }
                                    });
                            },
                            error: (error) => {
                                this.matDialog.closeAll();
                                this.errorShowDoc();
                            },
                        });

                        break;

                    case 'pdf':
                        // On lance le pdf viewer
                        this.download(id).subscribe({
                            next: (data) => {
                                const contentDisposition = data.headers.get('content-disposition')!;
                                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                var matches = filenameRegex.exec(contentDisposition);
                                if (matches != null && matches[1]) {
                                    const filename = matches[1].replace(/['"]/g, '');
                                }
                                let blob = new Blob([data.body!], {
                                    type: data.headers.get('Content-Type')!,
                                });
                                let myBlobURL = URL.createObjectURL(blob);
                                //this.myBlobURL = this.documentService.getPdf(data);
                                const dialogConfig = new MatDialogConfig();
                                dialogConfig.disableClose = false;
                                dialogConfig.id = 'modal-component';
                                dialogConfig.data = {
                                    type: 'pdf',
                                    title: this.translate.instant(title),
                                    myBlobURL: myBlobURL,
                                    actionButtonText: this.translate.instant('button.download'),
                                    annuler: this.translate.instant('button.annuler'),
                                };
                                this.matDialog.closeAll();
                                this.matDialog
                                    .open(ShowdocsComponent, dialogConfig)
                                    .afterClosed()
                                    .subscribe((result) => {
                                        if (result == 'OK') {
                                            this.downLoadFile(data);
                                        }
                                    });
                            },
                            error: (error) => {
                                this.matDialog.closeAll();
                                this.errorShowDoc();
                            },
                        });

                        break;
                }
            },
            error: () => {
                this.matDialog.closeAll();
                this.errorShowDoc();
            },
        });
    }

    // En cas d'erreur de chargement
    errorShowDoc() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.id = 'modal-component';
        dialogConfig.height = '50vh';
        dialogConfig.width = '50vw';
        dialogConfig.data = {
            title: this.translate.instant('document.titre.error'),
            description: this.translate.instant('document.label.error'),
        };
        this.matDialog.closeAll();
        this.matDialog.open(ModalComponent, dialogConfig);
    }

    // Upload files
    // ============
    //
    // File - Fichier choisi par utilisateur
    // Id - id owner
    // Fid - File ID ou Null ou -1
    // Type - Type de fichier
    // Seance - Séance à laquelle le document est attaché (optionnel)

    upload(file: File, id: number, fid: string, type: string, seance: string = '0'): Observable<HttpEvent<any>> {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        const formData: FormData = new FormData();

        formData.append('file', file);
        formData.append('appKey', this.appKey);
        formData.append('company', this.currentUser!.company!);
        formData.append('id', id + '');
        formData.append('fid', fid);
        formData.append('type', type);
        formData.append('seance', seance);

        const req = new HttpRequest('POST', this.apiUrl + 'documents/upload', formData, {
            reportProgress: true,
            responseType: 'json',
        });

        return this.http.request(req);
    }

    // Update Avatar
    // =============
    updateAvatar(id: string) {
        let left = document.getElementsByClassName('main-profile');
        let top = document.getElementsByClassName('header-profile');
        left = left[0].getElementsByTagName('img');
        top = top[0].getElementsByTagName('img');
        this.getImage(id).subscribe({
            next: (avatar) => {
                left[0].setAttribute('src', avatar.image);
                top[0].setAttribute('src', avatar.image);
            },
            error: () => {},
        });
    }

    // Download files
    // ==============
    //
    // id - Id du fichier ou Null
    // type - Type du fichier (associé à la company) ou Null

    download(id: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<ArrayBuffer>(
            this.apiUrl + 'documents/download',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser!.company,
            }),
            {
                observe: 'response',
                responseType: 'blob' as 'json',
            }
        );
    }

    // Get extension file
    // ==================
    //
    // id - Id du fichier

    getDetailsFile(id: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'documents/getdetails',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser!.company,
            })
        );
    }

    // Téléchargement de fichiers
    // ==========================

    downLoadFile(data: any) {
        const contentDisposition = data.headers.get('content-disposition');
        const filename = this.getFilenameFromContentDisposition(contentDisposition);
        let blob = new Blob([data.body], {
            type: data.headers.get('Content-Type'),
        });
        let url = window.URL.createObjectURL(blob);

        // Permet de télécharger le fichier avec son nom
        // On crée un lien temporaire et on simule un click dessus pour ouvrir un pop-up de téléchargement
        let element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        // let pwa = window.open(url);
        // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        //     alert( 'Please disable your Pop-up blocker and try again.');
        // }
    }

    // Téléchargement / sauvegarde d'une image au format base64
    // ========================================================

    getImage(id: string = '', type: string = '') {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<ISimage>(
            this.apiUrl + 'documents/get/image',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                type: type,
                company: this.currentUser!.company,
            })
        );
    }

    saveImage(image: ISimage) {
        const filename = image.name;

        // On converti Base64Data en image Blob
        // ====================================
        let base64Data = '';

        switch (image['extension'].toLowerCase()) {
            case 'jpg':
            case 'jpeg':
                base64Data = image.image.replace(/^data:image\/jpeg;base64,/, '');
                image['extension'] = 'image/jpeg';
                break;

            case 'png':
                base64Data = image.image.replace(/^data:image\/png;base64,/, '');
                image['extension'] = 'image/png';
                break;
        }

        const byteString: string = atob(base64Data);
        const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }

        let blob = new Blob([int8Array], {
            type: image['extension'],
        });
        let url = window.URL.createObjectURL(blob);

        // Permet de télécharger le fichier avec son nom
        // On crée un lien temporaire et on simule un click dessus

        let element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    private getFilenameFromContentDisposition(contentDisposition: string): string {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }

        return '';
    }

    // Delete file
    // ============
    deleteFile(id: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<ArrayBuffer>(
            this.apiUrl + 'documents/delete',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser!.company,
            })
        );
    }

    // Load tous les documents d'une structure
    getAll() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'documents/getFiles',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    // Mise à jour des documents partagés
    saveShared(id: string, selectedRows: []) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'documents/saveshared',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                id: id,
                selectedRows: selectedRows,
            })
        );
    }

    toggleAccess(id: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'document/togglePublic',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
            })
        );
    }
}
