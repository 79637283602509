import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LanguageTranslationModule } from '@/_shared';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToolsService, StorageService } from '@/_services';
import {
    JwtInterceptor,
    ErrorInterceptor,
    AuthGuard,
    Roles,
    Activites,
    PratiquantsAcceptes,
    CalendarParams,
    Documents,
    Structures,
    Lieux,
    Presences,
    Mesures,
    Pathologies,
} from '@/_helpers';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { MatPaginatorI18nService } from '@/_services';

import { MatDialogModule } from '@angular/material/dialog';

// Gestion de la langue
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { PreviewLayoutComponent } from './preview-layout/preview-layout.component';
import { PresenceComponent } from './print-layout/presence/presence.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
    declarations: [AppComponent, PresenceComponent, PreviewLayoutComponent, PrintLayoutComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxSpinnerModule,
        MatDialogModule,
        BrowserAnimationsModule,
        LanguageTranslationModule,
        ToastrModule.forRoot(),
    ],

    providers: [
        ToolsService,
        Roles,
        Structures,
        Documents,
        Activites,
        PratiquantsAcceptes,
        CalendarParams,
        AuthGuard,
        StorageService,
        Lieux,
        Presences,
        Mesures,
        Pathologies,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
        { provide: MAT_DATE_LOCALE, useValue: 'fr' },
        {
            provide: APP_INITIALIZER,
            useFactory: (ds: StorageService) => () => ds.init(),
            deps: [StorageService],
            multi: true,
        },
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
