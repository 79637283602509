import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from '@/_models';

@Injectable({
    providedIn: 'root',
})
export class ListesService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    currentUser: User = new User();
    constructor(private http: HttpClient) {}

    getListe(liste: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'listes/getListe',
            JSON.stringify({
                appKey: this.appKey,
                id: this.currentUser.id,
                liste: liste,
            })
        );
    }

    setListe(value: any, liste: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'listes/setListe',
            JSON.stringify({
                appKey: this.appKey,
                value: value,
                id: this.currentUser.id,
                liste: liste,
            })
        );
    }
}
