import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { MaterialModule } from '@/_shared';

import { ModalComponent } from '@/_components';

@NgModule({
    declarations: [ModalComponent],
    imports: [CommonModule, MatDialogModule, MaterialModule],
})
export class ModalModule {}
