<div id="modal-content-wrapper">
    <form [formGroup]="rdvForm" (ngSubmit)="onSubmit('OK')">
        <div class="row">
            <div class="col-md-12">
                <h4>
                    {{ 'calendar.rdv.titre' | translate }}
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>{{ 'calendar.rdv.label.objet' | translate }}</mat-label>
                    <input
                        matInput
                        formControlName="objet"
                        placeholder="{{ 'calendar.rdv.label.objet' | translate }}"
                    />
                    <mat-error *ngIf="submitted && r['objet'].hasError && r['objet'].hasError('required')">{{
                        'error.obligatoire' | translate
                    }}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>{{ 'calendar.rdv.label.commentaire' | translate }}</mat-label>
                    <textarea
                        matInput
                        formControlName="commentaire"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="10"
                    ></textarea>
                    <mat-error
                        *ngIf="submitted && r['commentaire'].hasError && r['commentaire'].hasError('required')"
                        >{{ 'error.obligatoire' | translate }}</mat-error
                    >
                </mat-form-field>
            </div>
        </div>
        <div class="form-group text-center mt-5">
            <button [disabled]="loading" class="btn btn-primary mr-5">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{ 'button.envoyer' | translate }}
            </button>
            <span role="button" class="btn btn-secondary" (click)="onSubmit('Cancel')">{{
                'button.annuler' | translate
            }}</span>
        </div>
    </form>
</div>
