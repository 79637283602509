import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@/_shared';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarComponent, ProgrammationComponent, RdvComponent, ActionsComponent } from '@/_components';
//import { FlexLayoutModule } from '@angular/flex-layout';

FullCalendarModule.registerPlugins([
    // register FullCalendar plugins
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin,
]);

@NgModule({
    declarations: [CalendarComponent, ProgrammationComponent, RdvComponent, ActionsComponent],
    imports: [
        CommonModule,
        FullCalendarModule,
        TranslateModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        //FlexLayoutModule,
    ],
    exports: [CalendarComponent],
})
export class CalendarSharedModule {}
