import { ISinscription } from '@/_interfaces';

export class Inscription {
    id: number = 0;
    enable: boolean = false;
    seance: number = 0;
    user: number = 0;
    inscription: ISinscription = {
        details: {
            debut: 0,
            fin: 0,
            modalite: 0,
            nbSeances: 0,
            cout: 0,
            paye: 0,
        },
        objectifsPratiquant: [],
    };
}
