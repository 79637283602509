import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User, Suivi } from '@/_models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SuiviService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    currentUser = new User();
    constructor(private http: HttpClient) {}

    edit(suivi: Suivi, user_id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'suivi/edit',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                uid: user_id,
                suivi: suivi,
            })
        );
    }

    getSuivi(user_id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'suivi',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                uid: user_id,
            })
        );
    }
}
