<div class="row" *ngIf="!inProduction">
    <div class="col-12">
        <h4>{{ 'forms.titre.ma' | translate }}</h4>
    </div>
</div>
<div class="form-group" [formGroup]="fieldsForm">
    <div class="row" formGroupName="fields">
        <div class="col-sm-4 mb-3">
            <mat-form-field>
                <mat-label>{{ 'forms.label.poids' | translate }}</mat-label>
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.poids' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <input
                    placeholder="{{ 'forms.label.poids' | translate }}"
                    matInput
                    formControlName="poids"
                    [mask]="numericMask"
                />
                <mat-error *ngIf="submitted && f['fields'].get('poids')!.hasError('required')"
                    >{{ 'error.obligatoire' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-4 mb-3">
            <mat-form-field>
                <mat-label>{{ 'forms.label.taille' | translate }}</mat-label>
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.taille' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <input
                    placeholder="{{ 'forms.label.taille' | translate }}"
                    matInput
                    formControlName="taille"
                    [mask]="numericMask"
                />
                <mat-error *ngIf="submitted && f['fields'].get('taille')!.hasError('required')"
                    >{{ 'error.obligatoire' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-4 mb-3">
            <mat-form-field>
                <mat-label>{{ 'forms.label.perimetre' | translate }}</mat-label>
                <mat-icon
                    matTooltipClass="custom-tooltip"
                    matToolTipPosition="above"
                    matTooltip="{{ 'forms.help.perimetre' | translate }}"
                    matPrefix
                    >help</mat-icon
                >
                <input
                    placeholder="{{ 'forms.label.perimetre' | translate }}"
                    matInput
                    formControlName="perimetre"
                    [mask]="numericMask"
                />
                <mat-error *ngIf="submitted && f['fields'].get('perimetre')!.hasError('required')"
                    >{{ 'error.obligatoire' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
