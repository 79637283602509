import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Company, Enseignant } from '@/_models';
import { ISepreuve } from '@/_interfaces';

@Injectable({
    providedIn: 'root',
})
export class EpreuveService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    currentUser: User = new User();

    constructor(private http: HttpClient) {}

    setEpreuve(id: number, epreuve: ISepreuve, action: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'epreuve/edit',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
                epreuve: epreuve,
                action: action,
            })
        );
    }

    getAll() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'epreuve/epreuves',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    getEpreuve(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'epreuve/epreuve',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    getEpreuveTests(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'epreuve/epreuveandtests',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    delete(ids: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'epreuve/delete',
            JSON.stringify({
                appKey: this.appKey,
                ids: ids,
                company: this.currentUser.company,
            })
        );
    }

    copy(ids: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'epreuve/copy',
            JSON.stringify({
                appKey: this.appKey,
                ids: ids,
                company: this.currentUser.company,
            })
        );
    }
}
