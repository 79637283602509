import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@/_shared';
import { TranslateModule } from '@ngx-translate/core';

import { PaiementSeanceComponent } from '@/_components';

@NgModule({
    declarations: [PaiementSeanceComponent],
    imports: [CommonModule, MatDialogModule, MaterialModule, TranslateModule, FormsModule, ReactiveFormsModule],
})
export class PaiementModule {}
