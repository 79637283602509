import { User, Seance, Structure } from '@/_models';
export class Document {
    id: number = 0;
    user!: User;
    company: string = '';
    fileName: string = '';
    name: string = '';
    doc_type: number = 0;
    file_type: number = 0;
    created: number = 0;
    published: boolean = false;
    access: boolean = false;
    seance!: {
        id: number;
        seance: Seance;
    };
    shared: Array<number> = [];
}
