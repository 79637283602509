import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { User } from '@/_models';
import { AuthenticationService } from '@/_services';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-calendar-rdv',
    templateUrl: './rdv.component.html',
    styleUrls: ['./rdv.component.scss'],
})
export class RdvComponent implements OnInit {
    loading = false;
    submitted = false;
    rdvForm: FormGroup;
    userID: number = 0;
    currentUser: User;

    constructor(
        public dialogRef: MatDialogRef<RdvComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private toastr: ToastrService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.userID = this.currentUser.id; // TODO Ajouter un filtre pour avoir les séances d'un enseignant en particulier

        this.rdvForm = this.formBuilder.group({
            objet: this.formBuilder.control('', Validators.required),
            commentaire: this.formBuilder.control(''),
        });
    }

    ngOnInit(): void {
        this.rdvForm.reset();
        switch (this.data.action) {
            case 'create':
                // rien de particulier
                break;
            case 'edit':
                // on renseigne les éléments du rendez-vous
                this.rdvForm.get('objet')?.setValue(this.data.objet);
                this.rdvForm.get('commentaire')?.setValue(this.data.commentaire);
                break;
        }
    }

    // convenience getter for easy access to form fields
    get r() {
        return this.rdvForm.controls;
    }

    onSubmit(result: string) {
        this.submitted = true;

        if (result == 'Cancel') {
            this.dialogRef.close(result);
            return;
        }

        // stop here if form is invalid
        if (this.rdvForm.invalid) {
            this.toastr.info(this.translate.instant('error.form.invalid'));
            return;
        }

        this.dialogRef.close(this.rdvForm.value);
    }
}
