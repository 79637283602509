import { Component, OnInit, ViewChild, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { Calendar } from '@fullcalendar/core'; // Load lib before other calendar files
import {
    CalendarOptions,
    DateSelectArg,
    EventClickArg,
    EventApi,
    FullCalendarComponent,
    CalendarApi,
} from '@fullcalendar/angular';
import { Draggable } from '@fullcalendar/interaction';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';

import { CalendarService, ToolsService, SeanceService, UserService, AuthenticationService } from '@/_services';
import { CalendarParams, Lieux } from '@/_helpers';
import { ISbussinessHours, ISpresence, ISprogrammation, ISseance } from '@/_interfaces';
import { User, Seance } from '@/_models';

import frLocale from '@fullcalendar/core/locales/fr';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

/* Modal Dialog */
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProgrammationComponent, RdvComponent } from '@/_components';
import { v4 as uuidv4 } from 'uuid';

import { Observable } from 'rxjs';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
    @Input() inData!: Observable<{}>;

    @ViewChild('external') external!: ElementRef;
    @ViewChild('fullcalendar') calendarComponent!: FullCalendarComponent;

    programmationBind: Array<ISprogrammation> = [];
    presenceBind: Array<ISpresence> = [];
    programmation: any;

    seanceBind!: Seance;

    currentEvents: EventApi[] = [];
    calendarVisible = false;
    calendarOptions: CalendarOptions = {};
    calendarApi!: CalendarApi;
    eventIndex = 0;
    currentUser: User;
    isAdmin: boolean = false;
    IsPratiquant: boolean = false;
    IsEnseignant: boolean = false;
    userID = 0;
    seanceID = 0;
    ownerAgenda = '';

    loading = true;
    initializing = true; // Calendar invisible

    paramsForm!: FormGroup;

    workSpec: ISbussinessHours = { daysOfWeek: [0], startTime: '', endTime: '' };
    workSpecs: Array<ISbussinessHours> = [];
    showDays: Array<ISbussinessHours> = [];
    workMin: string = '';
    workMax: string = '';
    workDays: Array<any> = [];
    hideDays: Array<any> = [];

    private inParam: any;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private calendarService: CalendarService,
        private translate: TranslateService,
        private toastr: ToastrService,
        public calendarParams: CalendarParams,
        private formBuilder: FormBuilder,
        private toolsService: ToolsService,
        private userService: UserService,
        private router: Router,
        private seanceService: SeanceService,
        private authenticationService: AuthenticationService,
        private breakpointObserver: BreakpointObserver,
        public matDialog: MatDialog,
        private locations: Lieux
    ) {
        const name = Calendar.name;
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);
        this.IsPratiquant = this.authenticationService.isPratiquant(this.currentUser);
        this.IsEnseignant = this.authenticationService.isEnseignant(this.currentUser);
    }

    ngOnDestroy(): void {
        this.inParam.unsubscribe();
        this.loading = false;
    }

    ngOnInit() {
        this.inParam = this.inData.subscribe({
            next: (response: any) => {
                this.userID = response.userID;
                this.calendarService
                    .getParams(this.userID, this.translate.instant('constant.CALENDAR_TYPE_PERSONAL'))
                    .subscribe({
                        next: (parametres: any) => {
                            // On initialise les parametres du calendrier
                            parametres['businessHours'].forEach((val: any, key: any) => {
                                this.workSpec = {
                                    daysOfWeek: [val.daysOfWeek[0]],
                                    startTime: val.startTime,
                                    endTime: val.endTime,
                                };
                                this.workSpecs.push(this.workSpec);
                                this.showDays[val.daysOfWeek[0]] = this.workSpec; // On arrange les index à partir de daysOfWeek pour le html
                            });

                            // On calcule les différentes plages en tenant compte des businessHours
                            this.workMin = this.workSpecs
                                .map((item) => item.startTime)
                                .sort()
                                .shift()!;
                            this.workMax = this.workSpecs
                                .map((item) => item.endTime)
                                .sort()
                                .pop()!;
                            this.workDays = [...new Set(this.workSpecs.flatMap((item) => item.daysOfWeek))];
                            this.hideDays = [...Array(7).keys()].filter((day) => !this.workDays.includes(day));

                            // On initialise les formulaires de la colonne de gauche
                            this.paramsForm = this.formBuilder.group({
                                birthday: this.formBuilder.control(0, Validators.required),
                                daysOfWeek: this.addDaysOfWeekControls(),
                            });

                            // On initialise le calendrier
                            this.initCalendar();
                        },
                        error: () => {
                            this.loading = false;
                        },
                    });
            },
            error: () => {
                this.loading = false;
            },
        });
    }

    // Initialisation des jours de la semaine
    // ======================================
    addDaysOfWeekControls() {
        const arr = this.calendarParams.daysOfWeek.map((element, i) => {
            if (this.hideDays.includes(i)) return this.formBuilder.control(false);
            else return this.formBuilder.control(true);
        });
        return this.formBuilder.array(arr);
    }

    // A chaque fois que l'on change l'état des checkbox des jours à visualiser
    getSelectedDaysOfWeekValue(e: any) {
        const index = e.split('-');
        const horaire = document.getElementById(e) as HTMLInputElement;
        let row = 0;
        switch (index[0]) {
            case 'daysOfWeek':
                const box = document.getElementById(e + '-input') as HTMLInputElement;
                this.workSpecs.forEach((val: any, key: any) => {
                    if (this.workSpecs[key].daysOfWeek.indexOf(parseInt(index[1])) > -1) row = key;
                });
                if (!box.checked) {
                    this.calendarParams.selectedDaysOfWeek.push(this.calendarParams.daysOfWeek[index[1]].value);
                    this.workSpecs.splice(row, 1); // On supprime l'enregistrement dans businessHours
                } else {
                    this.calendarParams.selectedDaysOfWeek.splice(
                        this.calendarParams.selectedDaysOfWeek.indexOf(this.calendarParams.daysOfWeek[index[1]].value),
                        1
                    );
                    // On ajoute un enregistrement standard dans businessHours
                    this.workSpecs.splice(row, 0, {
                        daysOfWeek: [parseInt(index[1])],
                        startTime: '08:00',
                        endTime: '18:00',
                    });
                }
                this.calendarRender();
                break;

            case 'startTime':
                this.workSpecs.forEach((val: any, key: any) => {
                    if (this.workSpecs[key].daysOfWeek.indexOf(parseInt(index[1])) > -1) row = key;
                });
                this.workSpecs[row].startTime = horaire.value;
                this.calendarRender();
                break;

            case 'endTime':
                this.workSpecs.forEach((val: any, key: any) => {
                    if (this.workSpecs[key].daysOfWeek.indexOf(parseInt(index[1])) > -1) row = key;
                });
                this.workSpecs[row].endTime = horaire.value;
                this.calendarRender();
                break;
        }
    }

    get daysOfWeekArray() {
        return <FormArray>this.paramsForm.get('daysOfWeek');
    }

    handleDragStop(info: any) {
        let elementMouseIsOver = document.elementFromPoint(info.jsEvent.clientX, info.jsEvent.clientY);

        if (elementMouseIsOver?.getAttribute('id') == 'delete-zone') {
            // On drop au dessus de la zone de suppression

            switch (info.event.extendedProps.type) {
                case 'SEANCE':
                    if (!this.IsPratiquant && (this.isAdmin || this.currentUser.params.rights.editSeances)) {
                        this.loading = true;
                        info.event.remove();
                        this.calendarService
                            .setEvents(
                                this.currentEvents,
                                this.userID,
                                this.translate.instant('constant.CALENDAR_TYPE_PERSONAL')
                            )
                            .subscribe({
                                next: () => {
                                    let id = info.event.id.split('_');

                                    // On met à jour les programmations
                                    this.seanceService.getProgrammation(id[1]).subscribe({
                                        next: (data: any) => {
                                            this.programmationBind = data.programmation.filter(
                                                (x: ISprogrammation) => x.id != id[2]
                                            );
                                            this.seanceService
                                                .editProgrammation(id[1], this.programmationBind)
                                                .subscribe({
                                                    next: () => {
                                                        // On met à jour les fiches de présences
                                                        this.seanceService.getPresence(id[1]).subscribe({
                                                            next: (data: any) => {
                                                                this.presenceBind = [];
                                                                if (
                                                                    data !== null &&
                                                                    Object.keys(data.participants).length !== 0
                                                                ) {
                                                                    data.participants.forEach((o: any, i: any) => {
                                                                        if (
                                                                            this.programmationBind.find(
                                                                                (x: any) => x.id == o.id
                                                                            )
                                                                        )
                                                                            this.presenceBind.push(o);
                                                                    });
                                                                }
                                                                this.seanceService
                                                                    .editPresence(id[1], this.presenceBind)
                                                                    .subscribe({
                                                                        next: () => {
                                                                            this.loading = false;
                                                                            this.toastr.success(
                                                                                this.translate.instant(
                                                                                    'calendar.success.maj'
                                                                                )
                                                                            );
                                                                        },
                                                                        error: () => {
                                                                            this.loading = false;
                                                                        },
                                                                    });
                                                            },
                                                            error: () => {
                                                                this.loading = false;
                                                            },
                                                        });
                                                    },
                                                    error: () => {
                                                        this.loading = false;
                                                    },
                                                });
                                        },
                                        error: () => {
                                            this.loading = false;
                                        },
                                    });
                                },

                                error: () => {
                                    this.loading = false;
                                },
                            });
                    } else {
                        this.loading = false;
                        this.toastr.warning(this.translate.instant('calendar.error.noRights'));
                    }
                    break;
                case 'RDV':
                    this.loading = true;
                    info.event.remove();
                    this.calendarService
                        .setEvents(
                            this.currentEvents,
                            this.userID,
                            this.translate.instant('constant.CALENDAR_TYPE_PERSONAL')
                        )
                        .subscribe({
                            next: () => {
                                this.loading = false;
                                this.toastr.success(this.translate.instant('calendar.success.maj'));
                            },
                            error: () => {
                                this.loading = false;
                            },
                        });
                    break;
            }
        }
    }

    handleEventDrop(info: any) {
        // Un évènement est déplacé dans le calendrier
        if (!this.IsPratiquant || info.event.extendedProps.type == 'RDV') {
            switch (info.event.extendedProps.type) {
                case 'SEANCE':
                    if (!this.IsPratiquant && (this.isAdmin || this.currentUser.params.rights.editSeances)) {
                        this.loading = true;
                        this.calendarService
                            .setEvents(
                                this.currentEvents,
                                this.userID,
                                this.translate.instant('constant.CALENDAR_TYPE_PERSONAL')
                            )
                            .subscribe({
                                next: () => {
                                    let id = info.event.id.split('_');
                                    this.seanceService.getProgrammation(id[1]).subscribe({
                                        next: (data: any) => {
                                            this.programmationBind = data.programmation;
                                            let index = 0;
                                            for (let [key, val] of Object.entries(this.programmationBind)) {
                                                if (val.id == id[2]) {
                                                    this.programmationBind[index].date = new Date(
                                                        info.event.start
                                                    ).getTime();
                                                    break;
                                                }
                                                index++;
                                            }
                                            this.seanceService
                                                .editProgrammation(id[1], this.programmationBind)
                                                .subscribe({
                                                    next: () => {
                                                        this.loading = false;
                                                        this.toastr.success(
                                                            this.translate.instant('calendar.success.maj')
                                                        );
                                                    },
                                                    error: () => {
                                                        this.loading = false;
                                                    },
                                                });
                                        },
                                        error: () => {
                                            this.loading = false;
                                        },
                                    });
                                },
                                error: () => {
                                    this.loading = false;
                                },
                            });
                    } else {
                        this.loading = false;
                        info.revert();
                        this.toastr.warning(this.translate.instant('calendar.error.noRights'));
                    }
                    break;
                case 'RDV':
                    this.loading = true;
                    this.calendarService
                        .setEvents(
                            this.currentEvents,
                            this.userID,
                            this.translate.instant('constant.CALENDAR_TYPE_PERSONAL')
                        )
                        .subscribe({
                            next: () => {
                                this.loading = false;
                                this.toastr.success(this.translate.instant('calendar.success.maj'));
                            },
                            error: () => {
                                this.loading = false;
                            },
                        });
                    break;
            }
        } else {
            this.loading = false;
            info.revert();
            this.toastr.warning(this.translate.instant('calendar.error.noRights'));
        }
    }

    handleEventReceive(info: any) {
        // Un évènement draggué de l'extérieur, on récupère les éléments caractéristiques en ligne
        let props = JSON.parse(info.draggedEl.getAttribute('data-type')!);
        Object.keys(props.extendedProps).forEach((key) => {
            info.event.setExtendedProp(key, props.extendedProps[key]); // On récupère toutes les propriétés étendues
        });

        let dialogConfig = new MatDialogConfig();
        switch (info.event.extendedProps.type) {
            case 'SEANCE':
                if (!this.IsPratiquant && (this.isAdmin || this.currentUser.params.rights.editSeances)) {
                    // On ouvre un pop-up pour valider la séance supplémentaire
                    this.breakpointObserver
                        .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
                        .pipe(first())
                        .subscribe((state: BreakpointState) => {
                            if (state.matches) {
                                dialogConfig.height = '100vh';
                                dialogConfig.width = '100vw';
                            } else {
                                dialogConfig.height = '';
                                dialogConfig.width = 'calc(50%)';
                            }

                            // The user can't close the dialog by clicking outside its body
                            dialogConfig.disableClose = true;
                            dialogConfig.id = 'programmation-component';
                            dialogConfig.maxWidth = '100vw';
                            dialogConfig.data = {
                                date: new Date(info.event.start).getTime(),
                                userID: this.userID,
                            };
                            this.matDialog
                                .open(ProgrammationComponent, dialogConfig)
                                .afterClosed()
                                .subscribe((programmation) => {
                                    if (programmation != 'Cancel') {
                                        this.programmation = programmation;
                                        this.loading = true;
                                        // On récupère les programmations de la séance choisie
                                        this.seanceService.getProgrammation(this.programmation.seanceId).subscribe({
                                            next: (data: any) => {
                                                this.programmationBind = data.programmation;
                                                this.programmationBind.push({
                                                    id: uuidv4(),
                                                    date: new Date(this.programmation.date).getTime(),
                                                    dateFin: new Date(this.programmation.dateFin).getTime(),
                                                    objectifCycle: this.programmation.objectifCycle,
                                                    objectifSeance: this.programmation.objectifSeance,
                                                    alertes: this.programmation.alertes,
                                                    commentaire: this.programmation.commentaire,
                                                    info: this.programmation.info,
                                                    annulation: this.programmation.annulation,
                                                });
                                                this.seanceService
                                                    .editProgrammation(
                                                        this.programmation.seanceId,
                                                        this.programmationBind
                                                    )
                                                    .subscribe({
                                                        next: () => {
                                                            // On met à jour l'event en cours
                                                            let row = this.currentEvents[info.event.id - 1];
                                                            row.setProp('title', this.programmation.seanceNom);
                                                            row.setProp(
                                                                'id',
                                                                'SEA_' +
                                                                    this.programmation.seanceId +
                                                                    '_' +
                                                                    this.programmationBind[
                                                                        this.programmationBind.length - 1
                                                                    ].id
                                                            );
                                                            row.setProp(
                                                                'backgroundColor',
                                                                this.programmation.backgroundColor
                                                            );
                                                            row.setProp(
                                                                'borderColor',
                                                                this.programmation.backgroundColor
                                                            );
                                                            row.setProp('classNames', ['event-seance']);

                                                            row.setDates(
                                                                this.programmation.date,
                                                                this.programmation.dateFin
                                                            );
                                                            row.setExtendedProp('icon', 'asterisk');
                                                            row.setExtendedProp('type', 'SEANCE');
                                                            row.setExtendedProp('class', 'event-seance');
                                                            row.setExtendedProp(
                                                                'color',
                                                                this.programmation.backgroundColor
                                                            );

                                                            this.currentEvents[info.event.id - 1] = row;

                                                            this.calendarService
                                                                .setEvents(
                                                                    this.currentEvents,
                                                                    this.userID,
                                                                    this.translate.instant(
                                                                        'constant.CALENDAR_TYPE_PERSONAL'
                                                                    )
                                                                )
                                                                .subscribe({
                                                                    next: (data: any) => {
                                                                        this.loading = false;
                                                                        this.toastr.success(
                                                                            this.translate.instant(
                                                                                'calendar.success.maj'
                                                                            )
                                                                        );
                                                                    },
                                                                    error: () => {
                                                                        this.loading = false;
                                                                    },
                                                                });
                                                        },
                                                        error: () => {
                                                            this.loading = false;
                                                        },
                                                    });
                                            },
                                            error: () => {
                                                this.loading = false;
                                            },
                                        });
                                    } else {
                                        // On remove l'instance du calendrier à l'écran
                                        info.event.remove();
                                    }
                                });
                        });
                } else {
                    this.loading = false;
                    this.toastr.warning(this.translate.instant('calendar.error.noRights'));
                }
                break;
            case 'RDV':
                // On ouvre un pop-up pour saisir la définition du RDV
                this.breakpointObserver
                    .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
                    .pipe(first())
                    .subscribe((state: BreakpointState) => {
                        if (state.matches) {
                            dialogConfig.height = '100vh';
                            dialogConfig.width = '100vw';
                        } else {
                            dialogConfig.height = '';
                            dialogConfig.width = 'calc(50%)';
                        }

                        // The user can't close the dialog by clicking outside its body
                        dialogConfig.disableClose = true;
                        dialogConfig.id = 'rdv-component';
                        dialogConfig.maxWidth = '100vw';
                        dialogConfig.data = {
                            action: 'create',
                            date: new Date(info.event.start).getTime(),
                        };
                        this.matDialog
                            .open(RdvComponent, dialogConfig)
                            .afterClosed()
                            .subscribe((rdv) => {
                                if (rdv != 'Cancel') {
                                    this.loading = true;

                                    // On met à jour l'event en cours : Title, Commentaires
                                    let row = this.currentEvents[info.event.id - 1];
                                    row.setProp('title', rdv.objet);
                                    row.setProp('id', 'RDV_' + uuidv4());
                                    row.setExtendedProp('commentaire', rdv.commentaire);
                                    this.currentEvents[info.event.id - 1] = row;

                                    this.calendarService
                                        .setEvents(
                                            this.currentEvents,
                                            this.userID,
                                            this.translate.instant('constant.CALENDAR_TYPE_PERSONAL')
                                        )
                                        .subscribe({
                                            next: (data: any) => {
                                                this.loading = false;
                                                this.toastr.success(this.translate.instant('calendar.success.maj'));
                                            },
                                            error: () => {
                                                this.loading = false;
                                            },
                                        });
                                } else {
                                    // On remove l'instance du calendrier à l'écran
                                    info.event.remove();
                                }
                            });
                    });

                break;
        }
    }

    handleEventMouseEnter(info: EventClickArg) {
        let tooltip = '<div id="event-tooltip" matTolltip="Info about the action"></div>';
        info.el.insertAdjacentHTML('beforeend', tooltip);
    }

    handleEventMouseLeave(info: EventClickArg) {
        document.getElementById('event-tooltip')!.remove();
    }

    handleEventClick(info: EventClickArg) {
        let dialogConfig = new MatDialogConfig();

        switch (info.event.extendedProps['type']) {
            case 'SEANCE':
                if (
                    !this.IsPratiquant &&
                    (this.isAdmin || this.currentUser.params.rights.editSeances || this.IsEnseignant)
                ) {
                    let id = info.event.id.split('_');

                    // On envoi l'enseignant ou l'admin vers la fiche de présence du groupe
                    this.router.navigate(['dashboard/seances/edit/' + id[1]], {
                        queryParams: { programmation: id[2], returnUrl: '/', index: 2 },
                    });
                }

                break;
            case 'RDV':
                // On ouvre un pop-up pour saisir la définition du RDV
                this.breakpointObserver
                    .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
                    .pipe(first())
                    .subscribe((state: BreakpointState) => {
                        if (state.matches) {
                            dialogConfig.height = '100vh';
                            dialogConfig.width = '100vw';
                        } else {
                            dialogConfig.height = '';
                            dialogConfig.width = 'calc(50%)';
                        }
                        let row = this.currentEvents.find((o) => {
                            return o.id == info.event.id;
                        });

                        // The user can't close the dialog by clicking outside its body
                        dialogConfig.disableClose = true;
                        dialogConfig.id = 'rdv-component';
                        dialogConfig.maxWidth = '100vw';
                        dialogConfig.data = {
                            action: 'edit',
                            objet: row!.title,
                            commentaire: row!.extendedProps['commentaire'],
                        };
                        this.matDialog
                            .open(RdvComponent, dialogConfig)
                            .afterClosed()
                            .subscribe((rdv) => {
                                if (rdv != 'Cancel') {
                                    this.loading = true;

                                    // On met à jour l'event en cours : Title, Commentaire
                                    let row = this.currentEvents.find((o, i) => {
                                        this.eventIndex = i;
                                        return o.id == info.event.id;
                                    });

                                    row!.setProp('title', rdv.objet);
                                    row!.setProp('id', row!.id);
                                    row!.setExtendedProp('commentaire', rdv.commentaire);
                                    this.currentEvents[this.eventIndex] = row!;

                                    this.calendarService
                                        .setEvents(
                                            this.currentEvents,
                                            this.userID,
                                            this.translate.instant('constant.CALENDAR_TYPE_PERSONAL')
                                        )
                                        .subscribe({
                                            next: (data: any) => {
                                                this.loading = false;
                                                this.toastr.success(this.translate.instant('calendar.success.maj'));
                                            },
                                            error: () => {
                                                this.loading = false;
                                            },
                                        });
                                }
                            });
                    });

                break;
        }
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
        sessionStorage.setItem('nextID', String(this.currentEvents.length + 1));
    }

    // Gestion du paramétrage du calendrier

    handleCalendarToggle() {
        this.calendarVisible = !this.calendarVisible;
    }

    handleWeekendsToggle() {
        const { calendarOptions } = this;
        calendarOptions.weekends = !calendarOptions.weekends;
    }

    calendarRender() {
        this.calendarVisible = false;
        this.loading = true;
        this.showDays = [];
        const parameters = { businessHours: this.workSpecs };
        this.calendarService
            .setParams(parameters, this.userID, this.translate.instant('constant.CALENDAR_TYPE_PERSONAL'))
            .subscribe({
                next: () => {
                    // On calcule les différentes plages en tenant compte des businessHours
                    this.workMin = this.workSpecs
                        .map((item) => item.startTime)
                        .sort()
                        .shift()!;
                    this.workMax = this.workSpecs
                        .map((item) => item.endTime)
                        .sort()
                        .pop()!;
                    this.workDays = [...new Set(this.workSpecs.flatMap((item) => item.daysOfWeek))];
                    this.hideDays = [...Array(7).keys()].filter((day) => !this.workDays.includes(day));

                    this.calendarOptions.hiddenDays = this.hideDays;
                    this.calendarOptions.businessHours = this.workSpecs;
                    this.calendarOptions.slotMinTime = this.workMin;
                    this.calendarOptions.slotMaxTime = this.workMax;

                    this.workSpecs.forEach((val: any, key: any) => {
                        this.showDays[val.daysOfWeek[0]] = {
                            daysOfWeek: [val.daysOfWeek[0]],
                            startTime: val.startTime,
                            endTime: val.endTime,
                        }; // On arrange les index à partir de daysOfWeek pour le html
                    });

                    this.calendarVisible = true;
                    this.loading = false;
                    this.toastr.success(this.translate.instant('calendar.success.maj'));
                },
                error: () => {
                    this.calendarVisible = true;
                    this.loading = false;
                },
            });
    }

    timestampToDate(value: number) {
        return this.toolsService.timestampToDate(value);
    }

    onTimeChange(control: AbstractControl, event: any) {
        control.setValue(new Date(event.value.valueOf()));
    }

    onTabChange(event: MatTabChangeEvent) {
        switch (event.index) {
            case 0: // Agenda
                this.initCalendar();
                break;
            case 1: // Parametrage
                break;
        }
    }

    handleResize(info: any) {
        // Un évènement est retaillé dans le calendrier
        if (!this.IsPratiquant || info.event.extendedProps.type == 'RDV') {
            switch (info.event.extendedProps.type) {
                case 'SEANCE':
                    if (!this.IsPratiquant && (this.isAdmin || this.currentUser.params.rights.editSeances)) {
                        this.loading = true;
                        this.calendarService
                            .setEvents(
                                this.currentEvents,
                                this.userID,
                                this.translate.instant('constant.CALENDAR_TYPE_PERSONAL')
                            )
                            .subscribe({
                                next: () => {
                                    let id = info.event.id.split('_');
                                    this.seanceService.getProgrammation(id[1]).subscribe({
                                        next: (data: any) => {
                                            this.programmationBind = data.programmation;
                                            let index = 0;
                                            for (let [key, val] of Object.entries(this.programmationBind)) {
                                                if (val.id == id[2]) {
                                                    this.programmationBind[index].dateFin = new Date(
                                                        info.event.end
                                                    ).getTime();
                                                    break;
                                                }
                                                index++;
                                            }
                                            this.seanceService
                                                .editProgrammation(id[1], this.programmationBind)
                                                .subscribe({
                                                    next: () => {
                                                        this.loading = false;
                                                        this.toastr.success(
                                                            this.translate.instant('calendar.success.maj')
                                                        );
                                                    },
                                                    error: () => {
                                                        this.loading = false;
                                                    },
                                                });
                                        },
                                        error: () => {
                                            this.loading = false;
                                        },
                                    });
                                },
                                error: () => {
                                    this.loading = false;
                                },
                            });
                    } else {
                        this.loading = false;
                        info.revert();
                        this.toastr.warning(this.translate.instant('calendar.error.noRights'));
                    }
                    break;
                case 'RDV':
                    this.loading = true;
                    this.calendarService
                        .setEvents(
                            this.currentEvents,
                            this.userID,
                            this.translate.instant('constant.CALENDAR_TYPE_PERSONAL')
                        )
                        .subscribe({
                            next: () => {
                                this.loading = false;
                                this.toastr.success(this.translate.instant('calendar.success.maj'));
                            },
                            error: () => {
                                this.loading = false;
                            },
                        });
                    break;
            }
        } else {
            this.loading = false;
            info.revert();
            this.toastr.warning(this.translate.instant('calendar.error.noRights'));
        }
    }

    synchronization() {
        // On supprime les séances fantômes du calendrier
        this.loading = true;
        this.seanceService.getAllByEnseignant(this.userID).subscribe({
            next: (seances: any) => {
                // Est-ce que l'event existe pour la séance en cours ?
                // reverse loop pour le splice()
                for (let i = this.currentEvents.length - 1; i >= 0; i--) {
                    let event = this.currentEvents[i]._def.publicId.split('_');
                    if (event[0] == 'SEA') {
                        let arrayFiltered = seances.filter((x: any) => x.id === parseInt(event[1]));
                        if (arrayFiltered.length == 0) {
                            this.currentEvents.splice(i, 1); // On supprime l'event qui n'a pas de correspondance de séance
                        }
                    }
                }
                let all_obs: any = [];
                let events: any = [];
                seances.forEach((o: any, i: any) => {
                    let seanceID = o.id;
                    let seanceItem: Seance = o.seance;
                    all_obs.push(
                        this.seanceService.getProgrammation(seanceID).pipe(
                            map((data: any) => {
                                this.programmationBind = data.programmation;

                                // Est-ce que l'event existe dans les programmations de la séance en cours ?
                                // reverse loop pour le splice()
                                for (let i = this.currentEvents.length - 1; i >= 0; i--) {
                                    events[i] = this.currentEvents[i]._def.publicId.split('_');

                                    // On selectionne les évènements de la séance en cours
                                    if (events[i][0] == 'SEA' && parseInt(events[i][1]) == seanceID) {
                                        let arrayFiltered = this.programmationBind.filter((x) => x.id == events[i][2]);
                                        if (arrayFiltered.length == 0) {
                                            this.currentEvents.splice(i, 1); // On supprime l'event qui n'a pas de correspondance de programmation
                                            events.splice(i, 1);
                                        }
                                    }
                                }
                                // On complète les events éventuellement manquants
                                let exist = false;
                                for (let i = 0; i < this.programmationBind.length; i++) {
                                    for (let j = 0; j < events.length; j++) {
                                        // On selectionne les évènements de la séance en cours
                                        if (events[j][0] == 'SEA' && parseInt(events[j][1]) == seanceID) {
                                            if (events[j][2].includes(this.programmationBind[i].id)) {
                                                exist = true;
                                                break;
                                            }
                                        }
                                    }
                                    if (!exist) {
                                        // On ajoute l'évènement

                                        let lieu = this.locations.lieux.find((x) => x.value == seanceItem.lieuPratique);

                                        if (lieu!.value == this.translate.instant('constant.SEANCE_LIEU_EXTERNE')) {
                                            // On remplace le nom par la structure
                                            lieu!.name = seanceItem.lieuExterne.organisation;
                                        }

                                        // Problème possible sur l'abscence de date de fin ou dateFin null
                                        if (
                                            !Object.keys(this.programmationBind[i]).includes('dateFin') ||
                                            this.programmationBind[i].dateFin == null
                                        ) {
                                            this.programmationBind[i].dateFin = this.programmationBind[i].date;
                                        }

                                        // On ajoute l'event dans le tableau d'events
                                        this.currentEvents.push(
                                            this.calendarApi.addEvent({
                                                title: seanceItem.activite.nom + ' - ' + lieu!.name,
                                                start: formatDate(
                                                    new Date(this.programmationBind[i].date),
                                                    'YYYY-MM-ddTHH:mm',
                                                    'fr-FR'
                                                ),
                                                end: formatDate(
                                                    new Date(this.programmationBind[i].dateFin),
                                                    'YYYY-MM-ddTHH:mm',
                                                    'fr-FR'
                                                ),
                                                id: 'SEA_' + seanceID + '_' + this.programmationBind[i].id,
                                                classNames: ['event-seance'],
                                                backgroundColor: seanceItem.activite.color,
                                                borderColor: seanceItem.activite.color,
                                                extendedProps: {
                                                    icon: 'asterisk',
                                                    type: 'SEANCE',
                                                    class: 'event-seance',
                                                    color: seanceItem.activite.color,
                                                },
                                            })!
                                        );
                                    }
                                    exist = false;
                                }
                            })
                        )
                    );
                });
                // On attend l'ensemble des observables calculés dans le foreach du dessus
                forkJoin(all_obs).subscribe({
                    next: () => {
                        //On sauvegarde la mise à jour du calendrier
                        this.calendarService
                            .setEvents(
                                this.currentEvents,
                                this.userID,
                                this.translate.instant('constant.CALENDAR_TYPE_PERSONAL')
                            )
                            .subscribe({
                                next: (data: any) => {
                                    this.calendarVisible = false;
                                    setTimeout(() => {
                                        this.calendarVisible = true;
                                        this.loading = false;
                                    });
                                    this.toastr.success(this.translate.instant('calendar.success.maj'));
                                },
                                error: () => {
                                    this.calendarVisible = false;
                                    setTimeout(() => {
                                        this.calendarVisible = true;
                                        this.loading = false;
                                    });
                                },
                            });
                    },
                });
            },
            error: () => {
                this.calendarVisible = false;
                setTimeout(() => {
                    this.calendarVisible = true;
                    this.loading = false;
                });
            },
        });
    }

    handleEventAdd(info: any) {
        return;
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        return;
    }

    initCalendar() {
        // On initialise le calendrier
        this.initializing = true;
        this.loading = true;
        this.calendarService
            .getEvents(this.userID, this.translate.instant('constant.CALENDAR_TYPE_PERSONAL'))
            .subscribe({
                next: (initEvents: any) => {
                    this.initializing = false;
                    this.calendarOptions = {
                        droppable: true,
                        headerToolbar: {
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                        },
                        locales: [frLocale],
                        locale: 'fr',
                        initialView: 'timeGridWeek',
                        events: initEvents,
                        editable: true,
                        selectable: true,
                        selectMirror: true,
                        dayMaxEvents: true,
                        allDaySlot: false,
                        eventConstraint: 'businessHours',
                        selectConstraint: 'businessHours',
                        businessHours: this.workSpecs,
                        slotMinTime: this.workMin,
                        slotMaxTime: this.workMax,
                        hiddenDays: this.hideDays,
                        displayEventTime: false,
                        contentHeight: 'auto',

                        select: this.handleDateSelect.bind(this), // Sélection sur une case de date vide
                        eventClick: this.handleEventClick.bind(this), // On clique sur une date dans le calendrier
                        eventMouseEnter: this.handleEventMouseEnter.bind(this), // On survol une date dans le calendrier
                        eventMouseLeave: this.handleEventMouseLeave.bind(this), // On sort du survol d'une date dans le calendrier
                        eventsSet: this.handleEvents.bind(this), // Lorsqu'un event est créé
                        eventReceive: this.handleEventReceive.bind(this), // Un évènement draggué de l'extérieur
                        eventAdd: this.handleEventAdd.bind(this), // Un évènement ajouté en cliquant dans le calendrier une case vide
                        eventDrop: this.handleEventDrop.bind(this), // Un évènement est déplacé dans le calendrier
                        eventDragStop: this.handleDragStop.bind(this), // Un évènement déplacé en dehors du calendrier pour être supprimé
                        eventResize: this.handleResize.bind(this), // Un event est retaillé

                        /* you can update a remote database when these fire:
                  eventRemove:
                  */
                    };
                    this.calendarVisible = true;
                    this.changeDetector.detectChanges(); // Après rendu visible on pointe sur #fullcalendar en détectant le changement de visibilité
                    this.calendarApi = this.calendarComponent.getApi();

                    new Draggable(this.external.nativeElement, {
                        itemSelector: '.fc-event',
                        eventData: function (eventEl) {
                            let props = JSON.parse(eventEl.getAttribute('data-type')!); // On récupère les propriétés étendues déclarées dans l'HTML
                            let netxId = sessionStorage.getItem('nextID'); // On récupère le prochain ID pour l'ajout de l'évènement
                            return {
                                id: netxId,
                                title: eventEl.innerText,
                                className: props.extendedProps.class,
                                backgroundColor: props.extendedProps.color,
                                borderColor: props.extendedProps.color,
                                icon: 'asterisk',
                            };
                        },
                    });
                    if (initEvents) sessionStorage.setItem('nextID', String(initEvents.length + 1));
                    else sessionStorage.setItem('nextID', '1');
                    this.calendarVisible = false;
                    setTimeout(() => {
                        this.calendarVisible = true;
                        this.loading = false;
                    });

                    // Si pas owner alors on affiche le nom du propriétaire en tête d'agenda
                    if (this.userID != this.currentUser.id) {
                        this.userService.getUser(this.userID).subscribe({
                            next: (user) => {
                                this.ownerAgenda = Object(user).firstname + ' ' + Object(user).lastname;
                            },
                            error: () => {
                                this.loading = false;
                            },
                        });
                    }
                },
                error: () => {
                    this.loading = false;
                },
            });
    }
}
