<div id="modal-content-wrapper">
    <form [formGroup]="programmationForm" (ngSubmit)="onSubmit('OK')">
        <div class="row">
            <div class="col-md-12">
                <h4>
                    {{ 'calendar.programmation.titre' | translate }}
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>{{ 'calendar.programmation.choixSeance' | translate }}</mat-label>
                    <mat-select formControlName="seanceId">
                        <mat-option *ngFor="let seance of listSeances" [value]="seance.id">
                            {{ seance.nom }} - {{ seance.lieu }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && p['seanceId'].hasError('required')">{{
                        'error.obligatoire' | translate
                        }}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>{{ 'programmation.titre.date' | translate }}</mat-label>
                    <input matInput formControlName="date" placeholder="{{ 'programmation.titre.date' | translate }}"
                        type="datetime-local" />
                    <mat-error *ngIf="submitted p['date'].hasError('required')">{{
                        'error.obligatoire' | translate
                        }}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'programmation.titre.objectifCycle' | translate }}</mat-label>
                    <textarea matInput formControlName="objectifCycle" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"></textarea>
                    <mat-error
                        *ngIf="submitted && p['objectifCycle'].invalid && p['objectifCycle'].hasError('required')">{{
                        'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'programmation.titre.objectifSeance' | translate }}</mat-label>
                    <textarea matInput formControlName="objectifSeance" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"></textarea>
                    <mat-error
                        *ngIf="submitted && p['objectifSeance'].invalid && p['objectifSeance'].hasError('required')">{{
                        'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'programmation.titre.alertes' | translate }}</mat-label>
                    <textarea matInput formControlName="alertes" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"></textarea>
                    <mat-error *ngIf="submitted && p['alertes'].invalid && p['alertes'].hasError('required')">{{
                        'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'programmation.titre.commentaire' | translate }}</mat-label>
                    <textarea matInput formControlName="commentaire" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"></textarea>
                    <mat-error *ngIf="submitted && p['commentaire'].invalid && p['commentaire'].hasError('required')">{{
                        'error.obligatoire' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-group text-center mt-5">
            <button [disabled]="loading" class="btn btn-primary mr-5">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{ 'button.envoyer' | translate }}
            </button>
            <span role="button" class="btn btn-secondary" (click)="onSubmit('Cancel')">{{
                'button.annuler' | translate
                }}</span>
        </div>
    </form>
</div>