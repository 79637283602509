import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule, MaterialModule, EnseignantsSharedModule, CalendarSharedModule } from '@/_shared';

import { BoardSeancesComponent, BoardParticipantsComponent, BoardAgendasComponent } from '@/_components';

import { NgxMaskModule } from 'ngx-mask';
//import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule,
        AlertModule,
        MaterialModule,
        EnseignantsSharedModule,
        CalendarSharedModule,
        //FlexLayoutModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [BoardSeancesComponent, BoardParticipantsComponent, BoardAgendasComponent],

    declarations: [BoardSeancesComponent, BoardParticipantsComponent, BoardAgendasComponent],
})
export class BoardsModule {}
