import { ISimage } from '@/_interfaces';

export interface IStest {
    titre: string;
    description: string;
    images: Array<ISimage>;
    type: number;
    mesures: Array<{
        type: string;
        description: string;
        field_csv: string;
        min: number;
        max: number;
    }>;
}
