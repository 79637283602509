import { Component, Input, Output, forwardRef, OnDestroy, EventEmitter } from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
    NG_VALIDATORS,
} from '@angular/forms';

import { Subscription } from 'rxjs';

import { AuthenticationService, ToolsService } from '@/_services';

import { User } from '@/_models';

// describes what the return value of the form control will look like
export interface adloadFormValues {
    titre: string;
    description: string;
    selector: string;
    type: 0;
    fields: {
        q11: string;
        q12: string;
        q13: string;
        q14_fort: string;
        q14_modere: string;
        q15_fort: string;
        q15_modere: string;
        q16_fort: string;
        q16_modere: string;
        q17_ecran: string;
        q17_autre: string;
        q18_ecran: string;
        q18_autre: string;
        q19_ecran: string;
        q19_autre: string;
    };
}

@Component({
    selector: 'app-adload',
    templateUrl: './adload.component.html',
    styleUrls: ['./adload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AdloadComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AdloadComponent),
            multi: true,
        },
    ],
})
export class AdloadComponent implements ControlValueAccessor, OnDestroy {
    @Input() inData: any;
    @Input() inProduction: any;
    @Output() parentFormSubmittedChange = new EventEmitter<boolean>();
    @Input() set parentFormSubmitted(isSubmitted: boolean) {
        if (isSubmitted) {
            this.submitted = true;
            setTimeout(() => {
                this.parentFormSubmittedChange.next(false);
            });
        }
    }

    currentUser: User;
    loading = false;
    submitted = false;
    isAdmin: boolean;
    formID = 0;
    returnUrl: string = '/dashboard/forms';
    fieldsForm: FormGroup;
    subscriptions: Subscription[] = [];
    listeModes: Array<any> = [];
    ouiq11: string = '0';
    ouiq14_fort: string = '0';
    ouiq14_modere: string = '0';
    ouiq17_ecran: string = '0';
    ouiq17_autre: string = '0';

    numericMask = '0*';
    heureMask = 'Hh:m0';

    constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private toolsService: ToolsService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isAdmin = this.authenticationService.isAdmin(this.currentUser);
        // Le nom des champs correspond aux noms ONAPS
        this.fieldsForm = this.formBuilder.group({
            selector: this.formBuilder.control('app-adload'),
            fields: this.formBuilder.group({
                q11: this.formBuilder.control('', Validators.required),
                q12: this.formBuilder.control(''),
                q13: this.formBuilder.control(''),
                q14_fort: this.formBuilder.control('', Validators.required),
                q14_modere: this.formBuilder.control('', Validators.required),
                q15_fort: this.formBuilder.control(''),
                q15_modere: this.formBuilder.control(''),
                q16_fort: this.formBuilder.control(''),
                q16_modere: this.formBuilder.control(''),
                q17_ecran: this.formBuilder.control('', Validators.required),
                q17_autre: this.formBuilder.control('', Validators.required),
                q18_ecran: this.formBuilder.control(''),
                q18_autre: this.formBuilder.control(''),
                q19_ecran: this.formBuilder.control(''),
                q19_autre: this.formBuilder.control(''),
            }),
        });

        this.subscriptions.push(
            // any time the inner form changes update the parent of any change
            this.fieldsForm.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.fieldsForm.controls;
    }

    get value(): adloadFormValues {
        return this.fieldsForm.value;
    }

    set value(value: adloadFormValues) {
        this.fieldsForm.patchValue(value);
        this.onChange(value);
        this.onTouched();
    }

    // communicate the inner form validation to the parent form
    validate(_: FormControl) {
        return this.fieldsForm.valid ? null : { genericFields: { valid: false } };
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
            this.fieldsForm.patchValue(this.value);
        }

        if (value === null) {
            this.fieldsForm.reset();
        }
    }
    // On initialise le formulaire
    ngAfterContentInit(): void {
        // console.log(this.inData);
        this.writeValue(this.inData.formBind.genericFields);

        // Conversion en heures:minutes des minutes
        this.fieldsForm
            .get('fields.q13')!
            .setValue(this.toolsService.convertMinutes(this.fieldsForm.get('fields.q13')!.value));

        this.fieldsForm
            .get('fields.q16_fort')!
            .setValue(this.toolsService.convertMinutes(this.fieldsForm.get('fields.q16_fort')!.value));

        this.fieldsForm
            .get('fields.q16_modere')!
            .setValue(this.toolsService.convertMinutes(this.fieldsForm.get('fields.q16_modere')!.value));

        this.fieldsForm
            .get('fields.q19_ecran')!
            .setValue(this.toolsService.convertMinutes(this.fieldsForm.get('fields.q19_ecran')!.value));

        this.fieldsForm
            .get('fields.q19_autre')!
            .setValue(this.toolsService.convertMinutes(this.fieldsForm.get('fields.q19_autre')!.value));

        if (this.inData.formBind.hasOwnProperty('genericFields')) {
            this.ouiq11 = this.inData.formBind.genericFields.fields.q11;
            if (this.ouiq11 == '1') {
                this.fieldsForm.get('fields.q12')?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                this.fieldsForm.get('fields.q13')?.setValidators(Validators.required);
            }

            this.ouiq14_fort = this.inData.formBind.genericFields.fields.q14_fort;
            if (this.ouiq14_fort == '1') {
                this.fieldsForm
                    .get('fields.q15_fort')
                    ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                this.fieldsForm.get('fields.q16_fort')?.setValidators(Validators.required);
            }

            this.ouiq14_modere = this.inData.formBind.genericFields.fields.q14_modere;
            if (this.ouiq14_modere == '1') {
                this.fieldsForm
                    .get('fields.q15_modere')
                    ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                this.fieldsForm.get('fields.q16_modere')?.setValidators(Validators.required);
            }

            this.ouiq17_ecran = this.inData.formBind.genericFields.fields.q17_ecran;
            if (this.ouiq17_ecran == '1') {
                this.fieldsForm
                    .get('fields.q18_ecran')
                    ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                this.fieldsForm.get('fields.q19_ecran')?.setValidators(Validators.required);
            }

            this.ouiq17_autre = this.inData.formBind.genericFields.fields.q17_autre;
            if (this.ouiq17_autre == '1') {
                this.fieldsForm
                    .get('fields.q18_autre')
                    ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                this.fieldsForm.get('fields.q19_autre')?.setValidators(Validators.required);
            }
        }
    }

    onSetYN(event: any, field: string) {
        switch (field) {
            case 'q11':
                this.ouiq11 = event.value;
                if (event.value == '1') {
                    this.fieldsForm
                        .get('fields.q12')
                        ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                    this.fieldsForm.get('fields.q13')?.setValidators(Validators.required);
                } else {
                    this.fieldsForm.get('fields.q12')?.setValidators(null);
                    this.fieldsForm.get('fields.q12')?.setValue(null);
                    this.fieldsForm.get('fields.q13')?.setValidators(null);
                    this.fieldsForm.get('fields.q13')?.setValue(null);
                }

                this.fieldsForm.get('fields.q12')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q13')!.updateValueAndValidity();
                break;
            case 'q14_fort':
                this.ouiq14_fort = event.value;
                if (event.value == '1') {
                    this.fieldsForm
                        .get('fields.q15_fort')
                        ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                    this.fieldsForm.get('fields.q16_fort')?.setValidators(Validators.required);
                } else {
                    this.fieldsForm.get('fields.q15_fort')?.setValidators(null);
                    this.fieldsForm.get('fields.q15_fort')?.setValue(null);
                    this.fieldsForm.get('fields.q16_fort')?.setValidators(null);
                    this.fieldsForm.get('fields.q16_fort')?.setValue(null);
                }

                this.fieldsForm.get('fields.q15_fort')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q16_fort')!.updateValueAndValidity();
                break;
            case 'q14_modere':
                this.ouiq14_modere = event.value;
                if (event.value == '1') {
                    this.fieldsForm
                        .get('fields.q15_modere')
                        ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                    this.fieldsForm.get('fields.q16_modere')?.setValidators(Validators.required);
                } else {
                    this.fieldsForm.get('fields.q15_modere')?.setValidators(null);
                    this.fieldsForm.get('fields.q15_modere')?.setValue(null);
                    this.fieldsForm.get('fields.q16_modere')?.setValidators(null);
                    this.fieldsForm.get('fields.q16_modere')?.setValue(null);
                }

                this.fieldsForm.get('fields.q15_modere')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q16_modere')!.updateValueAndValidity();
                break;
            case 'q17_ecran':
                this.ouiq17_ecran = event.value;
                if (event.value == '1') {
                    this.fieldsForm
                        .get('fields.q18_ecran')
                        ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                    this.fieldsForm.get('fields.q19_ecran')?.setValidators(Validators.required);
                } else {
                    this.fieldsForm.get('fields.q18_ecran')?.setValidators(null);
                    this.fieldsForm.get('fields.q18_ecran')?.setValue(null);
                    this.fieldsForm.get('fields.q19_ecran')?.setValidators(null);
                    this.fieldsForm.get('fields.q19_ecran')?.setValue(null);
                }

                this.fieldsForm.get('fields.q18_ecran')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q19_ecran')!.updateValueAndValidity();
                break;

            case 'q17_autre':
                this.ouiq17_autre = event.value;
                if (event.value == '1') {
                    this.fieldsForm
                        .get('fields.q18_autre')
                        ?.setValidators([Validators.required, this.toolsService.plage(0, 7)]);
                    this.fieldsForm.get('fields.q19_autre')?.setValidators(Validators.required);
                } else {
                    this.fieldsForm.get('fields.q18_autre')?.setValidators(null);
                    this.fieldsForm.get('fields.q18_autre')?.setValue(null);
                    this.fieldsForm.get('fields.q19_autre')?.setValidators(null);
                    this.fieldsForm.get('fields.q19_autre')?.setValue(null);
                }

                this.fieldsForm.get('fields.q18_autre')!.updateValueAndValidity();
                this.fieldsForm.get('fields.q19_autre')!.updateValueAndValidity();
                break;
        }
    }
}
