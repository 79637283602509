<div *ngIf="loading" class="d-flex justify-content-center loader">
    <div class="spinner-border text-danger" role="status" style="width: 3rem; height: 3rem"></div>
</div>

<div *ngIf="!loading && !showProgrammation" class="container-fluid">
    <div class="col-12">
        <div class="card mb-3">
            <div class="card-body table-responsive">
                <mat-form-field>
                    <span mat-icon-button matSuffix><mat-icon>filter_list</mat-icon></span>
                    <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'filtre' | translate }}" />
                </mat-form-field>
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    #sort="matSort"
                    class="mat-elevation-z8"
                    (matSortChange)="sortData($event, 'tabBoardSeances')"
                >
                    <!-- nom Column -->
                    <ng-container matColumnDef="nom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'seance.label.nom' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span role="button" class="text-primary" (click)="getSeance(element.id)">{{
                                element.nom
                            }}</span>
                        </td>
                    </ng-container>

                    <!-- auteur Column -->
                    <ng-container matColumnDef="lieu">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'seance.label.lieu' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.lieu }}
                        </td>
                    </ng-container>

                    <!-- auteur Column -->
                    <ng-container matColumnDef="debut">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'seance.label.debut' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.debut | date : 'longDate' }}
                        </td>
                    </ng-container>

                    <!-- auteur Column -->
                    <ng-container matColumnDef="fin">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'seance.label.fin' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.fin | date : 'longDate' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="enseignant">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'seance.label.enseignant' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.enseignant }}
                        </td>
                    </ng-container>

                    <ng-container class="text-center" matColumnDef="inscrits">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'seance.label.inscrits' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.inscrits }}/{{ element.maxParticipants }}</td>
                    </ng-container>

                    <!-- lien documentation -->
                    <ng-container class="text-center" matColumnDef="paiements">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'seance.label.paiements' | translate }}
                        </th>
                        <ng-container *matCellDef="let element">
                            <td class="text-primary" mat-cell *ngIf="element.inscrits > 0">
                                <mat-icon role="button" *ngIf="element.typeClient" (click)="getPaiements(element.id)"
                                    >domain</mat-icon
                                >
                                <mat-icon role="button" *ngIf="!element.typeClient" (click)="getPaiements(element.id)"
                                    >group</mat-icon
                                >
                            </td>
                            <td class="text-muted" mat-cell *ngIf="element.inscrits == 0">
                                <mat-icon *ngIf="element.typeClient">domain</mat-icon>
                                <mat-icon *ngIf="!element.typeClient">group</mat-icon>
                            </td>
                        </ng-container>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator
                    [pageSizeOptions]="[15, 25, 50, 100]"
                    #paginator="matPaginator"
                    (page)="onPaginateChange($event, 'pageBoardSeances')"
                ></mat-paginator>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="seanceID > 0 && !loading && showProgrammation" [formGroup]="programmationForm">
    <div class="col-12 mt-3">
        <div class="card mb-3">
            <div class="card-body table-responsive">
                <mat-form-field>
                    <span mat-icon-button matSuffix><mat-icon>filter_list</mat-icon></span>
                    <input
                        matInput
                        (keyup)="applyFilterProgrammations($event)"
                        placeholder="{{ 'filtre' | translate }}"
                    />
                </mat-form-field>
                <div class="table-responsive">
                    <table
                        mat-table
                        [dataSource]="dataProgrammationsSource"
                        matSort
                        #sortProgrammations="matSort"
                        class="table mat-elevation-z8"
                        (matSortChange)="sortData($event, 'tabBoardProgrammations')"
                    >
                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'programmation.titre.date' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field>
                                    <mat-label>{{ 'programmation.titre.date' | translate }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="date"
                                        placeholder="{{ 'programmation.titre.date' | translate }}"
                                        type="datetime-local"
                                        [readonly]="true"
                                    />
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <!-- Groupe Column -->
                        <ng-container matColumnDef="groupe">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                {{ 'seance.titre.fichePresence' | translate }}
                                <mat-icon
                                    matTooltipClass="custom-tooltip"
                                    matToolTipPosition="above"
                                    matTooltip="{{ 'programmation.help.groupe' | translate }}"
                                    >help</mat-icon
                                >
                            </th>
                            <td
                                mat-cell
                                [formGroup]="element"
                                *matCellDef="let element"
                                (click)="goGroupe(element.get('id')!.value)"
                                class="text-primary text-center"
                            >
                                <span role="button"><mat-icon>supervised_user_circle</mat-icon></span>
                            </td>
                        </ng-container>
                        <!-- Cycle Column -->
                        <ng-container matColumnDef="objectifCycle">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'programmation.titre.objectifCycle' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'programmation.titre.objectifCycle' | translate }}</mat-label>
                                    <textarea
                                        matInput
                                        formControlName="objectifCycle"
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="10"
                                        [readonly]="true"
                                    ></textarea>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <!-- Seance Column -->
                        <ng-container matColumnDef="objectifSeance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'programmation.titre.objectifSeance' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'programmation.titre.objectifSeance' | translate }}</mat-label>
                                    <textarea
                                        matInput
                                        formControlName="objectifSeance"
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="10"
                                        [readonly]="true"
                                    ></textarea>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Commentaire Column -->
                        <ng-container matColumnDef="commentaire">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'programmation.titre.commentaire' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'programmation.titre.commentaire' | translate }}</mat-label>
                                    <textarea
                                        matInput
                                        formControlName="commentaire"
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="10"
                                        [readonly]="true"
                                    ></textarea>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedProgrammationsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedProgrammationsColumns"></tr>
                    </table>
                </div>
                <mat-paginator
                    [pageSizeOptions]="[5, 10, 25, 100]"
                    #paginatorProgrammations="matPaginator"
                ></mat-paginator>

                <span role="button" class="btn btn-secondary mt-3" (click)="back()">{{
                    'button.back' | translate
                }}</span>
            </div>
        </div>
    </div>
</ng-container>
