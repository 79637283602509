<div id="modal-content-wrapper">
    <header id="modal-header">
        <h1 id="modal-title">{{ modalData.title }}</h1>
    </header>
    <section id="modal-body">
        <mat-dialog-content [innerHTML]="modalData.body"></mat-dialog-content>
    </section>
    <footer id="modal-footer">
        <button mat-raised-button id="modal-action-button" (click)="close('OK')">
            {{ modalData.actionButtonText }}
        </button>
        <button mat-raised-button id="modal-cancel-button" (click)="close('Cancel')">
            {{ 'button.annuler' | translate }}
        </button>
    </footer>
</div>
