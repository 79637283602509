import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from '@/_models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    rep: any;

    private currentUserSubject = new BehaviorSubject<User>(JSON.parse('{}'));
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        const currentUser = sessionStorage.getItem('currentUser');
        if (currentUser) {
            try {
                this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(currentUser));
            } catch (err) {
                sessionStorage.removeItem('currentUser');
                this.currentUserSubject.next(new User());
            }
        }

        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    token(user: User): Observable<any> {
        sessionStorage.removeItem('jwt_token'); // Initialisation
        sessionStorage.removeItem('refresh_token'); // Initialisation
        return this.http
            .post<any>(
                this.apiUrl + 'api/token',
                JSON.stringify({
                    username: user.username,
                    password: user.password,
                }),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
            )
            .pipe(
                tap({
                    next: (data) => {
                        sessionStorage.setItem('jwt_token', data['token']);
                        sessionStorage.setItem('refresh_token', data['refresh_token']);
                    },
                })
            );
    }

    refresh() {
        sessionStorage.removeItem('jwt_token'); // Initialisation
        return this.http
            .post<any>(
                this.apiUrl + 'api/token/refresh',
                JSON.stringify({
                    refresh_token: sessionStorage.getItem('refresh_token'),
                }),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
            )
            .pipe(
                tap((tokens) => {
                    sessionStorage.setItem('jwt_token', tokens.token);
                    sessionStorage.setItem('refresh_token', tokens.refresh_token);
                })
            );
    }

    login(user: User): Observable<any> {
        sessionStorage.removeItem('currentUser'); // Initialisation
        return this.http
            .post<any>(
                this.apiUrl + 'user/login',
                JSON.stringify({
                    appKey: this.appKey,
                    username: user.username,
                    password: user.password,
                }),
                {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + sessionStorage.getItem('jwt_token'),
                    }),
                }
            )
            .pipe(
                tap({
                    next: (data) => {
                        data['token'] = sessionStorage.getItem('jwt_token');
                        sessionStorage.setItem('currentUser', JSON.stringify(data));
                        this.currentUserSubject.next(data);
                    },
                })
            );
    }

    logout() {
        sessionStorage.clear();
        this.currentUserSubject.next(new User());
    }

    resetPassword(username: string) {
        return this.http.post(
            this.apiUrl + 'user/resetpassword',
            JSON.stringify({
                appKey: this.appKey,
                username: username,
            })
        );
    }

    validPassword(password: string, token: string) {
        return this.http.post(
            this.apiUrl + 'user/validpassword',
            JSON.stringify({
                appKey: this.appKey,
                password: password,
                token: token,
            })
        );
    }

    isSuperAdmin(user: User): boolean {
        // On renvoi true si superviseur
        if (user.roles && user.roles.includes('ROLE_SUPER_ADMIN')) return true;
        else return false;
    }

    isAdmin(user: User): boolean {
        // On renvoi true si admin (administratif ou superuser)
        if (user.roles && (user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN'))) return true;
        else return false;
    }

    isActi3S(user: User): boolean {
        // On renvoi true si superuser et propriétaire acti3S
        let licence = JSON.parse(sessionStorage.getItem('licenceParams')!);
        if (user.roles && user.roles.includes('ROLE_SUPER_ADMIN') && licence['licence']['administrateur']) return true;
        else return false;
    }

    isEnseignant(user: User): boolean {
        // On renvoi true si enseignant
        if (user.roles && user.roles.includes('ROLE_ENSEIGNANT')) return true;
        else return false;
    }

    isPratiquant(user: User): boolean {
        // On renvoi true si pratiquant
        if (user.roles && user.roles.includes('ROLE_PRATIQUANT')) return true;
        else return false;
    }

    isPro(user: User): boolean {
        // On renvoi true si pro
        if (user.roles && user.roles.includes('ROLE_PRO')) return true;
        else return false;
    }
}
