import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Company, Inscription } from '@/_models';

@Injectable({
    providedIn: 'root',
})
export class InscriptionService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    profil: User = new User();
    currentUser: User = new User();

    constructor(private http: HttpClient) {}

    // Création de l'inscription d'un participant
    create(inscriptionData: Inscription) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'inscription/create',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                enseignant: this.currentUser.id,
                inscriptionData: inscriptionData,
            })
        );
    }

    // suppression de l'inscription d'un participant
    delete(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'inscription/delete',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                id: id,
            })
        );
    }

    // Edition de l'inscription d'un participant
    edit(inscriptionData: Inscription, seanceID: number, userID: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'inscription/edit',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                user: userID,
                seance: seanceID,
                inscriptionData: inscriptionData,
            })
        );
    }

    // Load toutes les inscriptions d'un inscrit
    getAll(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'inscriptions',
            JSON.stringify({
                appKey: this.appKey,
                user_id: id,
                company: this.currentUser.company,
            })
        );
    }

    // Load toutes les inscriptions de la company avec les séances associées
    getAllCompany() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'inscriptions/company',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    // Load toutes les inscriptions de tous les pratiquants
    getListEtat() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'inscriptions/etat',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    getListEtatBySeance(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'inscriptions/etatSeance',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
                id: id,
            })
        );
    }

    // Load une inscription
    get(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'inscriptions/inscription',
            JSON.stringify({
                appKey: this.appKey,
                user_id: id,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    // Load les inscrits à une séance
    getInscrits(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post<any>(
            this.apiUrl + 'inscriptions/inscrits',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }
}
