export class ISperiodic {
    debut: string = '';
    fin: string = '';
    periodicite: string = '';
    daily: { days: string; alldays: boolean } = { days: '', alldays: false };
    weekly: { weeks: string; daysOfWeek: boolean[] } = { weeks: '', daysOfWeek: [] };
    plage: { debut: string; fin: string; occurences: string; choixPlage: string } = {
        debut: '',
        fin: '',
        occurences: '',
        choixPlage: '',
    };
}
