import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Company, Enseignant } from '@/_models';
import { ISepreuve } from '@/_interfaces';

@Injectable({
    providedIn: 'root',
})
export class ParcoursService {
    private apiUrl = environment.apiUrl;
    private appKey = environment.appKey;

    company: Company = new Company();
    currentUser: User = new User();

    constructor(private http: HttpClient) {}

    setParcours(parcoursID: number, epreuveID: number, resultats: [], action: string) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'parcours/edit',
            JSON.stringify({
                appKey: this.appKey,
                parcoursID: parcoursID,
                epreuveID: epreuveID,
                company: this.currentUser.company,
                resultats: resultats,
                action: action,
            })
        );
    }

    getAll() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'parcours/parcours',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    // Get all Parcours attached to one epreuve
    getParcours(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'parcours/getParcours',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    // Get all resultats of a company
    getAllResultats() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'parcours/getAllResultats',
            JSON.stringify({
                appKey: this.appKey,
                company: this.currentUser.company,
            })
        );
    }

    // Get resultats from id of efforts
    getResultats(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'parcours/getResultats',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    // Get all resultats of a participant
    getEfforts(id: number) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'parcours/getEfforts',
            JSON.stringify({
                appKey: this.appKey,
                id: id,
                company: this.currentUser.company,
            })
        );
    }

    delete(ids: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'parcours/delete',
            JSON.stringify({
                appKey: this.appKey,
                ids: ids,
                company: this.currentUser.company,
            })
        );
    }

    copy(ids: string[]) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        return this.http.post(
            this.apiUrl + 'parcours/copy',
            JSON.stringify({
                appKey: this.appKey,
                ids: ids,
                company: this.currentUser.company,
            })
        );
    }
}
