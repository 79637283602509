import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from '@/_components';

@NgModule({
  imports: [CommonModule],

  exports: [AlertComponent],
  declarations: [AlertComponent],
})
export class AlertModule {}
