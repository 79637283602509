<div id="modal-content-wrapper">
    <form [formGroup]="periodicForm" (ngSubmit)="onSubmit('OK')">
        <div class="row">
            <div class="col-md-12">
                <p class="text-primary">
                    {{ 'periodic.titre.seance' | translate }}
                </p>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <mat-label>{{ 'periodic.label.hour' | translate }}</mat-label>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field>
                        <mat-label>{{ 'periodic.label.start' | translate }}</mat-label>
                        <input matInput formControlName="debut" type="time" />
                        <mat-error *ngIf="submitted && p['debut'].hasError && p['debut'].hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field>
                        <mat-label>{{ 'periodic.label.stop' | translate }}</mat-label>
                        <input matInput formControlName="fin" type="time" />
                        <mat-error *ngIf="submitted && p['fin'].hasError && p['fin'].hasError('required')"
                            >{{ 'error.obligatoire' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3">
                <mat-label>{{ 'periodic.titre.periodicite' | translate }}</mat-label>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <mat-radio-group class="vertical-radio" formControlName="periodicite" (change)="onPeriode($event)">
                        <mat-radio-button *ngFor="let period of periods" [value]="period.value">
                            {{ period.name }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <!-- DAILY -->
                <div *ngIf="setDaily" class="col-md-8 pl-5" formGroupName="daily">
                    <mat-radio-group class="vertical-radio" formControlName="alldays">
                        <mat-radio-button [value]="false">
                            <span>
                                {{ 'periodic.label.every' | translate }}&nbsp;
                                <mat-form-field style="width: 50px">
                                    <input matInput formControlName="days" type="number" min="1" />
                                </mat-form-field>
                                &nbsp;{{ 'periodic.label.days' | translate }}
                            </span>
                        </mat-radio-button>
                        <mat-radio-button [value]="true">{{ 'periodic.label.allDays' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <!-- WEEKLY -->
                <div *ngIf="setWeekly" class="col-md-8 pl-5" formGroupName="weekly">
                    <span>
                        {{ 'periodic.label.everyWeek' | translate }}&nbsp;
                        <mat-form-field style="width: 50px">
                            <input matInput formControlName="weeks" type="number" min="1" />
                        </mat-form-field>
                        &nbsp;{{ 'periodic.label.weeks' | translate }}
                    </span>
                    <section>
                        <mat-checkbox
                            *ngFor="let control of daysOfWeekArray.controls; let i = index"
                            [checked]="control.value"
                            class="pr-5"
                            (change)="getSelectedDaysValue(i)"
                            id="{{ i }}"
                            >{{ calendarParams.daysOfWeek[i].name }}
                        </mat-checkbox>
                        <mat-error *ngIf="submitted && daysOfWeekError"
                            >{{ 'error.obligatoire' | translate }}
                        </mat-error>
                    </section>
                </div>
                <div *ngIf="setMonthly" class="col-md-8 pl-5">Prévu dans une version à venir</div>
                <div *ngIf="setYearly" class="col-md-8 pl-5">Prévu dans une version à venir</div>
            </div>
            <div class="row mt-3">
                <mat-label>{{ 'periodic.titre.plagePeriodicite' | translate }}</mat-label>
            </div>
            <div class="row mt-3" formGroupName="plage">
                <div class="col-sm-4">
                    <mat-form-field>
                        <mat-label>{{ 'periodic.label.start' | translate }}</mat-label>

                        <input
                            matInput
                            formControlName="debut"
                            placeholder="{{ 'periodic.label.start' | translate }}"
                            [matDatepicker]="picker"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error
                            *ngIf="
                                submitted &&
                                p['plage'].get('debut')!.hasError &&
                                p['plage'].get('debut')!.hasError('required')
                            "
                            >{{ 'error.obligatoire' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-8">
                    <mat-radio-group class="vertical-radio" formControlName="choixPlage">
                        <mat-radio-button value="{{ 'constant.PLAGE_FIN_LE' | translate }}">
                            <mat-form-field>
                                <mat-label>{{ 'periodic.label.stop' | translate }}</mat-label>
                                <input
                                    matInput
                                    formControlName="fin"
                                    placeholder="{{ 'periodic.label.stop' | translate }}"
                                    [matDatepicker]="picker2"
                                />
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                                <mat-error
                                    *ngIf="
                                        submitted &&
                                        p['plage'].get('fin')!.hasError &&
                                        p['plage'].get('fin')!.hasError('required')
                                    "
                                    >{{ 'error.obligatoire' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </mat-radio-button>
                        <mat-radio-button value="{{ 'constant.PLAGE_OCCURENCE' | translate }}">
                            <span>
                                {{ 'periodic.label.plages.apres' | translate }}&nbsp;
                                <mat-form-field style="width: 50px">
                                    <input matInput formControlName="occurences" type="number" min="1" />
                                </mat-form-field>
                                &nbsp;{{ 'periodic.label.plages.occurences' | translate }}
                            </span>
                        </mat-radio-button>
                        <mat-error
                            *ngIf="
                                submitted &&
                                p['plage'].get('choixPlage')!.hasError &&
                                p['plage'].get('choixPlage')!.hasError('required')
                            "
                            >{{ 'error.obligatoire' | translate }}
                        </mat-error>
                    </mat-radio-group>
                </div>
            </div>
        </div>

        <div class="row mt-5 text-center">
            <div class="col-md-6">
                <button [disabled]="loading" class="btn btn-primary mb-3">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ 'button.envoyer' | translate }}
                </button>
            </div>
            <div class="col-md-6">
                <span role="button" class="btn btn-secondary" (click)="onSubmit('Cancel')">{{
                    'button.annuler' | translate
                }}</span>
            </div>
        </div>
    </form>
</div>
