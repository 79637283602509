<div id="modal-content-wrapper">
    <div class="card">
        <div class="card-header d-block">
            <p class="text-primary">
                {{ modalData.title }}
            </p>
        </div>
        <div class="card-body">
            <p [innerHTML]="modalData.description"></p>
            <img *ngIf="modalData.image" src="{{ modalData.image['image'] }}" />
        </div>
        <div class="card-footer">
            <div class="form-group text-center mt-3">
                <button class="btn btn-primary mr-5" (click)="close('OK')">
                    {{ modalData.actionButtonText }}
                </button>
                <button class="btn btn-secondary" (click)="close('Cancel')">
                    {{ modalData.annuler }}
                </button>
            </div>
        </div>
    </div>
</div>
