import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-prompt',
    templateUrl: './prompt.component.html',
    styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<PromptComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {}

    ngOnInit() {}

    public close(result: string) {
        this.dialogRef.close(result);
    }
}
