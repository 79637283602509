<div class="row" *ngIf="!inProduction">
    <div class="col-12">
        <h4>{{ 'forms.titre.aat' | translate }}</h4>
    </div>
</div>
<div class="form-group" [formGroup]="fieldsForm">
    <ng-container formGroupName="fields">
        <div class="row mb-3">
            <div class="col-sm-6">
                <label>{{ 'forms.label.q0' | translate }}</label
                ><br />
                <mat-radio-group formControlName="q0" (change)="onSetYN($event, 'q0')">
                    <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                    <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && f['fields'].get('q0')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <ng-container *ngIf="ouiq0 == '1'">
            <h4 class="mt-2">{{ 'forms.titre.q1' | translate }}</h4>
            <div class="row mb-3">
                <div class="col-sm-6">
                    <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.q1_fort' | translate }}"
                        matPrefix
                        >help</mat-icon
                    >
                    <label>{{ 'forms.label.q1_fort' | translate }}</label>
                    <br />
                    <mat-radio-group formControlName="q1_fort" (change)="onSetYN($event, 'q1_fort')">
                        <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                        <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="submitted && f['fields'].get('q1_fort')!.hasError('required')">{{
                        'error.obligatoire' | translate
                    }}</mat-error>
                </div>
                <div class="col-sm-6">
                    <mat-icon
                        matTooltipClass="custom-tooltip"
                        matToolTipPosition="above"
                        matTooltip="{{ 'forms.help.q1_modere' | translate }}"
                        matPrefix
                        >help</mat-icon
                    >
                    <label>{{ 'forms.label.q1_modere' | translate }}</label>
                    <br />
                    <mat-radio-group formControlName="q1_modere" (change)="onSetYN($event, 'q1_modere')">
                        <mat-radio-button value="1" class="mr-3">{{ 'button.oui' | translate }}</mat-radio-button>
                        <mat-radio-button value="0" class="mr-3">{{ 'button.non' | translate }}</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="submitted && f['fields'].get('q1_modere')!.hasError('required')">{{
                        'error.obligatoire' | translate
                    }}</mat-error>
                </div>
            </div>
            <ng-container *ngIf="ouiq1_fort == '1' || ouiq1_modere == '1'">
                <h4 class="mt-2">{{ 'forms.titre.q2' | translate }}</h4>
                <div class="row mb-3">
                    <div class="col-sm-6" *ngIf="ouiq1_fort == '1'">
                        <mat-form-field>
                            <mat-label>{{ 'forms.label.q2_fort' | translate }}</mat-label>
                            <input
                                placeholder="{{ 'forms.label.q2_fort' | translate }}"
                                matInput
                                formControlName="q2_fort"
                                [mask]="numericMask"
                            />
                            <mat-hint style="display: flex; flex-direction: column">
                                <span class="text-warning">{{ 'forms.consigne.q2' | translate }} </span>
                                <span
                                    class="text-danger mt-2"
                                    *ngIf="submitted && f['fields'].get('q2_fort')!.hasError('required')"
                                    >{{ 'error.obligatoire' | translate }}
                                </span>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6" *ngIf="ouiq1_modere == '1'">
                        <mat-form-field>
                            <mat-label>{{ 'forms.label.q2_modere' | translate }}</mat-label>
                            <input
                                placeholder="{{ 'forms.label.q2_modere' | translate }}"
                                matInput
                                formControlName="q2_modere"
                                [mask]="numericMask"
                            />
                            <mat-hint style="display: flex; flex-direction: column">
                                <span class="text-warning">{{ 'forms.consigne.q2' | translate }} </span>
                                <span
                                    class="text-danger mt-2"
                                    *ngIf="submitted && f['fields'].get('q2_modere')!.hasError('required')"
                                    >{{ 'error.obligatoire' | translate }}
                                </span>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <h4 class="mt-2">{{ 'forms.titre.q3' | translate }}</h4>
                <div class="row mb-3">
                    <div class="col-sm-6" *ngIf="ouiq1_fort == '1'">
                        <mat-form-field>
                            <mat-label>{{ 'forms.label.q3_fort' | translate }}</mat-label>
                            <input
                                placeholder="00:00"
                                matInput
                                formControlName="q3_fort"
                                [mask]="heureMask"
                                [leadZeroDateTime]="true"
                            />
                            <mat-hint style="display: flex; flex-direction: column">
                                <span class="text-warning">{{ 'forms.consigne.q3' | translate }} </span>
                                <span
                                    class="text-danger mt-2"
                                    *ngIf="submitted && f['fields'].get('q3_fort')!.hasError('required')"
                                    >{{ 'error.obligatoire' | translate }}
                                </span>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6" *ngIf="ouiq1_modere == '1'">
                        <mat-form-field>
                            <mat-label>{{ 'forms.label.q3_modere' | translate }}</mat-label>
                            <input
                                placeholder="00:00"
                                matInput
                                formControlName="q3_modere"
                                [mask]="heureMask"
                                [leadZeroDateTime]="true"
                            />
                            <mat-hint style="display: flex; flex-direction: column">
                                <span class="text-warning">{{ 'forms.consigne.q3' | translate }} </span>
                                <span
                                    class="text-danger mt-2"
                                    *ngIf="submitted && f['fields'].get('q3_modere')!.hasError('required')"
                                    >{{ 'error.obligatoire' | translate }}
                                </span>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <h4 class="mt-2">{{ 'forms.titre.q4' | translate }}</h4>
            <div class="row mb-3" *ngIf="ouiq0 == '1'">
                <div class="col-sm-6">
                    <mat-form-field>
                        <mat-label>{{ 'forms.label.q4' | translate }}</mat-label>
                        <input
                            placeholder="00:00"
                            matInput
                            formControlName="q4"
                            [mask]="heureMask"
                            [leadZeroDateTime]="true"
                        />
                        <mat-hint style="display: flex; flex-direction: column">
                            <span class="text-warning">{{ 'forms.consigne.q4' | translate }} </span>
                            <span
                                class="text-danger mt-2"
                                *ngIf="submitted && f['fields'].get('q4')!.hasError('required')"
                                >{{ 'error.obligatoire' | translate }}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
