import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule, MaterialModule } from '@/_shared';

import {
    CsedComponent,
    MaComponent,
    SituationComponent,
    AatComponent,
    DbuComponent,
    AdloadComponent,
    EpsComponent,
    ScoresComponent,
} from '@/_components';

import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule,
        AlertModule,
        MaterialModule,
        //FlexLayoutModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [
        CsedComponent,
        MaComponent,
        SituationComponent,
        AatComponent,
        DbuComponent,
        AdloadComponent,
        EpsComponent,
        ScoresComponent,
    ],

    declarations: [
        CsedComponent,
        MaComponent,
        SituationComponent,
        AatComponent,
        DbuComponent,
        AdloadComponent,
        EpsComponent,
        ScoresComponent,
    ],
})
export class FormsSharedModule {}
