<div class="row mb-3" *ngIf="!inProduction">
    <div class="col-12">
        <h4>{{ 'forms.titre.eps' | translate }}</h4>
    </div>
</div>
<p class="text-warning">{{ 'forms.consigne.garnier' | translate }}</p>
<div class="form-group" [formGroup]="fieldsForm">
    <ng-container formGroupName="fields">
        <div class="row mb-3">
            <div class="col-sm-6">
                <label>{{ 'forms.label.garnier_q1' | translate }}</label
                ><br />
                <mat-slider
                    thumbLabel
                    formControlName="garnier_q1"
                    step="1"
                    [min]="min - 1"
                    [max]="max"
                    aria-label="units"
                ></mat-slider>
                <mat-error *ngIf="submitted && f['fields'].get('garnier_q1')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-6">
                <label>{{ 'forms.label.garnier_q2' | translate }}</label
                ><br />
                <mat-slider
                    thumbLabel
                    formControlName="garnier_q2"
                    step="1"
                    [min]="min - 1"
                    [max]="max"
                    aria-label="units"
                ></mat-slider>
                <mat-error *ngIf="submitted && f['fields'].get('garnier_q2')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-6">
                <label>{{ 'forms.label.garnier_q3' | translate }}</label
                ><br />
                <mat-slider
                    thumbLabel
                    formControlName="garnier_q3"
                    step="1"
                    [min]="min - 1"
                    [max]="max"
                    aria-label="units"
                ></mat-slider>
                <mat-error *ngIf="submitted && f['fields'].get('garnier_q3')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-6">
                <label>{{ 'forms.label.garnier_q4' | translate }}</label
                ><br />
                <mat-slider
                    thumbLabel
                    formControlName="garnier_q4"
                    step="1"
                    [min]="min - 1"
                    [max]="max"
                    aria-label="units"
                ></mat-slider>
                <mat-error *ngIf="submitted && f['fields'].get('garnier_q4')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-6">
                <label>{{ 'forms.label.garnier_q5' | translate }}</label
                ><br />
                <mat-slider
                    thumbLabel
                    formControlName="garnier_q5"
                    step="1"
                    [min]="min - 1"
                    [max]="max"
                    aria-label="units"
                ></mat-slider>
                <mat-error *ngIf="submitted && f['fields'].get('garnier_q5')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-6">
                <label>{{ 'forms.label.garnier_q6' | translate }}</label
                ><br />
                <mat-slider
                    thumbLabel
                    formControlName="garnier_q6"
                    step="1"
                    [min]="min - 1"
                    [max]="max"
                    aria-label="units"
                ></mat-slider>
                <mat-error *ngIf="submitted && f['fields'].get('garnier_q6')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-6">
                <label>{{ 'forms.label.garnier_q7' | translate }}</label
                ><br />
                <mat-slider
                    thumbLabel
                    formControlName="garnier_q7"
                    step="1"
                    [min]="min - 1"
                    [max]="max"
                    aria-label="units"
                ></mat-slider>
                <mat-error *ngIf="submitted && f['fields'].get('garnier_q7')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
            <div class="col-sm-6">
                <label>{{ 'forms.label.garnier_q8' | translate }}</label
                ><br />
                <mat-slider
                    thumbLabel
                    formControlName="garnier_q8"
                    step="1"
                    [min]="min - 1"
                    [max]="max"
                    aria-label="units"
                ></mat-slider>
                <mat-error *ngIf="submitted && f['fields'].get('garnier_q8')!.hasError('required')">{{
                    'error.obligatoire' | translate
                }}</mat-error>
            </div>
        </div>
    </ng-container>
</div>
